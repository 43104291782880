/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { setKey, setLanguage, setLocationType, fromLatLng } from "react-geocode";

const MyMapPicker = ({ lat = "", lng = "", disabled = false, setFieldValue }) => {
  setKey(process.env.REACT_APP_GOOGLE_API);
  setLanguage("en");
  setLocationType("ROOFTOP");
  // enableDebug();

  const mapRef = React.useRef(null);

  const [latlng, setlatlng] = useState({ lat: "", lng: "" });
  const [myLatlng, setmyLatlng] = useState(null);

  const { google } = window;

  useEffect(() => {
    setlatlng({ lat, lng });
  }, [lat, lng]);

  // To avoid map refresh
  useEffect(() => {
    setmyLatlng(new google.maps.LatLng(latlng.lat, latlng.lng));
    // eslint-disable-next-line
  }, [latlng.lat, latlng.lng]);

  useEffect(() => {
    let map = mapRef.current;
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      draggableCursor: disabled ? "default" : "pointer",
      styles: [
        {
          featureType: "water",
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [{ hue: "#ff0000" }, { saturation: -100 }, { lightness: 99 }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#808080" }, { lightness: 54 }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#ece2d9" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#ccdca1" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#767676" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }],
        },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
        },
        { featureType: "poi.park", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "on" }],
        },
        { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "simplified" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map,
      animation: google.maps.Animation.DROP,
      title: "LCM",
    });

    const contentString =
      '<div class="info-window-content"><h2>LCM</h2>' +
      "<p>Lokalee app Self Serve Platform</p></div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", () => {
      infowindow.open(map, marker);
    });

    if (!disabled)
      google.maps.event.addListener(map, "click", (e) => {
        setlatlng({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        setFieldValue("lat", e.latLng.lat());
        setFieldValue("lng", e.latLng.lng());

        fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
          (response) => {
            // console.log(response.results);
            const address = response.results[0].formatted_address;
            let city;
            let country;
            // let state
            for (let i = 0; i < response.results[0].address_components.length; i++) {
              for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    // state = response.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country = response.results[0].address_components[i].long_name;
                    break;
                  default:
                    break;
                }
              }
            }

            if (address) setFieldValue("address", address);
            if (city) setFieldValue("city", city);

            if (country) setFieldValue("country", country);
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.log("Geocoding returned no results. Non-existent address!");
            // eslint-disable-next-line no-console
            console.error(error);
          }
        );

        // infowindow.open(map, marker);
      });
  }, [latlng.lat, latlng.lng, myLatlng, disabled]);

  return <div style={{ height: "470px", borderRadius: "8px" }} ref={mapRef} />;
};

MyMapPicker.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MyMapPicker;
