/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import timeZonesList from "./TimeZonesList";

const TimeZonePicker = ({
  handleTimeZoneChange = () => {},
  timeZone = {},
  setFieldValue = () => {},
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  return (
    <SelectF
      options={timeZonesList}
      placeholder="Select a time zone"
      value={timeZone}
      onChange={(event) => {
        if (event) {
          setFieldValue("timeZone", event);
          handleTimeZoneChange(event);
        } else {
          setFieldValue("timeZone", {});
          handleTimeZoneChange(event);
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.name}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      menuPortalTarget={document.body}
    />
  );
};

// typechecking props for TimeZonePicker
TimeZonePicker.propTypes = {
  handleTimeZoneChange: PropTypes.func,
  timeZone: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
};

export default TimeZonePicker;
