// Libraries
import PropTypes from "prop-types";
import React from "react";

// @mui material components
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormHelperText } from "@mui/material";

const DynamicRadioGroup = ({
  value = "",
  options = [],
  name = "",
  handleChange = () => {},
  setFieldValue = () => {},
  groupLabel = "",
  disabled = false,
  helperTextStyle = {},
  isColumn = false,
}) => (
  <>
    {groupLabel && (
      <FormLabel
        sx={{ color: "black!important", fontWeight: 500, fontSize: "0.875rem" }}
        id="demo-radio-buttons-group-label"
      >
        {groupLabel}
      </FormLabel>
    )}
    <RadioGroup
      value={value}
      onChange={(event) => {
        const selectedType = event.target.value;
        setFieldValue(name, selectedType);
        handleChange(event); // Pass the event object
      }}
      row={!isColumn}
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      sx={{
        opacity: disabled ? 0.6 : 1,
        "& .MuiFormControlLabel-root": helperTextStyle
          ? {
              display: "flex",
            }
          : { fontWeight: 400, fontSize: "0.8rem" },
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio disabled={option?.disable || disabled} />}
          label={
            <>
              <span>{option.label}</span>
              {option.helperText && (
                <FormHelperText sx={{ margin: 0, ...helperTextStyle }}>
                  {option.helperText}
                </FormHelperText>
              )}
            </>
          }
        />
      ))}
    </RadioGroup>
  </>
);

// typechecking props for AdditionalInfo
DynamicRadioGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.instanceOf(Array),
  helperTextStyle: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  groupLabel: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isColumn: PropTypes.bool,
};

export default DynamicRadioGroup;
