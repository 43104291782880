/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import {
  Box,
  Step,
  Select,
  Stepper,
  MenuItem,
  Checkbox,
  FormGroup,
  StepLabel,
  Typography,
  InputLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect, useRef, useState } from "react";
import DynamicMaterialTable from "components/MaterialTablePicker";

import {
  addProduct,
  updateProduct,
  fetchStoreItems,
  fetchLocationTags,
  fetchSubLocationTags,
  updateSettingsConfigs,
} from "store/actions/locationAction";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "components/FormField";
import DetailList from "components/DetailList";

import colors from "assets/theme/base/colors";
import onlineStoreForm from "../../schemas/OnlineStore/form";
import validations from "../../schemas/OnlineStore/validation";

const addIcon = () => <AddIcon />;
const settingsIcon = () => <SettingsIcon />;
const columns = ["Product Name", "Product Code", " Currency", " Price", "Tag ", "Subtag"];
const steps = {
  add: [
    { _id: 0, label: "Product Information" },
    { _id: 1, label: "Review" },
  ],
  settings: [
    { _id: 0, label: "Online Store Configuration" },
    { _id: 1, label: "Review" },
  ],
};
const company = [
  { label: "Sky Software", value: "sky" },
  { label: "Message Box", value: "messagebox" },
];
const currencyOptions = [
  { code: "EUR", label: "EUR" },
  { code: "USD", label: "USD" },
  { code: "AED", label: "AED" },
  { code: "GBP", label: "GBP" },
  { code: "CAD", label: "CAD" },
  { code: "AUD", label: "AUD" },
  { code: "EGP", label: "EGP" },
  { code: "TRY", label: "TRY" },
  { code: "SAR", label: "SAR" },
];

const OnlineStore = ({ locationId = "", locationCurrency = "", storeConfigs = {} }) => {
  /* ** Refs ** */
  const tableRef = useRef(null);
  const initialValues = useRef({});

  /* ** States ** */
  const [activeStep, setActiveSteps] = useState(0);
  const [chosenTagId, setChosenTagId] = useState("");
  const [iconPressed, setIconPressed] = useState("");

  /* ** Selectors ** */
  const store = useSelector((state) => state.locations.store);
  const locationTags = useSelector((state) => state.locations.locationTags);
  const locationSubTags = useSelector((state) => state.locations.locationSubTags);

  /* ** Constants ** */
  const dispatch = useDispatch();
  const { addProductValidation, settingValidation } = validations;
  const { onlineFormId, addProductsFields, settingFields } = onlineStoreForm;
  const { paymentMethod, orderProcessingType, staffEmail, systemPicker } = settingFields;
  const materialTableIcons = [
    {
      icon: settingsIcon,
      isFreeAction: true,
      tooltip: "Settings",
      onClick: () => handleIconPressed("settings", {}),
      iconProps: {
        style: {
          color: "black",
          fontSize: "25px",
          boxShadow: "none",
          marginLeft: "10px",
        },
      },
    },
    {
      icon: addIcon,
      isFreeAction: true,
      tooltip: "Add Product",
      onClick: () => handleIconPressed("add", {}),
      iconProps: {
        style: {
          color: "black",
          fontSize: "25px",
          boxShadow: "none",
          marginLeft: "10px",
        },
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchLocationTags());
    dispatch(fetchStoreItems(locationId));
  }, []);
  useEffect(() => {
    if (chosenTagId) {
      dispatch(fetchSubLocationTags(chosenTagId));
    }
  }, [chosenTagId]);

  const handleIconPressed = (icon, data) => {
    if (iconPressed === icon) {
      setIconPressed("");
      return;
    }

    const initializeAddValues = (addData) => {
      const {
        _id = "",
        tags = [],
        title = "",
        subTags = [],
        currency = "",
        partnerId = "",
        price = { recommendedRetailPrice: "" },
      } = addData;

      const tag = locationTags.find((item) => item._id === tags[0]?._id) || {};
      const itemCurrency = currency || locationCurrency;
      const currencyOption = currencyOptions.find((item) => item.code === itemCurrency);

      if (tags[0]?._id) {
        setChosenTagId(tags[0]?._id);
      }

      return {
        productName: title,
        productCode: partnerId,
        currency: currencyOption,
        price: price.recommendedRetailPrice,
        subTag: subTags.length > 0 ? { _id: subTags[0], label: subTags[1] } : {},
        tag: tag._id ? { _id: tag._id, label: tag.title } : {},
        ...(_id && { _id }),
      };
    };
    const initializeSettingsValues = (settingsData) => {
      const defaultOrderProcessingType = [{ name: "emailToCs", label: "Email to CS" }];
      const defaultPaymentMethods = [
        { name: "cash", label: "Cash On Delivery (COD)" },
        { name: "credit", label: "Credit Card" },
        { name: "charges", label: "Room Charges" },
      ];

      const processedOrderProcessingType =
        settingsData?.orderProcessingType?.length > 0
          ? orderProcessingType.map((item) => ({ name: item, label: item }))
          : defaultOrderProcessingType;

      const processedPaymentMethod =
        settingsData?.paymentMethod.length > 0
          ? settingsData.paymentMethod
              .map((item) => {
                const paymentLabels = {
                  cash: "Cash On Delivery (COD)",
                  credit: "Credit Card",
                  charges: "Room Charges",
                };
                return paymentLabels[item] ? { name: item, label: paymentLabels[item] } : null;
              })
              .filter(Boolean)
          : defaultPaymentMethods;

      return {
        staffEmail: settingsData.staffEmail,
        paymentMethod: processedPaymentMethod,
        orderProcessingType: processedOrderProcessingType,
      };
    };

    if (icon === "add") {
      initialValues.current = initializeAddValues(data);
    } else {
      initialValues.current = initializeSettingsValues(storeConfigs);
    }

    setIconPressed(icon);
  };

  const handleNextBttnPress = async (validateForm, values, resetForm) => {
    const validationErrors = await validateForm();

    if (activeStep === 0) {
      if (Object.keys(validationErrors).length === 0) {
        setChosenTagId("");
        setActiveSteps((prev) => prev + 1);
      }
      return;
    }

    if (activeStep === 1) {
      if (iconPressed === "add") {
        handleAddProduct(values);
      } else {
        handleUpdateSettings(values);
      }
      handleFormReset(resetForm);
    }
  };
  const handleAddProduct = (values) => {
    const { subTag = "", tag, price, productName, currency, productCode, _id = "" } = values;

    const productPayload = {
      tags: tag._id,
      source: "lokalee",
      itemType: "store",
      title: productName,
      location: locationId,
      partnerId: productCode,
      currency: currency.code,
      price: {
        recommendedRetailPrice: price,
      },
    };

    if (subTag) {
      productPayload.subTags = subTag._id;
    }

    if (_id) {
      dispatch(updateProduct(productPayload, _id));
    } else {
      dispatch(addProduct(productPayload));
    }
  };
  const handleUpdateSettings = (values) => {
    const updatedValues = {
      staffEmail: values.staffEmail,
      paymentMethod: values.paymentMethod.map((item) => item.name),
      // orderProcessingType: values.orderProcessingType.map((item) => item.name),
    };

    dispatch(updateSettingsConfigs(updatedValues, locationId));
  };
  const handleFormReset = (resetForm) => {
    resetForm();
    setActiveSteps(0);
    setChosenTagId("");
    setIconPressed("");
  };

  const handleDropDownOptions = (name) => {
    switch (name) {
      case "tag":
        return locationTags.map((tag) => ({ _id: tag._id, label: tag.title }));
      case "subTag":
        return locationSubTags.map((tag) => ({ _id: tag._id, label: tag.title }));
      case "currency":
        return currencyOptions;
      default:
        return [];
    }
  };
  const handleDetailsList = (values) => {
    if (iconPressed === "add") {
      const { productName, productCode, price, currency, tag, subTag } = values;
      return {
        ProductName: productName,
        JobCode: productCode,
        Price: price,
        Currency: currency.label,
        Tag: tag._id,
        SubTag: subTag._id,
      };
    }

    return {
      PaymentMethod: values.paymentMethod.map((item) => item.label).join(", "),
      ProcessingType: values.orderProcessingType.map((item) => item.label).join(", "),
      StaffEmail: values.staffEmail,
      SystemPicker: values.systemPicker,
    };
  };
  const handleDisplay = (values, errors, setErrors, setFieldValue) => {
    switch (iconPressed) {
      case "add":
        return (
          <>
            {Object.values(addProductsFields).map((textInput) => {
              const { type, name, label, placeholder } = textInput;

              if (type === "select") {
                const options = handleDropDownOptions(name);
                const noSubTags = values.tags && options.length === 0 && name === "subTag";

                return (
                  <FormControl
                    key={name}
                    fullWidth
                    error={errors[name]}
                    disabled={(name === "subTag" && values.tags) || options.length === 0}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: errors[name] ? "#d32f2f" : colors.primaryC.main,
                        },
                        "&:hover fieldset": {
                          borderColor: errors[name] && colors.primaryC.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: errors[name] ? "#d32f2f" : colors.primaryC.main,
                        },
                      },
                    }}
                  >
                    <InputLabel id={`${name}-label`}>{noSubTags ? "No Options" : label}</InputLabel>

                    <Select
                      id={name}
                      label={label}
                      variant="standard"
                      labelId={`${name}-label`}
                      style={styles.dropDownStyle}
                      value={Object.keys(values[name]).length > 0 ? values[name] : ""}
                      renderValue={(selected) => (selected.label ? selected.label : "")}
                      onChange={(e) => {
                        if (name === "tag") {
                          setChosenTagId(e.target.value._id);
                          setFieldValue(name, e.target.value);
                        } else {
                          setFieldValue(name, e.target.value);
                        }
                      }}
                      onClick={() => {
                        if (errors[name]) {
                          const { [name]: _, ...rest } = errors;
                          setErrors(rest);
                        }
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors[name] && (
                      <FormHelperText sx={{ marginLeft: 0, fontWeight: "400" }}>
                        {errors[name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }

              return (
                <FormField
                  key={name}
                  name={name}
                  label={label}
                  value={values[name]}
                  error={errors[name]}
                  placeholder={placeholder}
                  success={values[name]?.length > 0 && !errors[name]}
                  onFocus={() => {
                    if (errors[name]) {
                      const { [name]: _, ...rest } = errors;
                      setErrors(rest);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      type === "number" &&
                      Number.isNaN(Number(e.key)) &&
                      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "."].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              );
            })}
          </>
        );
      case "settings":
        return (
          <>
            <Box style={styles.paymentContainer}>
              <Typography variant="h6" component="legend">
                Payment Method *
              </Typography>

              <Box>
                <FormGroup style={styles.checkBoxContainer}>
                  {paymentMethod.options.map((option) => {
                    const { name, label } = option;

                    return (
                      <FormControlLabel
                        key={name}
                        label={label}
                        control={
                          <Checkbox
                            name={name}
                            checked={values.paymentMethod.map((item) => item.name).includes(name)}
                            onChange={(e) => {
                              const updatedPaymentMethods = e.target.checked
                                ? [...values.paymentMethod, { name, label }]
                                : values.paymentMethod.filter(
                                    (item) => item.name !== e.target.name
                                  );

                              if (errors.paymentMethod) {
                                const { paymentMethod: _, ...rest } = errors;
                                setErrors(rest);
                              }
                              setFieldValue("paymentMethod", updatedPaymentMethods);
                            }}
                          />
                        }
                      />
                    );
                  })}
                </FormGroup>

                {errors.paymentMethod && (
                  <Typography variant="body2" color="error" style={styles.errorStyle}>
                    *{errors.paymentMethod}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box>
              <Typography variant="h6" component="legend" style={{ marginBottom: "8px" }}>
                Choose Your Order Processing Type
              </Typography>

              <FormGroup style={styles.checkBoxContainer}>
                {orderProcessingType.options.map((option) => {
                  const { name, label } = option;

                  return (
                    <FormControlLabel
                      key={name}
                      label={label}
                      style={{
                        cursor: name === "guestRequest" ? "not-allowed" : "pointer",
                      }}
                      control={
                        <Checkbox
                          name={name}
                          disabled={name === "guestRequest"}
                          checked={values.orderProcessingType
                            .map((item) => item.name)
                            .includes(name)}
                          onChange={(e) => {
                            const updatedOrderProcessingType = e.target.checked
                              ? [...values.orderProcessingType, { name, label }]
                              : values.orderProcessingType.filter(
                                  (item) => item.name !== e.target.name
                                );

                            if (errors.orderProcessingType) {
                              const { orderProcessingType: _, ...rest } = errors;
                              setErrors(rest);
                            }
                            setFieldValue("orderProcessingType", updatedOrderProcessingType);
                          }}
                        />
                      }
                    />
                  );
                })}
              </FormGroup>

              {errors.orderProcessingType && (
                <Typography variant="body2" color="error" style={styles.errorStyle}>
                  *{errors.orderProcessingType}
                </Typography>
              )}
            </Box>

            <Box>
              {values.orderProcessingType.map((item) => item.name).includes("emailToCs") && (
                <FormField
                  outlined
                  name={staffEmail.name}
                  label={staffEmail.label}
                  value={values[staffEmail.name]}
                  error={errors[staffEmail.name]}
                  placeholder={staffEmail.placeholder}
                  onChange={(e) => setFieldValue(staffEmail.name, e.target.value)}
                  success={values[staffEmail.name]?.length > 0 && !errors[staffEmail.name]}
                  onFocus={() => {
                    if (errors[staffEmail.name]) {
                      const { [staffEmail.name]: _, ...rest } = errors;
                      setErrors(rest);
                    }
                  }}
                />
              )}

              {values.orderProcessingType.map((item) => item.name).includes("guestRequest") && (
                <FormControl
                  fullWidth
                  key={systemPicker.name}
                  error={errors[systemPicker.name]}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errors[systemPicker.name] ? "#d32f2f" : colors.primaryC.main,
                      },
                      "&:hover fieldset": {
                        borderColor: errors[systemPicker.name] ? "#d32f2f" : colors.primaryC.main,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: errors[systemPicker.name] ? "#d32f2f" : colors.primaryC.main,
                      },
                    },
                  }}
                >
                  <InputLabel id={`${systemPicker.name}-label`}>{systemPicker.label}</InputLabel>

                  <Select
                    id={systemPicker.name}
                    label={systemPicker.label}
                    value={values[systemPicker.name]}
                    labelId={`${systemPicker.name}-label`}
                    onChange={(e) => {
                      setFieldValue(systemPicker.name, e.target.value);
                    }}
                    onClick={() => {
                      if (errors[systemPicker.name]) {
                        const { [systemPicker.name]: _, ...rest } = errors;
                        setErrors(rest);
                      }
                    }}
                  >
                    {company.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>

                  {errors[systemPicker.name] && (
                    <FormHelperText sx={{ marginLeft: 0, fontWeight: "400" }}>
                      *{errors[systemPicker.name]}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  if (!iconPressed) {
    return (
      <DynamicMaterialTable
        isSearchable
        data={store}
        columns={columns}
        tableRef={tableRef}
        isExportable={false}
        addUrl="/add-product"
        searchField="elements"
        filteringOptions={false}
        customActions={materialTableIcons}
        onRowClickFn={(rowData) => {
          handleIconPressed("add", rowData);
        }}
      />
    );
  }

  return (
    <MDBox shadow="md" bgColor="white" borderRadius="md" variant="contained">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps[iconPressed].map(({ _id, label }) => (
          <Step key={_id}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues.current}
        validationSchema={iconPressed === "add" ? addProductValidation : settingValidation}
      >
        {({ values, errors, resetForm, setErrors, setTouched, validateForm, setFieldValue }) => (
          <>
            {activeStep === 0 ? (
              <Form
                id={onlineFormId}
                autoComplete="off"
                style={
                  iconPressed === "add" ? styles.addProductContainer : styles.settingsContainer
                }
              >
                {handleDisplay(values, errors, setErrors, setFieldValue)}
              </Form>
            ) : (
              <div style={styles.reviewContainer}>
                <h3>
                  {iconPressed === "add" ? "Product Information" : "Online Store Configuration"}
                </h3>

                <DetailList data={handleDetailsList(values)} />
              </div>
            )}

            <div style={styles.actionBttnsContainer}>
              <MDButton
                color="dark"
                rel="noreferrer"
                variant="outlined"
                onClick={() => {
                  handleFormReset(resetForm);
                }}
              >
                Cancel
              </MDButton>

              <div style={styles.actionBttns}>
                {activeStep !== 0 && (
                  <MDButton
                    color="dark"
                    variant="gradient"
                    onClick={() => setActiveSteps((prev) => prev - 1)}
                  >
                    Back
                  </MDButton>
                )}

                <MDButton
                  color="dark"
                  rel="noreferrer"
                  variant="outlined"
                  onClick={() => {
                    setTouched({
                      ...Object.keys(values).reduce((acc, key) => {
                        acc[key] = true;
                        return acc;
                      }, {}),
                    });
                    handleNextBttnPress(validateForm, values, resetForm);
                  }}
                >
                  {activeStep === 1 ? "Add" : "Next"}
                </MDButton>
              </div>
            </div>
          </>
        )}
      </Formik>
    </MDBox>
  );
};

export default OnlineStore;

const styles = {
  addProductContainer: {
    display: "grid",
    gap: "16px 32px",
    margin: "20px 0px",
    padding: "20px 40px",
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  actionBttnsContainer: {
    gap: "20px",
    display: "flex",
    padding: "20px 40px",
    justifyContent: "space-between",
  },

  actionBttns: {
    gap: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },

  reviewContainer: {
    gap: "10px",
    display: "flex",
    margin: "20px 0px",
    padding: "20px 40px",
    flexDirection: "column",
  },

  settingsContainer: {
    gap: "20px",
    display: "flex",
    padding: "20px 40px",
    flexDirection: "column",
  },

  checkBoxContainer: {
    width: "100%",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #ccc",
  },

  paymentContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },

  errorStyle: {
    marginTop: "5px",
    marginLeft: "10px",
  },

  dropDownStyle: {
    // paddingLeft: "10px",
  },
};

OnlineStore.propTypes = {
  locationId: PropTypes.string,
  storeConfigs: PropTypes.shape({}),
  locationCurrency: PropTypes.string,
  formData: PropTypes.shape({
    value: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }),
};
