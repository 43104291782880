import React from "react";
import PropTypes from "prop-types";
import ForgotPasswordEmail from "..";

const WebForgetPassword = ({ token = "961", user = {} }) => (
  <ForgotPasswordEmail isCode user={user} token={token} />
);

WebForgetPassword.propTypes = {
  user: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

export default WebForgetPassword;
