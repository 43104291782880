import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete"; // Material-UI delete icon
import Danger from "components/Danger";

const UploadImage = ({ defaultImage = null, setFieldValue, fieldName }) => {
  const [image, setImage] = useState(defaultImage);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef();
  const MAX_FILE_SIZE = 900 * 1024; // 900 kB in bytes

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const previewImage = (file) => {
    if (file && file instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
  };

  useEffect(() => {
    if (defaultImage) {
      previewImage(defaultImage);
    }
  }, [defaultImage]);

  const handleFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage("File size exceeds the 900 KB limit!");
    } else {
      setErrorMessage("");
      setFieldValue(fieldName, [file]);
      previewImage(file);
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const onChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const onClickButton = () => {
    fileInputRef.current.click();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      fileInputRef.current.click();
    }
  };

  const handleDeleteImage = (event) => {
    event.stopPropagation();
    setImage(null);
    setFieldValue(fieldName, []);
  };

  return (
    <div>
      <div
        role="button"
        onDragOver={onDragOver}
        onDrop={onDrop}
        onClick={onClickButton}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        style={{
          border: "2px dashed grey",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        Drag and drop an image here, or click
        {image && (
          <div
            style={{
              marginTop: "20px",
              position: "relative",
            }}
          >
            <img
              src={image}
              alt="Preview"
              style={{
                width: "100%",
                maxWidth: "300px",
                height: "300px",
                objectFit: "cover",
                // backgroundColor: "rgba(0, 0, 0, 0.1)",
                backgroundColor: "black",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                cursor: "pointer",
              }}
            >
              <DeleteIcon
                className="delete-icon-hover"
                style={{
                  color: "black", // Ensure the icon is visible
                  width: "18px",
                  height: "18px",
                }}
                onClick={(event) => {
                  handleDeleteImage(event);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={onChange}
        style={{ display: "none" }}
        accept="image/jpeg, image/jpg, image/png"
      />
      {errorMessage && <Danger validation={errorMessage} />}
    </div>
  );
};

UploadImage.propTypes = {
  defaultImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setFieldValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default UploadImage;
