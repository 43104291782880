/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import Information from "pages/content/banners/new-banner/components/Information";

const BannerDetail = ({
  formData,
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  handlePositionChange = () => {},
  selectedPosition = {},
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => "Banner Info";

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>
        <Information
          formData={formData}
          editPage
          detailPage={detailPage}
          handlePositionChange={handlePositionChange}
          selectedPosition={selectedPosition}
          isDisabledPositionPicker
        />
      </MDBox>
    </MDBox>
  );
};

// typechecking props for BannerDetail
BannerDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  handlePositionChange: PropTypes.func,
  selectedPosition: PropTypes.instanceOf(Object),
};

export default BannerDetail;
