export const convertToAMPM = (time24) => {
  if (!time24) {
    return "";
  }

  const timeSplit = time24.split(":");
  let hours = parseInt(timeSplit[0], 10);
  const minutes = timeSplit[1];
  const period = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  return `${hours}:${minutes} ${period}`;
};

export const exportDayName = (day) => {
  switch (day) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    default:
      return "";
  }
};
