/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid2";

// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { addWidget } from "store/actions/widgetActions";

// components
import FormField from "components/FormField";
import { Formik } from "formik";
import IconsPicker from "components/AutoComplete/IconsPicker";
import MDBox from "components/MDBox";
import initialValues from "../../schemas/initialValues";
import validations from "../../schemas/validations";
import form from "../../schemas/form";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AddTranportationServiceDialog = ({
  handleClose = () => {},
  handleIconChange = () => {},
  selectedIcon = {},
  openDialog = false,
  iconsObj = {},
}) => {
  const { title, logoName, logo } = form.formField;
  const currentValidation = validations[0];

  const dispatch = useDispatch();

  // selector
  const location = useSelector((state) => state.locations.location);

  const clearClose = (setFieldValue) => {
    setFieldValue("title", "");
    setFieldValue("logoName", "");
    setFieldValue("logo", "");

    handleClose();
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitFormFn = async (val, actions) => {
    await sleep(1000);
    dispatch(addWidget({ title: val?.title, location, logoName: val?.logoName, logo: val?.logo }));
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    submitFormFn(values, actions);
    handleClose();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors, submitForm }) => (
          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              clearClose(setFieldValue);
            }}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
          >
            <DialogContent sx={{ minHeight: `300px` }}>
              <DialogContentText id="alert-dialog-slide-description">
                Add Transportation Service
              </DialogContentText>
              <Grid item size={{ xs: 12 }} mt={5}>
                <FormField
                  type={title.type}
                  label={title?.label}
                  name={title.name}
                  value={values?.title}
                  placeholder={title.placeholder}
                  error={errors.title && touched.title}
                  success={values?.title.length > 0 && !errors.title}
                />
              </Grid>

              <Grid>
                <MDBox>
                  <IconsPicker
                    logoName={logoName}
                    logo={logo}
                    selectedIcon={selectedIcon || iconsObj || {}}
                    handleIconChange={handleIconChange}
                    setFieldValue={setFieldValue}
                    error={errors.logoName && touched.logoName}
                    success={values?.logoName?.name && !errors.logoName}
                  />
                </MDBox>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  clearClose(setFieldValue);
                }}
              >
                Cancel
              </Button>
              <Button onClick={submitForm}>Add</Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

AddTranportationServiceDialog.propTypes = {
  handleClose: PropTypes.func,
  handleIconChange: PropTypes.func,
  openDialog: PropTypes.bool,
  selectedIcon: PropTypes.instanceOf(Object),
  iconsObj: PropTypes.instanceOf(Object),
};

export default AddTranportationServiceDialog;
