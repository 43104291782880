/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import Danger from "components/Danger";

const UploadMultiImage = ({ defaultImages = null, setFieldValue, fieldName }) => {
  const [images, setImages] = useState(defaultImages || []); // Ensure defaultImages is always an array
  const [warningMessage, setWarningMessage] = useState("");
  const fileInputRef = useRef();

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const previewImages = async (files) => {
    const imagePromises = files.map(
      (file) =>
        new Promise((resolve) => {
          if (file instanceof Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              resolve(reader.result);
            };
          } else if (typeof file === "string") {
            // Handle image URLs
            resolve(file);
          } else {
            resolve(null);
          }
        })
    );

    const imageResults = await Promise.all(imagePromises);
    const validImages = imageResults.filter((image) => image !== null);
    setImages(validImages);
  };

  useEffect(() => {
    if (defaultImages) {
      previewImages(defaultImages);
      setFieldValue(fieldName, defaultImages);
    }
  }, [defaultImages, fieldName, setFieldValue]);

  const onDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const totalImages = images.length + files.length;

    if (totalImages > 6) {
      setWarningMessage("You can only upload up to 6 images.");
    } else {
      setWarningMessage("");
      const updatedImages = [...images, ...files];
      setImages(updatedImages);
      setFieldValue(fieldName, updatedImages);
      previewImages(updatedImages);
    }
  };

  const onChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = images.length + files.length;

    if (totalImages > 6) {
      setWarningMessage("You can only upload up to 6 images.");
    } else {
      setWarningMessage("");
      const updatedImages = [...images, ...files];
      setImages(updatedImages);
      setFieldValue(fieldName, updatedImages);
      previewImages(updatedImages);
    }
  };

  const onClickButton = () => {
    fileInputRef.current.click();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      fileInputRef.current.click();
    }
  };

  const handleDeleteImage = (index, e) => {
    e.stopPropagation();

    // Create a copy of the images array
    const updatedImages = [...images];

    // Remove the deleted image from the copy
    updatedImages.splice(index, 1);

    // Update the local state with the modified array
    setImages([...updatedImages]);

    // Update the form's state using setFieldValue
    setFieldValue(fieldName, updatedImages);
  };

  return (
    <div>
      <div
        role="button"
        onDragOver={onDragOver}
        onDrop={onDrop}
        onClick={onClickButton}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        style={{
          border: "2px dashed grey",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        Drag and drop images here, or click
        {images.length > 0 && (
          <div style={{ marginTop: "20px", display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <div key={index} style={{ margin: "8px", position: "relative" }}>
                <img
                  src={image}
                  alt={`Preview ${index}`}
                  style={{
                    width: "100%",
                    maxWidth: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    cursor: "pointer",
                  }}
                >
                  <DeleteIcon
                    className="delete-icon-hover"
                    style={{
                      color: "black",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={(e) => handleDeleteImage(index, e)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        onChange={onChange}
        style={{ display: "none" }}
        accept="image/jpeg, image/jpg, image/png"
        multiple
      />
      {warningMessage && <Danger validation={warningMessage} />}{" "}
      {/* Render Danger component with warning message */}
    </div>
  );
};

UploadMultiImage.propTypes = {
  defaultImages: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
  ),
  setFieldValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default UploadMultiImage;
