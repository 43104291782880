/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";

const ServiceJobsCompanyPicker = ({
  isDiningTab = false,
  companyV = "",
  setFieldValue,
  companyOptions,
}) => (
  <AsyncSelect
    options={companyOptions}
    placeholder="Select a company"
    value={companyV ? companyOptions.find((company) => company.value === companyV) : null}
    onChange={(selectedOption) => {
      if (!isDiningTab) {
        setFieldValue("company", selectedOption.value.toLowerCase());
        return;
      }

      setFieldValue("diningCompany", selectedOption.value.toLowerCase());
      if (selectedOption.value !== "digi") {
        setFieldValue("inRoomDiningPropertyId", "");
      }
    }}
    components={{
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    }}
    styles={{
      control: (base, state) => ({
        ...base,
        minHeight: "40px",
        borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
        boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
        "&:hover": {
          borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
        },
      }),
      input: (base) => ({
        ...base,
        fontSize: "0.875rem",
      }),
      menuList: (base) => ({
        ...base,
        fontSize: "0.875rem",
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: "0.875rem",
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: "0.875rem", // Customize the selected option text size
      }),
    }}
  />
);

// Prop-types for ServiceJobsCompanyPicker
ServiceJobsCompanyPicker.propTypes = {
  companyV: PropTypes.string,
  isDiningTab: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  companyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ServiceJobsCompanyPicker;
