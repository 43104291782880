/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
// import { Typography } from "@mui/material";
import debounce from "lodash/debounce";

const PinPicker = ({
  handleChange = () => {},
  setFieldValue = () => {},
  selectedPin = {},
  isClearable = false,
  type = "",
  isPickupPoint = false,
}) => {
  const filterRec = (data) =>
    data.map((pinData) => ({
      label: pinData?.name,
      value: pinData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url;
      url = `${process.env.REACT_APP_API}/pins?`;

      if (inputValue) url += `&name=${inputValue}`;
      url += "&sort=destinationName&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          const sortedData = result.data.sort((a, b) => a?.name.localeCompare(b?.name));
          resolve(filterRec(sortedData));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000); // Adjust the delay time (in milliseconds) as needed

  return (
    <div>
      <AsyncSelect
        // ref={selectRef}
        cacheOptions
        defaultOptions
        placeholder={selectedPin?.name || selectedPin?.label || "Search..."}
        isClearable={isClearable}
        key={type}
        menuPosition="fixed"
        value={selectedPin.value ? selectedPin : null} // Ensure selectedPin is set correctly
        loadOptions={debouncedLoadOptions}
        onChange={(option, { action }) => {
          if (option) {
            handleChange(option);
            if (isPickupPoint) setFieldValue("pickupPoint", option);
            else setFieldValue("pin", option);
          }
          if (action === "clear") {
            handleChange(null);
            setFieldValue("pin", {});
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          // option: (base) => ({
          //   ...base,
          //   padding: "18px 18px", // Adjust the padding as needed
          // }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            overflow: "unset",
          }),
        }}
      />
    </div>
  );
};

PinPicker.propTypes = {
  // pin: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isPickupPoint: PropTypes.bool,
  type: PropTypes.string,
  selectedPin: PropTypes.instanceOf(Object),
};

export default PinPicker;
