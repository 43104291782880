/* eslint-disable no-underscore-dangle */
// export default InvoiceDetailsRow;
/* eslint-disable no-underscore-dangle */
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";

const InvoiceDetailsRow = ({ invoices = {} }) => (
  // Assuming `invoices` is an object where keys are some identifiers and values are arrays or objects
  <Table
    style={{
      marginBottom: "25px",
      borderBottom: "hidden",
    }}
  >
    <>
      <TableHead>
        <TableRow>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Title
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Unit Price
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Retail Price
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Quantity
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Partner Net Price
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Booking Fee (9.5%)
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Partner total Price
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Stripe Fees (3%)
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Profit
          </TableCell>
          <TableCell component="th" style={{ color: "dimgray" }}>
            Profit (AED)
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{invoices?.order?.items[0]?.ref?.title}</TableCell>
          <TableCell>
            {(invoices?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice || 0) /
              (invoices?.order?.totalPax || 1)}
          </TableCell>
          <TableCell>
            {invoices?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice}
          </TableCell>
          <TableCell>{invoices?.order?.totalPax}</TableCell>
          <TableCell>{invoices?.totalAmount?.priceAfterLokaleeDiscount?.partnerNetPrice}</TableCell>
          <TableCell>{invoices?.totalAmount?.priceAfterLokaleeDiscount?.bookingFee}</TableCell>
          <TableCell>
            {invoices?.totalAmount?.priceAfterLokaleeDiscount?.partnerTotalPrice}
          </TableCell>
          <TableCell>{invoices?.stripeFee}</TableCell>
          <TableCell>{invoices?.totalProfit || 0}</TableCell>
          <TableCell>{invoices?.totalNetProfitAED || 0}</TableCell>
        </TableRow>
      </TableBody>
    </>
  </Table>
);

InvoiceDetailsRow.propTypes = {
  invoices: PropTypes.instanceOf(Object),
};

export default InvoiceDetailsRow;
