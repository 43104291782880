// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import { capitalizeFirstLetter } from "components/helper";
import PropTypes from "prop-types";

const RolePicker = ({ role, roleV, setFieldValue, disabled = false, ...rest }) => (
  <Autocomplete
    id="roles"
    options={["Admin", "Curator", "Partner", "Concierge"]}
    onChange={(e, value) => {
      if (value) {
        setFieldValue("role", value.toLowerCase());
      } else {
        setFieldValue("role", "admin");
      }
    }}
    defaultValue="Admin"
    value={roleV ? capitalizeFirstLetter(roleV) : "Admin"}
    getOptionLabel={(option) => option || "Admin"}
    isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={role.label}
        name={role.name}
        disabled={disabled}
        onChange={params.onChange}
      />
    )}
  />
);

// typechecking props for RolePicker
RolePicker.propTypes = {
  role: PropTypes.instanceOf(Object).isRequired,
  roleV: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default RolePicker;
