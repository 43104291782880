import PropTypes from "prop-types";

export const defaultProps = {
  data: {
    invoiceNumber: "LK20240001",
    paymentMethod: "cash",
    items: [
      {
        ref: {
          title: "Towel",
        },
        totalPax: 2,
        itemTotalPrice: {
          price: {
            recommendedRetailPrice: 10,
          },
        },
        currency: "USD",
      },
      {
        ref: {
          title: "Towel2",
        },
        totalPax: 2,
        itemTotalPrice: {
          price: {
            recommendedRetailPrice: 20,
          },
        },
        currency: "USD",
      },
    ],
    totalPax: 4,
    createdAt: "2021-06-01T00:00:00.000Z",
    email: "test@test.com",
    user: {
      name: "John Doe",
      roomNumber: "1130",
    },
    totalAmount: {
      price: {
        recommendedRetailPrice: 30,
      },
    },
  },
};

export const propTypes = {
  data: {
    invoiceNumber: PropTypes.string,
    paymentMethod: PropTypes.string,
    items: PropTypes.array,
    totalAmount: PropTypes.object,
    totalPax: PropTypes.number,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    user: PropTypes.object,
  },
};
