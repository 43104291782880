/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
// import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "components/helper";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import DynamicPrimaryDestinationPicker from "components/AutoComplete/DynamicPrimaryDestinationPicker";
import Danger from "components/Danger";

const Information = ({
  formData,
  editPage = false,
  detailPage = true,
  handlePrimaryDestinationChange = () => {},
  selectedPrimaryDestination = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, fomoMessage, top, primaryDestinationId } = formField;
  const {
    title: titleV,
    fomoMessage: fomoMessageV,
    top: topV,
    primaryDestinationId: primaryDestinationV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            fomoMessage: values?.fomoMessage,
            top: values?.top,
            primaryDestination:
              values?.primaryDestinationId?.destinationName || selectedPrimaryDestination?.label,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="thumbnailHiResURL" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={fomoMessage.type}
                label={fomoMessage.label}
                name={fomoMessage.name}
                value={capitalizeFirstLetter(fomoMessageV)}
                placeholder={fomoMessage.placeholder}
                error={errors.fomoMessage && touched.fomoMessage}
                success={fomoMessageV.length > 0 && !errors.fomoMessage}
              />
            </Grid>
            <Grid item size={{ xs: 12 }} sx={{ mt: "16px" }}>
              <DynamicPrimaryDestinationPicker
                setFieldValue={setFieldValue}
                handleChange={handlePrimaryDestinationChange}
                primaryDestination={primaryDestinationId}
                primaryDestinationV={selectedPrimaryDestination || primaryDestinationV}
              />
              {errors?.primaryDestinationId && touched?.primaryDestinationId && (
                <Danger validation={errors?.primaryDestinationId} />
              )}
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={topV}
                      name={top.name}
                      onChange={(event) => setFieldValue("top", event.target.checked)}
                    />
                  }
                  label="Top"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  handlePrimaryDestinationChange: PropTypes.func,
};

export default Information;
