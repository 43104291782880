/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import timeList from "./TimesList";

const TimesPicker = ({
  times,
  timesV,
  setFieldValue,
  handleTimesChange = () => {},
  isObj = false,
  ...rest
}) => (
  <Autocomplete
    id="times"
    multiple
    filterSelectedOptions
    options={timeList}
    onChange={(event, value) => {
      if (value) {
        handleTimesChange(value);
        setFieldValue("times", value);
      } else {
        setFieldValue("times", []);
      }
    }}
    value={timesV || []}
    getOptionLabel={(option) => (isObj ? option?.label || "" : option || "")}
    isOptionEqualToValue={(option, value) =>
      isObj ? option.value === value.value : option === value
    }
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={times.label}
        name={times?.name}
        onChange={() => null}
      />
    )}
  />
);

// typechecking props for TimesPicker
TimesPicker.propTypes = {
  times: PropTypes.instanceOf(Object).isRequired,
  timesV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleTimesChange: PropTypes.func,
  isObj: PropTypes.bool,
};

export default TimesPicker;
