import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Section, Head, Html, Text, Button, Img } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import { Font } from "@react-email/font";

import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";

const RegisterUserEmail = ({
  user = {
    name: "Lokalee",
  },
  token = "https://lokalee.app",
}) => (
  <Html>
    <Head>
      <Font
        fontFamily="Helvetica"
        fallbackFontFamily="Arial"
        webFont={{
          url: "https://fonts.gstatic.com/s/helvetica/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
          format: "woff2",
        }}
        fontStyle="normal"
      />
    </Head>
    <Tailwind>
      <Body className="bg-gray-100">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-center pt-4 px-6 pb-5">
            <Img
              src="https://email-assets.lokalee.app/email/emailImages/Image.png"
              alt="Welcome"
              className="max-w-full h-auto"
            />
            <Text className="text-[24px] leading-[34px] font-normal mt-4">
              Greetings {user.name},
            </Text>
            <Text className="text-[28px] leading-[34px] font-bold mb-2">Welcome to Lokalee!</Text>
          </Section>

          <Section className="text-center bg-[#8C8C8C] p-5 text-white rounded-md mb-6">
            <Text className="text-[20px] leading-[23px] font-bold mb-1">
              We are happy to have you onboard!
            </Text>
            <Text className="text-[20px] leading-[23px] font-bold mt-2 mb-4">
              Please click on the button below to validate your email:
            </Text>
            <Button
              className="bg-white text-black text-[14px] leading-[14px] font-semibold py-2 px-4 rounded-md mx-auto"
              href={token}
            >
              Reset Password
            </Button>
            <Text className="text-[20px] leading-[23px] font-bold mt-4 mb-1">Thanks,</Text>
            <Text className="text-[20px] leading-[23px] font-bold mt-1">Lokalee Team</Text>
          </Section>

          <IfYouNeedSupportSection />

          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

RegisterUserEmail.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  token: PropTypes.string,
};

export default RegisterUserEmail;
