/* eslint-disable no-underscore-dangle */

// libraries
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";

// custom components
import FormField from "components/FormField";

// actions
import { getUserPartnerLocations, getLocations, setLoading } from "store/actions/locationAction";

const LocationPicker = ({
  location = {},
  setFieldValue,
  locationOnly = false,
  selectedLocation = {},
  handleLocationChange = () => {},
  disabled = false,
  tktPage = false,
  conciergeUserPage = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const locations = useSelector((state) => state.locations.locations);

  // get locations
  useEffect(() => {
    setLoading();
    if (user?.role !== "partner") dispatch(getLocations());
    else {
      dispatch(getUserPartnerLocations(user?._id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Autocomplete
      // id={idName}
      value={selectedLocation}
      options={[...locations, {}]}
      disabled={disabled}
      // options={locations}
      onChange={(e, option) => {
        handleLocationChange(option, setFieldValue);
        if (locationOnly) {
          if (!option) {
            setFieldValue("location", "");
            setFieldValue("locDestinations.location", "");
            setFieldValue("recommendation", undefined);
          } else {
            setFieldValue("location", option);
            if (conciergeUserPage) {
              setFieldValue("locDestinations.location", option);
              setFieldValue("locDestinations.destinations", "");
              setFieldValue("destinations", []);
            }

            if (conciergeUserPage) setFieldValue("city", "");
          }
        } else if (!option) {
          setFieldValue("address", "");
          setFieldValue("city", "");
          setFieldValue("region", "");
          setFieldValue("country", "");
          setFieldValue("lng", "");
          setFieldValue("lat", "");
          setFieldValue("disabled", false);
          setFieldValue("location", undefined);
          setFieldValue("exclusiveLocation", false);
          setFieldValue("exclusivePartner", false);
          setFieldValue("appearanceLocation", null);
        } else if (!tktPage) {
          setFieldValue("location", option || {});
          setFieldValue("appearanceLocation", option || "");
          setFieldValue("partner", option?.partner?._id ? option?.partner?._id : "");

          setFieldValue("disabled", true);

          setFieldValue(
            "address",
            option?.geoLocation?.address
              ? option?.geoLocation?.address
              : option?.geoLocationData?.address || ""
          );
          setFieldValue(
            "city",
            option?.geoLocation?.city
              ? option?.geoLocation?.city
              : option?.geoLocationData?.city || ""
          );
          setFieldValue(
            "region",
            option?.geoLocation?.region
              ? option?.geoLocation?.region
              : option?.geoLocationData?.region || ""
          );
          setFieldValue(
            "country",
            option?.geoLocation?.country
              ? option?.geoLocation?.country
              : option?.geoLocationData?.country || ""
          );
          setFieldValue(
            "lng",
            option?.geoLocation?.coordinates[0] ? option?.geoLocation?.coordinates[0] : ""
          );
          setFieldValue(
            "lat",
            option?.geoLocation?.coordinates[1] ? option?.geoLocation?.coordinates[1] : ""
          );
        } else {
          setFieldValue("location", option || {});
        }
      }}
      getOptionLabel={(option) => option?.name || ""}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          label={location?.label}
          name={location?.name}
          onChange={() => null} // added to prevent the error while earasing the search value
        />
      )}
    />
  );
};

LocationPicker.propTypes = {
  location: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func.isRequired,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  locationOnly: PropTypes.bool,
  conciergeUserPage: PropTypes.bool,
  disabled: PropTypes.bool,
  tktPage: PropTypes.bool,
};

export default LocationPicker;
