/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Custom Components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useSelector } from "react-redux";
import Spinner from "components/Spinner";

const ViewEditActions = ({
  detailPage = true,
  noEditIcon = false,
  ticketPage = false,
  stateName = "",
  handleClose = () => {},
  handleEdit = () => {},
  handleSave = () => {},
}) => {
  const isLoading = useSelector((state) => state[stateName]?.loading);
  const loadUser = useSelector((state) => state.users?.loadUser);

  // Modified handleClose function to reload the page
  const handleCancelEdit = () => {
    handleClose();
  };

  return (
    <MDBox>
      {detailPage && !noEditIcon && !ticketPage ? (
        <MDButton
          variant="text"
          color="dark"
          iconOnly
          onClick={handleEdit}
          style={{ padding: 0, minHeight: "16px", height: "16px" }}
        >
          <Tooltip title="Edit" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDButton>
      ) : (
        !noEditIcon &&
        !ticketPage && (
          <MDBox style={{ display: "flex" }}>
            <MDButton
              variant="text"
              color="dark"
              iconOnly
              onClick={handleCancelEdit} // Use the modified handleClose function
              style={{ padding: 0, minHeight: "16px", height: "16px" }}
            >
              <Tooltip title="Cancel edit" placement="top">
                <Icon>close</Icon>
              </Tooltip>
            </MDButton>
            <MDButton
              variant="text"
              color="dark"
              iconOnly
              onClick={handleSave}
              type="submit"
              style={{ padding: 0, minHeight: "16px", height: "16px" }}
            >
              <Tooltip title="Save info" placement="top">
                <Icon>save</Icon>
              </Tooltip>
            </MDButton>
          </MDBox>
        )
      )}
      <Spinner loading={stateName === "users" ? loadUser : isLoading} />
    </MDBox>
  );
};

// typechecking props for ViewEditActions
ViewEditActions.propTypes = {
  detailPage: PropTypes.bool,
  noEditIcon: PropTypes.bool,
  ticketPage: PropTypes.bool,
  stateName: PropTypes.string,
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
};

export default ViewEditActions;
