/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LocationPicker from "components/AutoComplete/LocationPicker";
import DetailList from "components/DetailList";
import FormField from "components/FormField";
import IconsPicker from "components/AutoComplete/IconsPicker";
import { capitalizeFirstLetter } from "components/helper";

const TransportationServicesInfo = ({
  formData,
  detailPage = true,
  selectedLocation = {},
  handleLocationChange = () => {},
  selectedIcon = {},
  handleIconChange = () => {},
  editPage = false,
  locationObj = {},
  iconsObj = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, location, logoName, logo } = formField;
  const { title: titleV, location: locationV, logoName: logoNameV } = values;

  return (
    <MDBox>
      {!editPage && (
        <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
            Transportation Services info
          </MDTypography>
        </MDBox>
      )}
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            location: values?.location,
            logoName: values?.logoName,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={title.type}
                label={title?.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <LocationPicker
                setFieldValue={setFieldValue}
                location={location}
                locationOnly
                selectedLocation={selectedLocation?._id ? selectedLocation : locationObj}
                handleLocationChange={handleLocationChange}
                error={errors.location && touched.location}
                success={locationV?.name && !errors.location}
              />
            </Grid>
          </Grid>
          <Grid container>
            <MDBox>
              <IconsPicker
                logoName={logoName}
                logo={logo}
                selectedIcon={selectedIcon || iconsObj || {}}
                handleIconChange={handleIconChange}
                setFieldValue={setFieldValue}
                error={errors.logoName && touched.logoName}
                success={logoNameV?.name && !errors.logoName}
              />
            </MDBox>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for AdditionalCuratorInfo
TransportationServicesInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  // detailPage: PropTypes.bool.isRequired,

  detailPage: PropTypes.bool,
  locationObj: PropTypes.instanceOf(Object),
  iconsObj: PropTypes.instanceOf(Object),
  editPage: PropTypes.bool,
  selectedLocation: PropTypes.instanceOf(Object),
  selectedIcon: PropTypes.instanceOf(Object),

  handleLocationChange: PropTypes.func,
  handleIconChange: PropTypes.func,
};

export default TransportationServicesInfo;
