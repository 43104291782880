/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import { capitalizeFirstLetter } from "components/helper";

const Information = ({ formData, editPage = false, detailPage = true }) => {
  const { formField, values, errors, touched } = formData;
  const { name, website, address, contactName, contactEmail, contactPhone } = formField;
  const {
    name: nameV,
    website: websiteV,
    address: addressV,
    contactName: contactNameV,
    contactEmail: contactEmailV,
    contactPhone: contactPhoneV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            name: values?.name,
            website: values?.website,
            address: values?.address,
            contactName: values?.contactName,
            contactEmail: values?.contactEmail,
            contactPhone: values?.contactPhone,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={name?.type}
                label={name.label}
                name={name.name}
                value={capitalizeFirstLetter(nameV)}
                placeholder={name.placeholder}
                error={errors?.name && touched?.name}
                success={nameV?.length > 0 && !errors?.name}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                // type={contactName.type}
                label={contactName.label}
                name={contactName.name}
                value={contactNameV}
                placeholder={contactName.placeholder}
                error={errors.contactName && touched.contactName}
                success={contactNameV.length > 0 && !errors.contactName}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                // type={contactPhone.type}
                label={contactPhone.label}
                name={contactPhone.name}
                value={contactPhoneV}
                placeholder={contactPhone.placeholder}
                error={errors.contactPhone && touched.contactPhone}
                success={contactPhoneV.length > 0 && !errors.contactPhone}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={contactEmail.type}
                label={contactEmail.label}
                name={contactEmail.name}
                value={contactEmailV}
                placeholder={contactEmail.placeholder}
                error={errors?.contactEmail && touched?.contactEmail}
                success={contactEmailV?.length > 0 && !errors?.contactEmail}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={website.type}
                label={website.label}
                name={website.name}
                value={websiteV}
                placeholder={website.placeholder}
                error={errors?.website && touched?.website}
                success={websiteV?.length > 0 && !errors?.website}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={address.type}
                label={address.label}
                name={address.name}
                value={addressV}
                multiline
                outlined
                rows={5}
                placeholder={address.placeholder}
                error={errors.address && touched.address}
                success={addressV.length > 0 && !errors.address}
              />
            </Grid>{" "}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default Information;
