/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import TicketInfo from "pages/items/tickets/new-ticket/components/TicketInfo";
import Content from "pages/items/tickets/new-ticket/components/Content";
import Media from "pages/items/tickets/new-ticket/components/Media";
import SchedulesAndPricing from "pages/items/tickets/new-ticket/components/SchedulesAndPricingInfo";
import BookingAndTickets from "pages/items/tickets/new-ticket/components/BookingAndTickets";
import { useState } from "react";

const TicketDetail = ({
  formData,
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  selectedLocation = {},
  handleLocationChange = () => {},
  handleTitleIncChange = () => {},
  handleTitleExcChange = () => {},
  handlePinChange = () => {},
  selectedPin = {},
  setSelectedPin = () => {},
  selectedVenue = {},
  setSelectedPickupPoint = () => {},
  setSelectedVenue = () => {},
  setSelectedTitleExc = () => {},
  setSelectedTitleInc = () => {},
  handleVenueChange = () => {},
  selectedTitleInc = {},
  selectedTitleExc = {},
  setSelectedPrimaryDestination = () => {},
  handlePrimaryDestinationChange = () => {},
  handleSecondaryDestinationChange = () => {},
  selectedPrimaryDestination = {},
  selectedCurrency = {},
  handleCurrencyChange = () => {},
  selectedSecondaryDestination = {},
  handleTagChange = () => {},
  selectedTag = {},
  selectedCurator = {},
  handleCuratorChange = () => {},
  setSelectedCurator = () => {},
  handleTimeActChange = () => {},
  selectedTimeAct = {},
  handleActivityChange = () => {},
  selectedActivity = {},
  handleBookingConfirmationChange = () => {},
  selectedBookingConfirmation = {},
  handlePickupPointChange = () => {},
  selectedPickupPoint = {},
}) => {
  const { submitForm, resetForm } = useFormikContext();
  const [addSchedule, setAddSchedule] = useState(false);
  const handleCardTitle = () => {
    if (type === "ticketInfo") {
      return "Ticket info";
    }
    if (type === "content") {
      return "Content";
    }
    if (type === "media") {
      return "Media";
    }
    if (type === "schedulesAndPricing") {
      return "Schedule & Pricing";
    }

    return "Booking & Tickets";
  };

  const handleInfoCard = () => {
    if (type === "ticketInfo") {
      return (
        <TicketInfo
          selectedCurator={selectedCurator}
          handleCuratorChange={handleCuratorChange}
          setSelectedCurator={setSelectedCurator}
          handlePrimaryDestinationChange={handlePrimaryDestinationChange}
          selectedPrimaryDestination={selectedPrimaryDestination}
          handleSecondaryDestinationChange={handleSecondaryDestinationChange}
          selectedSecondaryDestination={selectedSecondaryDestination}
          setSelectedPrimaryDestination={setSelectedPrimaryDestination}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedTag={selectedTag}
          handleTagChange={handleTagChange}
        />
      );
    }
    if (type === "content") {
      return (
        <Content
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          handleTitleExcChange={handleTitleExcChange}
          handlePinChange={handlePinChange}
          selectedPin={selectedPin}
          setSelectedPin={setSelectedPin}
          setSelectedVenue={setSelectedVenue}
          setSelectedTitleInc={setSelectedTitleInc}
          setSelectedTitleExc={setSelectedTitleExc}
          selectedVenue={selectedVenue}
          handleVenueChange={handleVenueChange}
          handleTitleIncChange={handleTitleIncChange}
          selectedTitleInc={selectedTitleInc}
          selectedTitleExc={selectedTitleExc}
          handlePickupPointChange={handlePickupPointChange}
          selectedPickupPoint={selectedPickupPoint}
          setSelectedPickupPoint={setSelectedPickupPoint}
        />
      );
    }

    if (type === "media") {
      return <Media formData={formData} editPage detailPage={detailPage} />;
    }

    if (type === "schedulesAndPricing") {
      return (
        <SchedulesAndPricing
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedCurrency={selectedCurrency}
          handleCurrencyChange={handleCurrencyChange}
          setAddSchedule={setAddSchedule}
          addSchedule={addSchedule}
        />
      );
    }
    if (type === "bookingAndTickets") {
      return (
        <BookingAndTickets
          formData={formData}
          editPage
          detailPage={detailPage}
          handleTimeActChange={handleTimeActChange}
          selectedTimeAct={selectedTimeAct}
          selectedActivity={selectedActivity}
          handleActivityChange={handleActivityChange}
          handleBookingConfirmationChange={handleBookingConfirmationChange}
          selectedBookingConfirmation={selectedBookingConfirmation}
        />
      );
    }
    return null;
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// typechecking props for TicketDetail
TicketDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  setSelectedPrimaryDestination: PropTypes.func,
  handlePrimaryDestinationChange: PropTypes.func,
  handleCurrencyChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  setSelectedCurator: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  selectedCurrency: PropTypes.instanceOf(Object),
  selectedSecondaryDestination: PropTypes.instanceOf(Array),
  handleTagChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Array),
  selectedCurator: PropTypes.instanceOf(Object),
  handleCuratorChange: PropTypes.func,
  handleTimeActChange: PropTypes.func,
  handleActivityChange: PropTypes.func,
  handleBookingConfirmationChange: PropTypes.func,
  handleTitleExcChange: PropTypes.func,
  setSelectedPickupPoint: PropTypes.func,
  handlePinChange: PropTypes.func,
  handleTitleIncChange: PropTypes.func,
  setSelectedPin: PropTypes.func,
  setSelectedVenue: PropTypes.func,
  setSelectedTitleExc: PropTypes.func,
  setSelectedTitleInc: PropTypes.func,
  handleVenueChange: PropTypes.func,
  selectedTitleExc: PropTypes.instanceOf(Object),
  selectedVenue: PropTypes.instanceOf(Object),
  selectedPin: PropTypes.instanceOf(Object),
  selectedTitleInc: PropTypes.instanceOf(Object),
  selectedBookingConfirmation: PropTypes.instanceOf(Object),
  selectedActivity: PropTypes.instanceOf(Object),
  selectedTimeAct: PropTypes.instanceOf(Object),
  handlePickupPointChange: PropTypes.func,
  selectedPickupPoint: PropTypes.instanceOf(Object),
};

export default TicketDetail;
