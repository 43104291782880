import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { styled } from "@mui/material/styles";

// Import Flatpickr styles
import "flatpickr/dist/flatpickr.css";

import MDInput from "components/MDInput";
import { FormControl } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ErrorMessage } from "formik";

const MDInputF = styled(MDInput)(({ disabled }) => ({
  backgroundColor: "transparent!important",
  opacity: disabled ? 0.6 : 1,
  "& label": {
    fontWeight: 400,
  },
}));

const MDTimePicker = ({ input = {}, ...rest }) => (
  <FormControl style={{ width: "100%" }}>
    <Flatpickr
      options={{
        enableTime: true, // Enable time selection
        noCalendar: true, // Time picker only (no date)
        dateFormat: "H:i", // small h to display 12 hours format capital H for 24 hours format i for minutes K for am/pm
        static: true,
      }}
      {...rest}
      render={({ defaultValue }, ref) => (
        <MDInputF {...input} defaultValue={defaultValue} inputRef={ref} />
      )}
    />
    <MDBox mt={0.75}>
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        <ErrorMessage name={input?.name} />
      </MDTypography>
    </MDBox>
  </FormControl>
);

// Typechecking props for the MDTimePicker
MDTimePicker.propTypes = {
  input: PropTypes.instanceOf(Object),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default MDTimePicker;
