/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import Grid from "@mui/material/Grid2";
import AddressPicker from "components/AddressPicker";
import LocationPicker from "components/AutoComplete/LocationPicker";
import Danger from "components/Danger";
import FormField from "components/FormField";
import { capitalizeFirstLetter } from "components/helper";
import LocationUserPartner from "components/LocationUserPartner";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

const AddressLoc = ({
  formData,
  currentLocation = {},
  setFieldValue,
  selectedLocation = {},
  handleLocationChange = () => {},
  recPage = false,
  tktPage = false,
  destinationPage = false,
}) => {
  const { formField, values, errors, touched } = formData;
  const { address, city, region, country, lng, lat, location, country_code } = formField;
  const {
    address: addressV,
    country_code: country_codeV,
    city: cityV,
    region: regionV,
    country: countryV,
    lng: lngV,
    lat: latV,
  } = values;

  const user = useSelector((state) => state.users.user);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartnerId = useSelector((state) => state.locations.location);
  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);
  const [disbaled, setDisabled] = useState(false);

  useEffect(() => {
    if (selectedLocation && selectedLocation?.name?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (
      (user.role === "partner" && locationPartnerId?.length > 0) ||
      (user.role === "partner" && locations[0]?._id?.length > 0)
    ) {
      setFieldValue(
        "address",
        locationPartner?.geoLocationData?.address || locations[0]?.geoLocationData?.address
      );
      setFieldValue(
        "city",
        locationPartner?.geoLocationData?.city || locations[0]?.geoLocationData?.city
      );
      setFieldValue(
        "country",
        locationPartner?.geoLocationData?.country || locations[0]?.geoLocationData?.country
      );
      setFieldValue(
        "region",
        locationPartner?.geoLocationData?.region || locations[0]?.geoLocationData?.region
      );
      setFieldValue(
        "lng",
        locationPartner?.geoLocation?.coordinates[0] || locations[0]?.geoLocation?.coordinates[0]
      );
      setFieldValue(
        "lat",
        locationPartner?.geoLocation?.coordinates[1] || locations[0]?.geoLocation?.coordinates[1]
      );
    } else if (user.role === "admin") {
      setFieldValue("address", values?.address);
      setFieldValue("city", values?.city);
      setFieldValue("country", values?.country);
      setFieldValue("region", values?.region);
      setFieldValue("lng", values?.lng);
      setFieldValue("lat", values?.lat);
    }
  }, [user.role, locationPartnerId, locations[0]?._id]);

  return (
    <MDBox mt={1.625}>
      <Grid container direction="row" spacing={3}>
        <Grid item size={{ xs: 6 }} sx={{ mb: 2 }}>
          <AddressPicker
            setFieldValue={setFieldValue}
            currentLocation={currentLocation}
            disabled={recPage && user.role === "partner"}
          />
          <Danger validation={errors?.address} />
        </Grid>
      </Grid>
      {location && !destinationPage ? (
        <Grid container direction="row" spacing={3}>
          {user.role === "admin" && location ? (
            <Grid item size={{ xs: 6 }}>
              <LocationPicker
                setFieldValue={setFieldValue}
                location={location}
                selectedLocation={selectedLocation}
                handleLocationChange={handleLocationChange}
                tktPage={tktPage}
                locationOnly={tktPage}
              />
            </Grid>
          ) : (
            <Grid item size={{ xs: 6 }} sx={{ mb: 3 }}>
              <LocationUserPartner
                defaultLocationId={locationPartner?._id || locations[0]?._id}
                disabled
                handleChange={handleLocationChange}
                locations={locations || {}}
                location={location}
              />
            </Grid>
          )}
          <Grid item size={{ xs: 12 }}>
            {address && (
              <FormField
                type={address.type}
                label={address.label}
                name={address.name}
                value={addressV}
                placeholder={address.placeholder}
                error={user.role === "admin" && errors?.address && touched?.address}
                success={addressV?.length > 0 && !errors?.addressV}
                disabled={
                  (recPage && values?.location?.geoLocation?.city) ||
                  (recPage && user.role === "partner")
                }
              />
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <MDBox>
        <Grid container direction="row" spacing={3}>
          <Grid item size={{ sm: 4 }}>
            <FormField
              type={city.type}
              label={city.label}
              name={city.name}
              value={cityV?.length > 0 ? capitalizeFirstLetter(cityV) : ""}
              placeholder={city.placeholder}
              disabled={
                (recPage && values?.location?.geoLocation?.city) ||
                (recPage && user.role === "partner")
              }
            />
          </Grid>
          <Grid item size={{ sm: 4 }}>
            <FormField
              type={country.type}
              label={country.label}
              name={country.name}
              value={countryV}
              placeholder={country.placeholder}
              disabled={
                (recPage && values?.location?.geoLocation?.city) ||
                (recPage && user.role === "partner")
              }
            />
          </Grid>
          {country_code && (
            <Grid item size={{ xs: 4 }}>
              <FormField
                type={country_code.type}
                label={country_code.label}
                name={country_code.name}
                value={country_codeV}
                placeholder={country_code.placeholder}
                disabled={disbaled}
              />
            </Grid>
          )}
          {region && (
            <Grid item size={{ sm: 4 }}>
              <FormField
                type={region.type}
                label={region.label}
                name={region.name}
                value={regionV}
                placeholder={region.placeholder}
                disabled={
                  (recPage && values?.location?.geoLocation?.city) ||
                  (recPage && user.role === "partner")
                }
              />
            </Grid>
          )}
        </Grid>
      </MDBox>

      <Grid container direction="row" spacing={3}>
        <Grid item size={{ sm: 6 }}>
          <FormField
            type={lng.type}
            label={lng.label}
            name={lng.name}
            value={lngV}
            placeholder={lng.placeholder}
            disabled={
              (recPage && values?.location?.geoLocation?.city) ||
              (recPage && user.role === "partner")
            }
          />
        </Grid>
        <Grid item size={{ sm: 6 }}>
          <FormField
            type={lat.type}
            label={lat.label}
            name={lat.name}
            value={latV}
            placeholder={lat.placeholder}
            disabled={
              (recPage && values?.location?.geoLocation?.city) ||
              (recPage && user.role === "partner")
            }
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

// typechecking props for AddressLoc
AddressLoc.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  currentLocation: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func.isRequired,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  recPage: PropTypes.bool,
  tktPage: PropTypes.bool,
  destinationPage: PropTypes.bool,
};

export default AddressLoc;
