/* eslint-disable react/require-default-props */
/* eslint-disable arrow-body-style */
import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Html, Section, Text, Img } from "@react-email/components";
import HeadSection from "../HeadSection";
import { defaultProps } from "./Proptypes";

const EmailToGuest = ({ user, data }) => {
  return (
    <Html>
      <HeadSection />
      <Body
        style={{
          backgroundColor: "#f7fafc",
          lineHeight: "1.625",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Container
          style={{
            backgroundColor: "#ffffff",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "0",
            maxWidth: "600px",
            border: "1px solid #eaeaea",
          }}
        >
          {/* Logo Section */}
          <Section
            style={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "center",
              paddingBottom: "1.25rem",
              backgroundColor: "#F2F2F2",
            }}
          >
            <Img
              alt="Logo"
              src="https://email-assets.lokalee.app/email/emailImages/logo.png"
              style={{
                width: "100%",
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1.25rem",
              }}
            />
          </Section>

          {/* Greeting Section */}
          <Section
            style={{
              textAlign: "center",
              paddingTop: "1.5rem",
              paddingBottom: "1rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <Text
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "#222D29",
                marginBottom: "0.5rem",
              }}
            >
              Dear {user.name},
            </Text>
            <Text
              style={{
                fontSize: "16px",
                color: "#222D29",
                marginBottom: "1rem",
              }}
            >
              Thank you for choosing our services. We are pleased to process your request and
              deliver as soon as possible.
            </Text>
          </Section>

          {/* Order Details Section */}
          <Section
            style={{
              backgroundColor: "#F2F2F2",
              padding: "16px 24px",
              borderRadius: "11.54px",
              margin: "0 auto 24px auto",
              maxWidth: "536px",
            }}
          >
            <Text
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#222D29",
                marginBottom: "16px",
              }}
            >
              Order Number: {data.invoiceNumber}
            </Text>
            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
                marginBottom: "16px",
              }}
            >
              Room No: {user.roomNumber}
            </Text>
            <Section>
              {/* Header Row */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                  borderBottom: "1px solid #ddd",
                  paddingBottom: "8px",
                }}
              >
                <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                  ORDER DETAILS
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#222D29",
                    textAlign: "center",
                    flex: "1",
                  }}
                >
                  QUANTITY
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#222D29",
                    textAlign: "right",
                    flex: "1",
                  }}
                >
                  PRICE
                </Text>
              </div>

              {/* Value Row */}
              {data.items.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <Text style={{ fontSize: "14px", color: "#222D29" }}>{item.ref.title}</Text>
                    <Text
                      style={{ fontSize: "14px", color: "#222D29", textAlign: "center", flex: "1" }}
                    >
                      {item.totalPax}
                    </Text>
                    <Text
                      style={{
                        fontSize: "14px",
                        color: "#222D29",
                        textAlign: "right",
                        flex: "1",
                      }}
                    >
                      {item.currency} {item.itemTotalPrice.price.recommendedRetailPrice}
                    </Text>
                  </div>
                );
              })}
            </Section>

            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginTop: "16px",
                marginBottom: "10px",
                color: "#222D29",
              }}
            >
              PAYMENT METHOD
            </Text>
            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
                marginBottom: "16px",
              }}
            >
              {data.paymentMethod}
            </Text>
          </Section>

          {/* Assistance Section */}
          <Section
            style={{
              textAlign: "center",
              backgroundColor: "#ffffff",
              padding: "1rem",
            }}
          >
            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
              }}
            >
              Our staff are available for assistance at any time.
            </Text>
          </Section>

          {/* Footer Section */}
          <Section
            style={{
              textAlign: "left",
              padding: "1rem",
              borderTop: "1px solid #ddd",
              marginTop: "1.5rem",
            }}
          >
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#222D29",
                marginBottom: "0.5rem",
              }}
            >
              Enjoy your stay,
            </Text>
            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
              }}
            >
              {/* {hotelName} */}
              Management
            </Text>
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

EmailToGuest.propTypes = {
  user: {
    name: PropTypes.string,
    roomNumber: PropTypes.string,
  },
  data: {
    invoiceNumber: PropTypes.string,
    items: PropTypes.array,
    paymentMethod: PropTypes.string,
  },
};
EmailToGuest.defaultProps = defaultProps;

export default EmailToGuest;
