/* eslint-disable camelcase */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, icon },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, title.invalidMsg)
      .required(title.errorMsg),
    [icon.name]: Yup.string().required(icon.errorMsg).nullable(),
  }),
];

export default validations;
