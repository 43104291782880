import React from "react";
import { Body, Container, Section, Html, Text } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import PropTypes from "prop-types";
import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";
import HeadSection from "../HeadSection";

const UpdateUserProfileEmail = ({ user = { name: "Lokalee" }, token = "961" }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-center bg-white p-5 text-black">
            <Text className="text-[35px] leading-[26.5px] font-bold text-black  mb-2">
              Hello {user.name},
            </Text>
            <Text className="mb-4 text-[25px] leading-[26.5px] font-bold text-black">
              Thank you for using our app.
            </Text>
            <Text className="mb-4 text-[14px] leading-[18px] font-normal text-black">
              Please use the code below to confirm your email address.
            </Text>
            <Text className="text-[25px] leading-[26.5px] font-bold mb-6 mt-4">{token}</Text>
            <Text className="text-[14px] leading-[18px] font-normal text-black">
              If you did not request a password reset, please just ignore this email.
            </Text>
          </Section>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

UpdateUserProfileEmail.propTypes = {
  token: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default UpdateUserProfileEmail;
