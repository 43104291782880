import React from "react";
import { Button, Img, Link, Section, Text } from "@react-email/components";

import PropTypes from "prop-types";

const ReservationCard = ({ data, action = null }) => {
  /* ****** Constants ****** */
  const { guests = 0, time = "", date = "", name, phone, email } = data;
  const links = [
    {
      href: `https://wa.me/${phone}`,
      src: "https://email-assets.lokalee.app/email/emailImages/whatsapp-fill.svg",
      label: "Whatsapp",
    },
    {
      href: `mailto:${email}`,
      src: "https://email-assets.lokalee.app/email/emailImages/mail-line.svg",
      label: "Email",
    },
    {
      href: `tel:${phone}`,
      src: "https://email-assets.lokalee.app/email/emailImages/phone-line.svg",
      label: "Phone",
    },
  ];

  const handleDetails = (field, value) => (
    <Text
      numberOfLines={1}
      style={{
        margin: "0",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26.5px",
      }}
    >
      {field}: {value}
    </Text>
  );
  const callForAction = () => (
    <div
      style={{
        gap: "15px",
        display: "flex",
        marginTop: "20px",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Button
        href={action}
        style={{
          width: "100%",
          height: "38px",
          display: "flex",
          color: "#FFFFFF",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "14px",
          borderRadius: "4px",
          textAlign: "center",
          padding: "10px 0px",
          alignItems: "center",
          textDecoration: "none",
          justifyContent: "center",
          backgroundColor: "#222D29",
        }}
      >
        Confirm Reservation
      </Button>

      <div>
        <Text
          style={{
            margin: 0,
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "23px",
            fontFamily: "Arial",
            textAlign: "center",
          }}
        >
          Or contact the guest via:
        </Text>

        <div
          style={{
            gap: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {links.map(({ href, src, label }) => (
            <div
              style={{
                gap: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                href={href}
                style={{
                  gap: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Img src={src} alt={label} className="w-[34px] h-[32px]" />
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#222D29",
                    fontWeight: "400",
                    lineHeight: "26.5px",
                    textDecoration: "underline",
                  }}
                >
                  {label}
                </Text>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <Section
      style={{
        borderRadius: "12px",
        padding: "16px 24px",
        fontFamily: "Helvetica",
        backgroundColor: "#F2F2F2",
      }}
    >
      <Text
        style={{
          fontSize: "22px",
          fontWeight: "700",
          marginBottom: "12px",
          lineHeight: "25.75px",
        }}
      >
        Reservation Details
      </Text>

      <div
        style={{
          gap: "8px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {handleDetails("Guests", guests)}
        {handleDetails("Reservation Time", time)}
        {handleDetails("Reservation Date", date)}
      </div>

      <div style={{ border: "1px solid #BAB9B9", margin: "16px 0" }} />

      <Text
        style={{
          fontSize: "22px",
          fontWeight: "700",
          marginBottom: "12px",
          lineHeight: "25.75px",
        }}
      >
        Guest Details
      </Text>

      <div
        style={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {handleDetails("Full Name", name)}
        {handleDetails("Phone Number", phone)}
        {handleDetails("Email", email)}
      </div>

      {action && callForAction()}
    </Section>
  );
};

ReservationCard.propTypes = {
  data: PropTypes.shape({
    guests: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  action: PropTypes.string,
};

export default ReservationCard;
