/* eslint-disable no-underscore-dangle */
import * as Yup from "yup";
import onlineStoreForm from "./form";

const { productName, productCode, price, currency, tag, subTag } =
  onlineStoreForm.addProductsFields;
const { paymentMethod, orderProcessingType, staffEmail, systemPicker } =
  onlineStoreForm.settingFields;

const onlineStoreValidation = {
  addProductValidation: Yup.object().shape({
    [subTag.name]: Yup.object(),
    [productCode.name]: Yup.string(),
    [productName.name]: Yup.string()
      .required(productName.errorMessage)
      .matches(/[a-zA-Z]/, productName.invalidMessage),
    [price.name]: Yup.number()
      .typeError(price.invalidMessage)
      .required(price.errorMessage)
      .min(1, price.invalidMessage),
    [tag.name]: Yup.object()
      .test("tag-required", tag.errorMessage, (value) => value && value._id)
      .nullable(),
    [currency.name]: Yup.object()
      .test("currency-required", currency.errorMessage, (value) => value && value.code)
      .nullable(),
  }),

  settingValidation: Yup.object().shape({
    [paymentMethod.name]: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(paymentMethod.errorMessage),
        })
      )
      .min(1, paymentMethod.errorMessage)
      .required(paymentMethod.errorMessage),

    [orderProcessingType.name]: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(orderProcessingType.errorMessage),
        })
      )
      .min(1, orderProcessingType.errorMessage)
      .required(orderProcessingType.errorMessage),

    [staffEmail.name]: Yup.string()
      .nullable()
      .test(
        "is-required-for-emailToCs",
        staffEmail.errorMessage,
        function isRequiredForEmailToCs(value) {
          const { orderProcessingType: parentOrderProcessingType } = this.parent;
          return Array.isArray(parentOrderProcessingType) &&
            parentOrderProcessingType.some((item) => item.name === "emailToCs")
            ? !!value
            : true;
        }
      ),

    [systemPicker.name]: Yup.string()
      .nullable()
      .test(
        "is-required-for-guestRequest",
        "System picker is required.",
        function isRequiredForGuestRequest(value) {
          const { orderProcessingType: parentOrderProcessingType } = this.parent;
          return Array.isArray(parentOrderProcessingType) &&
            parentOrderProcessingType.some((item) => item.name === "guestRequest")
            ? !!value
            : true;
        }
      ),
  }),
};

export default onlineStoreValidation;
