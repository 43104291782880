/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
// import React from "react";
// import PropTypes from "prop-types";
// import { Section, Text, Button, Img } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";

// const findBestVariant = (variants, width) => {
//   // Sort variants by width in ascending order
//   const sortedVariants = variants.sort((a, b) => a.width - b.width);
//   // Find the best match
//   return (
//     sortedVariants.find((variant) => variant.width >= width) ||
//     sortedVariants.find((variant) => variant.width >= 500) ||
//     sortedVariants[sortedVariants.length - 1]
//   );
// };
// const getCurrencyCharacter = (currency) => {
//   switch (currency) {
//     case "EUR":
//       return "€";
//     case "AED":
//       return "AED";
//     case "SAR":
//       return "SR";
//     case "GBP":
//       return "£";
//     case "CAD":
//       return "C$";
//     case "AUD":
//       return "A$";
//     case "EGP":
//       return "E£";
//     case "TRY":
//       return "₺";
//     default:
//       return "$";
//   }
// };
// const convertToAMPM = (time24) => {
//   // Splitting hours and minutes
//   const timeSplit = time24.split(":");
//   let hours = parseInt(timeSplit[0], 10);
//   const minutes = timeSplit[1];

//   // Determining AM/PM
//   const period = hours >= 12 ? "PM" : "AM";

//   // Converting hours to 12-hour format
//   hours %= 12;
//   hours = hours || 12; // '0' should be '12' in 12-hour format

//   // Formatting the time in AM/PM format
//   const time12 = `${hours}:${minutes} ${period}`;

//   return time12;
// };
// const DiscountedPrice = ({ price, currency, discount }) => (
//   <div className="mt-4 mb-4">
//     <div className="flex justify-between">
//       <Text className="text-[14px] leading-[14px] mb-1">Subtotal:</Text>
//       <Text className="text-[14px] leading-[14px] mb-1">
//         {getCurrencyCharacter(currency || "USD")}
//         {price.recommendedRetailPrice}
//       </Text>
//     </div>
//     <div className="flex justify-between ">
//       <Text className="text-[14px] leading-[14px] mt-1">Discount:</Text>
//       <Text className="text-[14px] leading-[14px] mt-1">
//         {getCurrencyCharacter(currency || "USD")}
//         {discount}
//       </Text>
//     </div>
//     <hr className="border-t-2  border-[#BAB9B9] w-[488px]" />
//   </div>
// );

// DiscountedPrice.propTypes = {
//   price: PropTypes.shape({
//     recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   }).isRequired,
//   discount: PropTypes.number.isRequired,
// };
// const TicketDetails = ({ data, isProvider, noDescription, totalPrice }) => {
//   const coverImage =
//     data.ref.coverImage?.variants?.length > 0
//       ? findBestVariant(data?.ref.coverImage?.variants, 500).url
//       : `${data.ref.coverImage}`;
//   return (
//     <>
//       <Text className="text-[14.43px] leading-[49.05px] font-bold mt-1 mb-2">
//         Details of your booking
//       </Text>
//       <Img src={coverImage} alt="Booking Image" className="w-full h-auto rounded" />
//       <Text className="text-[22px] leading-[23.55px] font-normal mt-2 text-[#222D29]">
//         {data?.ref?.title}
//       </Text>
//       {!isProvider && !totalPrice && (
//         <Text className="text-lg font-semibold">
//           {data?.discount ? (
//             <del className="text-black-600">
//               {data.currency ? getCurrencyCharacter(data.currency || "USD") : "USD"}{" "}
//               {data?.itemTotalPrice.price.recommendedRetailPrice}-
//             </del>
//           ) : (
//             ""
//           )}
//           {data.currency ? getCurrencyCharacter(data.currency || "USD") : "USD"}{" "}
//           {data.itemTotalPrice.price.recommendedRetailPrice - (data?.discount || 0)}
//         </Text>
//       )}
//       {!noDescription && (
//         <Text className="text-[14px] leading-[20px] font-normal mt-2">
//           {data?.ref?.description}
//         </Text>
//       )}
//       <div className="flex space-x-2 mt-2">
//         <Text className="text-[14px] leading-[20px] font-normal px-2 py-1 border-solid border border-black rounded mr-1">
//           {data?.travelDate?.split("T")[0] || data?.travelDate}
//         </Text>
//         {data?.startTime ? (
//           <Text className="text-[14px] leading-[20px] font-normal px-2 py-1 border-solid border border-black rounded mr-1">
//             {`${convertToAMPM(data?.startTime)}`}
//           </Text>
//         ) : (
//           <> </>
//         )}
//         <Text className="text-[14px] leading-[20px] font-normal px-2 py-1 border-solid border border-black rounded">
//           {`${data?.totalPax} ${data?.totalPax > 1 ? "guests" : "guest"}`}
//         </Text>
//       </div>
//       <hr className="border-t-2  border-[#BAB9B9] w-[488px]" />
//     </>
//   );
// };
// TicketDetails.propTypes = {
//   data: PropTypes.shape({
//     ref: PropTypes.shape({
//       title: PropTypes.string,
//       rating: PropTypes.number,
//       reviews: PropTypes.shape({
//         combinedAverageRating: PropTypes.number,
//         description: PropTypes.string,
//       }),
//       coverImage: PropTypes.oneOfType([
//         PropTypes.shape({
//           imageSource: PropTypes.string,
//           caption: PropTypes.string,
//           isCover: PropTypes.bool,
//           variants: PropTypes.arrayOf(
//             PropTypes.shape({
//               url: PropTypes.string,
//               width: PropTypes.number,
//               height: PropTypes.number,
//             })
//           ),
//         }),
//         PropTypes.string,
//       ]),
//     }),
//     discount: PropTypes.number,
//     itemTotalPrice: PropTypes.shape({
//       price: PropTypes.shape({
//         recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//       }),
//     }),
//     totalPax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     travelDate: PropTypes.string,
//     startTime: PropTypes.string,
//   }).isRequired,
//   isProvider: PropTypes.bool.isRequired,
//   totalPrice: PropTypes.bool.isRequired,
//   noDescription: PropTypes.bool.isRequired,
// };

// const GuestInfo = ({ user }) => (
//   <div>
//     <Text className="text-[22px] leading-[30.05px] font-bold mb-2">Main Guest Information</Text>
//     <Text className="mb-4 text-[14px] leading-[21.46px] font-normal">Name: {user.name}</Text>
//     <Text className="mb-4 text-[14px] leading-[21.46px] font-normal">Email: {user.email}</Text>
//     <Text className="mb-4 text-[14px] leading-[21.46px] font-normal">
//       Phone Number: {user.phone}
//     </Text>
//     <hr className="border-t-2  border-[#BAB9B9] w-[488px]" />
//   </div>
// );

// GuestInfo.propTypes = {
//   user: PropTypes.shape({
//     name: PropTypes.string,
//     email: PropTypes.string,
//     phone: PropTypes.string,
//   }).isRequired,
// };

// const ProviderPrice = ({ price, currency, discount, isDiscount }) => (
//   <div className="flex justify-between mt-4 mb-4">
//     <Text className="text-[22px] leading-[30.05px] font-bold">
//       {isDiscount ? "Total" : "Price"}
//     </Text>
//     <Text className="text-[22px] leading-[30.05px] font-bold">
//       {getCurrencyCharacter(currency || "USD")}{" "}
//       {(price.recommendedRetailPrice - discount).toFixed(2)}
//     </Text>
//   </div>
// );

// ProviderPrice.propTypes = {
//   isDiscount: PropTypes.bool.isRequired,
//   price: PropTypes.shape({
//     recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   }).isRequired,
//   discount: PropTypes.number.isRequired,
// };

// const MoreInfo = ({ data, isProvider }) => (
//   <>
//     {isProvider && <hr className="border-t-2  border-[#BAB9B9] w-[488px]" />}
//     <Text className="text-[22px] leading-[30.05px] font-bold mb-2">What's included</Text>
//     {data?.inclusions.map((inclusion) => (
//       <Text className="text-[14px] leading-[21.46px] font-normal mb-4 w-[488px]">
//         {inclusion.included ||
//           inclusion?.otherDescription ||
//           inclusion?.description ||
//           inclusion?.typeDescription}
//       </Text>
//     ))}
//     {/* <hr className="border-t-2  border-[#BAB9B9] w-[488px]" /> */}
//     {/* <Text className="text-[22px] leading-[30.05px] font-bold mb-2">Know before you go</Text>
//     {data?.additionalInfo?.map((info) => (
//       <Text className="text-[14px] leading-[19.32px] underline font-normal w-[488px] mb-2">
//         {info || ""}
//       </Text>
//     ))} */}
//     {/* <Text className="text-[14px] leading-[19.32px] underline font-normal w-[488px]">
//       {data?.whatToExpect} */}
//     {/* </Text> */}
//     <hr className="border-t-2  border-[#BAB9B9] w-[488px]" />
//     <Text className="text-[14px] leading-[21.46px] font-normal mb-0">Address</Text>
//     <Text className="text-[14px] leading-[19.32px] underline font-normal w-[488px] mt-0">
//       {data?.logistics?.start?.[0]?.pin?.id?.geoLocation?.address || ""}
//     </Text>
//   </>
// );

// MoreInfo.defaultProps = {
//   isProvider: false,
// };
// MoreInfo.propTypes = {
//   data: PropTypes.shape({
//     inclusions: PropTypes.arrayOf(
//       PropTypes.shape({
//         typeDescription: PropTypes.string,
//         otherDescription: PropTypes.string,
//         description: PropTypes.string,
//       })
//     ),
//     logistics: PropTypes.shape({
//       start: PropTypes.arrayOf(
//         PropTypes.shape({
//           pin: PropTypes.shape({
//             id: PropTypes.shape({ geoLocation: PropTypes.shape({ address: PropTypes.string }) }),
//           }),
//         })
//       ),
//     }),
//     // additionalInfo: PropTypes.arrayOf(PropTypes.string),
//   }).isRequired,
//   isProvider: PropTypes.bool,
// };

// const ActionButton = ({ Abandonment, isCancel, token, noAction }) =>
//   !noAction && (
//     <Button
//       className="bg-[#003005] text-white text-[16px] leading-[16px] font-semibold py-2 px-4 rounded-md mx-auto"
//       href={token}
//     >
//       {Abandonment
//         ? "Complete Checkout"
//         : isCancel
//         ? "Cancel your booking"
//         : "Manage your bookings"}
//     </Button>
//   );

// ActionButton.propTypes = {
//   Abandonment: PropTypes.bool.isRequired,
//   noAction: PropTypes.bool.isRequired,
//   isCancel: PropTypes.bool.isRequired,
//   token: PropTypes.string.isRequired,
// };

// const DynamicTicketCard = ({
//   isMoreInfo,
//   noDescription,
//   Abandonment,
//   isCancel,
//   isGuest,
//   isProvider,
//   totalPrice,
//   isReminder,
//   data,
//   user,
//   noAction,
//   isDiscount,
// }) => {
//   // Normalize data to be always an array
//   const tickets = Array.isArray(data.items) ? data.items : [data];
//   return (
//     <Tailwind>
//       <div className="px-8">
//         {tickets.map((ticket, index) => (
//           <Section
//             key={ticket?.ref?.id || `${ticket?.ref?.title}-${index}`}
//             className="text-left bg-[#F2F2F2] text-black p-6 rounded-lg"
//           >
//             <TicketDetails
//               data={ticket}
//               isProvider={isProvider}
//               totalPrice={totalPrice}
//               noDescription={noDescription}
//             />
//             {isGuest && <GuestInfo user={user} />}
//             {isDiscount && (
//               <DiscountedPrice
//                 price={ticket.itemTotalPrice.price}
//                 currency={ticket.currency}
//                 discount={ticket.discount || 0}
//               />
//             )}
//             {(isProvider || totalPrice) && (
//               <ProviderPrice
//                 price={ticket.itemTotalPrice.price}
//                 currency={ticket.currency}
//                 isDiscount={isDiscount}
//                 discount={ticket.discount || 0}
//               />
//             )}
//             {isMoreInfo && <MoreInfo data={ticket?.ref} isProvider={isProvider} />}
//           </Section>
//         ))}
//         <Section className="text-left bg-[#F2F2F2] text-black p-6 rounded-lg">
//           {((isProvider && isReminder) || !isProvider) && (
//             <ActionButton
//               Abandonment={Abandonment}
//               noAction={noAction}
//               isCancel={isCancel}
//               token={
//                 isReminder
//                   ? "https://lokalee.app/bookings"
//                   : `https://lokalee.app/tours/${tickets[0]?.ref?.slug}`
//               }
//             />
//           )}
//         </Section>
//       </div>
//     </Tailwind>
//   );
// };

// DynamicTicketCard.defaultProps = {
//   isMoreInfo: false,
//   isDiscount: false,
//   Abandonment: false,
//   noAction: false,
//   noDescription: false,
//   isCancel: false,
//   isGuest: false,
//   isProvider: false,
//   totalPrice: false,
//   isReminder: false,
//   data: [],
//   user: {},
// };

// DynamicTicketCard.propTypes = {
//   isMoreInfo: PropTypes.bool,
//   isDiscount: PropTypes.bool,
//   Abandonment: PropTypes.bool,
//   noDescription: PropTypes.bool,
//   noAction: PropTypes.bool,
//   isCancel: PropTypes.bool,
//   isGuest: PropTypes.bool,
//   isProvider: PropTypes.bool,
//   isReminder: PropTypes.bool,
//   totalPrice: PropTypes.bool,
//   data: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(Array)]),
//   user: PropTypes.instanceOf(Object),
// };

// export default DynamicTicketCard;
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Section, Text, Button, Img } from "@react-email/components";

const findBestVariant = (variants, width) => {
  const sortedVariants = variants.sort((a, b) => a.width - b.width);
  return (
    sortedVariants.find((variant) => variant.width >= width) ||
    sortedVariants.find((variant) => variant.width >= 500) ||
    sortedVariants[sortedVariants.length - 1]
  );
};

const getCurrencyCharacter = (currency) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "AED":
      return "AED";
    case "SAR":
      return "SR";
    case "GBP":
      return "£";
    case "CAD":
      return "C$";
    case "AUD":
      return "A$";
    case "EGP":
      return "E£";
    case "TRY":
      return "₺";
    default:
      return "$";
  }
};

const convertToAMPM = (time24) => {
  const timeSplit = time24.split(":");
  let hours = parseInt(timeSplit[0], 10);
  const minutes = timeSplit[1];
  const period = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  return `${hours}:${minutes} ${period}`;
};

const DiscountedPrice = ({ price, currency, discount }) => (
  <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Text style={{ fontSize: "14px", lineHeight: "14px", marginBottom: "0.25rem" }}>
        Subtotal:
      </Text>
      <Text style={{ fontSize: "14px", lineHeight: "14px", marginBottom: "0.25rem" }}>
        {getCurrencyCharacter(currency || "USD")}
        {price.recommendedRetailPrice}
      </Text>
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Text style={{ fontSize: "14px", lineHeight: "14px", marginTop: "0.25rem" }}>Discount:</Text>
      <Text style={{ fontSize: "14px", lineHeight: "14px", marginTop: "0.25rem" }}>
        {getCurrencyCharacter(currency || "USD")}
        {discount}
      </Text>
    </div>
    <hr style={{ borderTop: "2px solid #BAB9B9", width: "488px", marginTop: "1rem" }} />
  </div>
);

DiscountedPrice.propTypes = {
  price: PropTypes.shape({
    recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  discount: PropTypes.number.isRequired,
};

const TicketDetails = ({ data, isProvider, noDescription, totalPrice }) => {
  const coverImage =
    data.ref.coverImage?.variants?.length > 0
      ? findBestVariant(data?.ref.coverImage?.variants, 500).url
      : `${data.ref.coverImage}`;

  return (
    <>
      <Text
        style={{
          fontSize: "14.43px",
          lineHeight: "49.05px",
          fontWeight: "bold",
          marginTop: "0.25rem",
          marginBottom: "0.5rem",
        }}
      >
        Details of your booking
      </Text>
      <Img
        src={coverImage}
        alt="Booking Image"
        style={{ width: "100%", height: "auto", borderRadius: "8px" }}
      />
      <Text
        style={{
          fontSize: "22px",
          lineHeight: "23.55px",
          fontWeight: "normal",
          marginTop: "0.5rem",
          color: "#222D29",
        }}
      >
        {data?.ref?.title}
      </Text>
      {!isProvider && !totalPrice && (
        <Text style={{ fontSize: "18px", fontWeight: "600" }}>
          {data?.discount ? (
            <del style={{ color: "#666" }}>
              {getCurrencyCharacter(data.currency || "USD")}{" "}
              {data?.itemTotalPrice.price.recommendedRetailPrice}-
            </del>
          ) : (
            ""
          )}
          {getCurrencyCharacter(data.currency || "USD")}{" "}
          {data.itemTotalPrice.price.recommendedRetailPrice - (data?.discount || 0)}
        </Text>
      )}
      {!noDescription && (
        <Text
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "normal",
            marginTop: "0.5rem",
          }}
        >
          {data?.ref?.description}
        </Text>
      )}
      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <Text
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            padding: "0.5rem",
            border: "1px solid black",
            borderRadius: "4px",
            marginRight: "0.5rem",
          }}
        >
          {data?.travelDate?.split("T")[0] || data?.travelDate}
        </Text>
        {data?.startTime && (
          <Text
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              padding: "0.5rem",
              border: "1px solid black",
              borderRadius: "4px",
              marginRight: "0.5rem",
            }}
          >
            {convertToAMPM(data?.startTime)}
          </Text>
        )}
        <Text
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            padding: "0.5rem",
            border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          {`${data?.totalPax} ${data?.totalPax > 1 ? "guests" : "guest"}`}
        </Text>
      </div>
      <hr style={{ borderTop: "2px solid #BAB9B9", width: "488px", marginTop: "1rem" }} />
    </>
  );
};

TicketDetails.propTypes = {
  data: PropTypes.shape({
    ref: PropTypes.shape({
      title: PropTypes.string,
      coverImage: PropTypes.oneOfType([
        PropTypes.shape({
          variants: PropTypes.arrayOf(
            PropTypes.shape({
              url: PropTypes.string,
              width: PropTypes.number,
            })
          ),
        }),
        PropTypes.string,
      ]),
      description: PropTypes.string,
    }),
    discount: PropTypes.number,
    itemTotalPrice: PropTypes.shape({
      price: PropTypes.shape({
        recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
    totalPax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    travelDate: PropTypes.string,
    startTime: PropTypes.string,
  }).isRequired,
  isProvider: PropTypes.bool.isRequired,
  totalPrice: PropTypes.bool.isRequired,
  noDescription: PropTypes.bool.isRequired,
};

const GuestInfo = ({ user }) => (
  <div>
    <Text
      style={{
        fontSize: "22px",
        lineHeight: "30.05px",
        fontWeight: "bold",
        marginBottom: "0.5rem",
      }}
    >
      Main Guest Information
    </Text>
    <Text
      style={{
        marginBottom: "1rem",
        fontSize: "14px",
        lineHeight: "21.46px",
        fontWeight: "normal",
      }}
    >
      Name: {user.name}
    </Text>
    <Text
      style={{
        marginBottom: "1rem",
        fontSize: "14px",
        lineHeight: "21.46px",
        fontWeight: "normal",
      }}
    >
      Email: {user.email}
    </Text>
    <Text
      style={{
        marginBottom: "1rem",
        fontSize: "14px",
        lineHeight: "21.46px",
        fontWeight: "normal",
      }}
    >
      Phone Number: {user.phone}
    </Text>
    <hr style={{ borderTop: "2px solid #BAB9B9", width: "488px", marginTop: "1rem" }} />
  </div>
);

GuestInfo.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

const ProviderPrice = ({ price, currency, discount, isDiscount }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem",
      marginBottom: "1rem",
    }}
  >
    <Text style={{ fontSize: "22px", lineHeight: "30.05px", fontWeight: "bold" }}>
      {isDiscount ? "Total" : "Price"}
    </Text>
    <Text style={{ fontSize: "22px", lineHeight: "30.05px", fontWeight: "bold" }}>
      {getCurrencyCharacter(currency || "USD")}{" "}
      {(price.recommendedRetailPrice - discount).toFixed(2)}
    </Text>
  </div>
);

ProviderPrice.propTypes = {
  isDiscount: PropTypes.bool.isRequired,
  price: PropTypes.shape({
    recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  discount: PropTypes.number.isRequired,
};

const MoreInfo = ({ data, isProvider = false }) => (
  <>
    {isProvider && (
      <hr style={{ borderTop: "2px solid #BAB9B9", width: "488px", marginTop: "1rem" }} />
    )}
    <Text
      style={{
        fontSize: "22px",
        lineHeight: "30.05px",
        fontWeight: "bold",
        marginBottom: "0.5rem",
      }}
    >
      What's included
    </Text>
    {data?.inclusions.map((inclusion) => (
      <Text
        style={{
          fontSize: "14px",
          lineHeight: "21.46px",
          fontWeight: "normal",
          marginBottom: "1rem",
          width: "488px",
        }}
      >
        {inclusion.included ||
          inclusion?.otherDescription ||
          inclusion?.description ||
          inclusion?.typeDescription}
      </Text>
    ))}
    <hr style={{ borderTop: "2px solid #BAB9B9", width: "488px", marginTop: "1rem" }} />
  </>
);

MoreInfo.propTypes = {
  data: PropTypes.shape({
    inclusions: PropTypes.arrayOf(
      PropTypes.shape({
        typeDescription: PropTypes.string,
        otherDescription: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    logistics: PropTypes.shape({
      start: PropTypes.arrayOf(
        PropTypes.shape({
          pin: PropTypes.shape({
            id: PropTypes.shape({ geoLocation: PropTypes.shape({ address: PropTypes.string }) }),
          }),
        })
      ),
    }),
  }).isRequired,
  isProvider: PropTypes.bool,
};

const ActionButton = ({ Abandonment, isCancel, token, noAction }) =>
  !noAction && (
    <Button
      style={{
        backgroundColor: "#003005",
        color: "#fff",
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: "600",
        padding: "0.5rem 1rem",
        borderRadius: "8px",
        display: "block",
        margin: "1rem auto",
        textDecoration: "none",
      }}
      href={token}
    >
      {Abandonment
        ? "Complete Checkout"
        : isCancel
        ? "Cancel your booking"
        : "Manage your bookings"}
    </Button>
  );

ActionButton.propTypes = {
  Abandonment: PropTypes.bool.isRequired,
  noAction: PropTypes.bool.isRequired,
  isCancel: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

const DynamicTicketCard = ({
  isMoreInfo = false,
  noDescription = false,
  Abandonment = false,
  isCancel = false,
  isGuest = false,
  isProvider = false,
  totalPrice = false,
  isReminder = false,
  data = [],
  user = {},
  noAction = false,
  isDiscount = false,
}) => {
  const tickets = Array.isArray(data.items) ? data.items : [data];

  return (
    <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
      {tickets.map((ticket, index) => (
        <Section
          key={ticket?.ref?.id || `${ticket?.ref?.title}-${index}`}
          style={{
            textAlign: "left",
            backgroundColor: "#F2F2F2",
            color: "#000",
            padding: "1.5rem",
            borderRadius: "8px",
            marginBottom: "1rem",
          }}
        >
          <TicketDetails
            data={ticket}
            isProvider={isProvider}
            totalPrice={totalPrice}
            noDescription={noDescription}
          />
          {isGuest && <GuestInfo user={user} />}
          {isDiscount && (
            <DiscountedPrice
              price={ticket.itemTotalPrice.price}
              currency={ticket.currency}
              discount={ticket.discount || 0}
            />
          )}
          {(isProvider || totalPrice) && (
            <ProviderPrice
              price={ticket.itemTotalPrice.price}
              currency={ticket.currency}
              isDiscount={isDiscount}
              discount={ticket.discount || 0}
            />
          )}
          {isMoreInfo && <MoreInfo data={ticket?.ref} isProvider={isProvider} />}

          {/* Moving the Action Button inside the same section */}
          {((isProvider && isReminder) || !isProvider) && (
            <ActionButton
              Abandonment={Abandonment}
              noAction={noAction}
              isCancel={isCancel}
              token={
                isReminder
                  ? "https://lokalee.app/bookings"
                  : `https://lokalee.app/tours/${ticket?.ref?.slug}`
              }
            />
          )}
        </Section>
      ))}
    </div>
  );
};

DynamicTicketCard.propTypes = {
  isMoreInfo: PropTypes.bool,
  isDiscount: PropTypes.bool,
  Abandonment: PropTypes.bool,
  noDescription: PropTypes.bool,
  noAction: PropTypes.bool,
  isCancel: PropTypes.bool,
  isGuest: PropTypes.bool,
  isProvider: PropTypes.bool,
  isReminder: PropTypes.bool,
  totalPrice: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(Array)]),
  user: PropTypes.instanceOf(Object),
};

export default DynamicTicketCard;
