/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import React, { createRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Tabs, Tab, Switch, Typography, Card, CardContent } from "@mui/material";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import CopytoClipboard from "components/CopytoClipboard";
import ViewEditActions from "components/DetailList/ViewEditActions";
import FormField from "components/FormField";
import MDButton from "components/MDButtonLoading";
import ServiceJobsCompanyPicker from "components/AutoComplete/ٍServiceJobs/ٍServiceJobsCompanyPicker";
import ServiceJobsSystemPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsSystemPicker";
import ServiceJobsVersionPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsVersionPicker";
import ServiceJobsCategoriesPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsCategoriesPicker";
import DetailList from "components/DetailList";
import Danger from "components/Danger";
import { addJobs, setLoading } from "store/actions/locationAction";
import { getUpdatedFilter } from "store/actions/userActions";
import { Formik, Form } from "formik";
import validationsCompanion from "../schemas/validationsCompanion";
import initialValuesCompanion from "../schemas/initialValuesCompanion";
import formCompanion from "../schemas/formCompanion";

const webUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://lokalee.app"
    : "https://staging.lokalee.app";

const GuestCompanion = ({ location }) => {
  /* *** Selectors *** */
  const reduxFilters = useSelector((state) => state.users.filters);

  /* *** Constants *** */
  const dispatch = useDispatch();
  const { apikey, url, portalId, diningId } = formCompanion.formField;

  /* *** Refs *** */
  const formRef = useRef();
  const tableRef = createRef();

  /* *** State *** */
  const [jobError, setJobError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [detailPage, setDetailPage] = useState(true);
  const [showInDining, setShowInDining] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesCompanion);

  useEffect(() => {
    dispatch(
      getUpdatedFilter({
        filters: reduxFilters?.filters,
        row_nb: reduxFilters?.row_nb,
        pageValue: reduxFilters?.pageValue,
        search: "",
      })
    );
  }, []);
  useEffect(() => {
    if (location) {
      const manipulated = {};
      manipulated.company = location.guestRequestType || "";
      manipulated.propertyCode = location.guestRequestPropertyId || "";
      manipulated.inRoomDiningPropertyId = location.inRoomDiningPropertyId || "";
      manipulated.inRoomDining = location.inRoomDining || false;
      manipulated.serviceJobs = location.serviceJobs || [];
      manipulated.system = location.guestRequestType === "sky" ? "guestapp" : "servicerequest";
      manipulated.version = location.guestRequestType === "sky" ? "v1" : "v0.1";
      manipulated.title = "";
      manipulated.code = "";
      manipulated.serviceJobsObj = null;
      manipulated.showGuestCompanion = location.showGuestCompanion || false;
      manipulated.url = location?.skyUrl || "";
      manipulated.apikey = location?.skyApikey || "";
      manipulated.portalId = location?.skyPortalId || "";
      manipulated.diningId = location?.skyDiningId || "";
      manipulated.diningCompany = location.inRoomDiningPropertyId ? "digi" : "sky";

      setShowInDining(location.inRoomDining);
      setInitialValues(manipulated);
    }
  }, [location]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm1 = async (values) => {
    await sleep(1000);
    dispatch(setLoading());
    dispatch(addJobs(location?._id, values, selectedTab));
    setDetailPage(true);
  };

  const handleAddJob = (values, setFieldValue) => {
    if (!values.title || !values.code || !values.serviceJobsObj) {
      setJobError(true);
      return;
    }
    setJobError(false);
    const jobs = values.serviceJobs || [];
    const newJob = {
      title: values.title,
      code: values.code,
      category: values.serviceJobsObj?.category?.toLowerCase(),
      externalCategoryId: values.serviceJobsObj?.categoryId,
      subcategory: values.serviceJobsObj?.subcategory,
    };
    jobs.push(newJob);
    setFieldValue("serviceJobs", jobs);
    setFieldValue("title", "");
    setFieldValue("code", "");
    setFieldValue("serviceJobsObj", null);
  };
  const handleDeleteJob = (_id, values, setFieldValue) => {
    const jobs = values.serviceJobs || [];
    const updatedJobs = jobs.filter((element) => element._id !== _id);
    setFieldValue("serviceJobs", updatedJobs);
  };

  return (
    <MDBox>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12} lg={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationsCompanion[0]}
            onSubmit={(values, actions) => submitForm1(values, actions)}
            innerRef={(f) => {
              formRef.current = f;
            }}
          >
            {({ values, errors, touched, setFieldValue, resetForm, submitForm }) => (
              <Form autoComplete="off">
                <Card>
                  <CardContent>
                    <MDBox>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid item />
                        <Grid item>
                          <ViewEditActions
                            detailPage={detailPage}
                            stateName="locations"
                            handleEdit={() => {
                              setDetailPage(false);
                            }}
                            handleClose={() => {
                              setDetailPage(true);
                              resetForm();
                              setFieldValue("serviceJobs", location.serviceJobs);
                            }}
                            handleSave={(event) => {
                              event.preventDefault();
                              submitForm();
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Tabs
                        value={selectedTab}
                        aria-label="guest companion tabs"
                        onChange={(_, newValue) => setSelectedTab(newValue)}
                      >
                        <Tab label="Guest Request Management" />
                        <Tab label="In-Room Dining" />
                      </Tabs>

                      <Divider sx={{ marginY: 2 }} />

                      {detailPage && selectedTab === 0 && (
                        <DetailList
                          data={{
                            company: values?.company,
                            propertyCode: values?.propertyCode,
                            apiKey: values?.apikey,
                            url: values?.url,
                            portalId: values?.portalId,
                            diningId: values?.diningId,
                            showGuestCompanion: values?.showGuestCompanion,
                          }}
                        />
                      )}
                      {detailPage && selectedTab === 1 && (
                        <DetailList
                          data={{
                            company: values?.inRoomDining
                              ? values?.diningCompany === "digi"
                                ? "Digi Menu"
                                : "Sky Software"
                              : "",
                            propertyCode: values?.inRoomDiningPropertyId,
                          }}
                        />
                      )}

                      {!detailPage && selectedTab === 0 && (
                        <>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: "inline", mr: 2 }}
                          >
                            Guest Companion
                          </Typography>
                          <Switch
                            checked={values?.showGuestCompanion}
                            onChange={() =>
                              setFieldValue("showGuestCompanion", !values?.showGuestCompanion)
                            }
                            color="primary"
                          />

                          {values?.showGuestCompanion && (
                            <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                              <Grid item xs={12} mb={4}>
                                <CopytoClipboard
                                  text={`${webUrl}/locations/${location?.slug}/companion`}
                                />
                              </Grid>
                              <Typography style={{ fontSize: "0.875rem", fontWeight: 400 }}>
                                Choose your vendor:
                              </Typography>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                  <ServiceJobsCompanyPicker
                                    companyV={values?.company}
                                    setFieldValue={setFieldValue}
                                    companyOptions={[
                                      { label: "Sky Software", value: "sky" },
                                      { label: "Message Box", value: "messagebox" },
                                    ]}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <ServiceJobsSystemPicker
                                    company={values.company}
                                    systemV={values?.system}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <ServiceJobsVersionPicker
                                    company={values.company}
                                    system={values.system}
                                    versionV={values?.version}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormField
                                    type="text"
                                    label="Property Code"
                                    name="propertyCode"
                                    value={values?.propertyCode}
                                    placeholder="Property Code"
                                    error={errors?.propertyCode && touched?.propertyCode}
                                    success={
                                      values?.propertyCode?.length > 0 && !errors?.propertyCode
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                  <FormField
                                    type={apikey.type}
                                    name={apikey.name}
                                    label={apikey.label}
                                    value={values?.apikey}
                                    placeholder={apikey.label}
                                  />
                                </Grid>

                                <Grid item xs>
                                  <FormField
                                    name={url.name}
                                    type={url.type}
                                    label={url.label}
                                    value={values?.url}
                                    placeholder={url.label}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                  <FormField
                                    type={portalId.type}
                                    name={portalId.name}
                                    label={portalId.label}
                                    value={values?.portalId}
                                    placeholder={portalId.label}
                                  />
                                </Grid>

                                <Grid item xs>
                                  <FormField
                                    type={diningId.type}
                                    name={diningId.name}
                                    label={diningId.label}
                                    value={values?.diningId}
                                    placeholder={diningId.label}
                                  />
                                </Grid>
                              </Grid>

                              <div
                                style={{
                                  height: "2px",
                                  width: "100%",
                                  borderRadius: "1px",
                                  backgroundColor: "#f0f0f0",
                                  margin: "30px 10% 10px 10%",
                                }}
                              />

                              <Typography
                                style={{ fontSize: "0.875rem", fontWeight: 400, marginTop: 16 }}
                              >
                                Add Jobs
                              </Typography>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                  <ServiceJobsCategoriesPicker
                                    setFieldValue={setFieldValue}
                                    serviceJobsObjV={values.serviceJobsObj}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormField
                                    type="text"
                                    label="Job Name"
                                    name="title"
                                    value={values?.title}
                                    placeholder="Name"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormField
                                    type="number"
                                    label="Job Code"
                                    name="code"
                                    value={values?.code}
                                    placeholder="Code"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MDButton
                                    onClick={() => handleAddJob(values, setFieldValue)}
                                    variant="gradient"
                                    color="dark"
                                  >
                                    Add Job
                                  </MDButton>
                                </Grid>
                              </Grid>

                              <MDBox mt={4} ml={4}>
                                <Grid item container direction="column" spacing={2}>
                                  <Grid item mb={2}>
                                    {jobError && (
                                      <Danger validation="Category, job name, and code fields are required!" />
                                    )}
                                  </Grid>

                                  <MaterialTablePicker
                                    editableRole
                                    deleteRoleAction
                                    tableRef={tableRef}
                                    isExportable={false}
                                    isRowClickable={false}
                                    title="Guest Companion"
                                    screen="GuestCompanion"
                                    filteringOptions={false}
                                    data={values.serviceJobs}
                                    key={values.serviceJobs.length}
                                    editableDelete={(oldData) =>
                                      handleDeleteJob(oldData, values, setFieldValue)
                                    }
                                    columns={[
                                      "Job Category",
                                      "Job Subcategory",
                                      "Job Name",
                                      "Job Code",
                                    ]}
                                  />
                                </Grid>
                              </MDBox>
                            </Grid>
                          )}
                        </>
                      )}
                      {!detailPage && selectedTab === 1 && (
                        <>
                          <Grid item>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{ display: "inline", mr: 2 }}
                            >
                              In Room Dining
                            </Typography>

                            <Switch
                              color="primary"
                              checked={showInDining}
                              onChange={() => {
                                setShowInDining(!showInDining);
                                setFieldValue("inRoomDining", !values?.inRoomDining);
                              }}
                            />
                          </Grid>

                          {showInDining && (
                            <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                              <Grid item xs={3}>
                                <ServiceJobsCompanyPicker
                                  isDiningTab
                                  setFieldValue={setFieldValue}
                                  companyV={values?.diningCompany}
                                  companyOptions={[
                                    { label: "Digi Menu", value: "digi" },
                                    { label: "Sky Software", value: "sky" },
                                  ]}
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <ServiceJobsSystemPicker
                                  setFieldValue={setFieldValue}
                                  company={values.diningCompany}
                                  systemV={values?.diningSystem}
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <ServiceJobsVersionPicker
                                  setFieldValue={setFieldValue}
                                  company={values.diningCompany}
                                  versionV={values?.diningVersion}
                                />
                              </Grid>

                              {values?.diningCompany === "digi" && (
                                <Grid item xs={3}>
                                  <FormField
                                    type="text"
                                    label="Property Code"
                                    placeholder="Property Code"
                                    name="inRoomDiningPropertyId"
                                    value={values?.inRoomDiningPropertyId}
                                    error={
                                      errors?.inRoomDiningPropertyId &&
                                      touched?.inRoomDiningPropertyId
                                    }
                                    success={
                                      values?.inRoomDiningPropertyId?.length > 0 &&
                                      !errors?.inRoomDiningPropertyId
                                    }
                                  />
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                    </MDBox>
                  </CardContent>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

GuestCompanion.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default GuestCompanion;
