/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData, editPage = false }) => {
  const { values } = formData;
  const [VoucherInfoData, setVoucherInfoData] = useState({});
  const [additionalInfoData, setAdditionalInfoData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setVoucherInfoData({
        title: values?.title,
        description: values?.description,
        recommendation: values?.recommendation?.title
          ? values?.recommendation?.title
          : values?.recommendation?.label,
        location: values?.location,
      });

      if (values?.dayRestriction) {
        setAdditionalInfoData({
          //   dayRestriction: values?.dayRestriction,
          openEnded: values?.openEnded,
          limit: values?.limit,
          type: values?.type,
          value: values?.value,
          maxUsage: values?.maxUsage,
          quantityPerDay:
            values.limit === true && values?.dayRestriction === "per_day" && values?.quantityPerDay,
          quantity:
            values.limit === true &&
            values?.dayRestriction === "no_day_restriction" &&
            values?.quantity,
          startDate: values?.startDate,
          endDate: values?.endDate,
        });
      } else {
        setAdditionalInfoData({
          //   dayRestriction: values?.dayRestriction,
          openEnded: values?.openEnded,
          limit: values?.limit,
          type: values?.type,
          value: values?.value,
          maxUsage: values?.maxUsage,
          quantity: values?.quantity,
          startDate: values?.startDate,
          endDate: values?.endDate,
        });
      }
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Information", data: VoucherInfoData },
      { id: 1, title: "Additional Info", data: additionalInfoData },
    ]);
  }, [VoucherInfoData, additionalInfoData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item size={{ xs: 12, sm: 6 }}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
