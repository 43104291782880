/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import { Typography } from "@mui/material";
import { capitalizeFirstLetter } from "components/helper";

const StatusPicker = ({
  handleTypeChange = () => {},
  setStatus = () => {},
  statusV = "",
  setFieldValue,
}) => {
  // Transform status options into the required format
  const statusOptions = [
    { label: "Purchase", value: "purchase" },
    { label: "Refund", value: "refund" },
  ];

  return (
    <div>
      <Typography style={{ fontSize: "0.875rem", fontWeight: 400 }}>Status</Typography>
      <AsyncSelect
        options={statusOptions} // Use static options in the correct format
        onChange={(selectedOption) => {
          if (selectedOption) {
            setFieldValue("type", selectedOption.value.toLowerCase()); // Set the selected value
            setStatus(selectedOption.label.toLowerCase()); // Set the selected label
            handleTypeChange(selectedOption.label.toLowerCase()); // Handle any change event
          } else {
            setFieldValue("type", "");
            setStatus("");
          }
        }}
        value={
          statusV ? { label: capitalizeFirstLetter(statusV), value: statusV.toLowerCase() } : null
        }
        placeholder="Select a status"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem", // Customize the selected option text size
          }),
        }}
      />
    </div>
  );
};

// Prop-types for StatusPicker
StatusPicker.propTypes = {
  statusV: PropTypes.string,
  setStatus: PropTypes.func,
  handleTypeChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
};

export default StatusPicker;
