/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
import React from "react";
import PropTypes from "prop-types";
import { DropzoneArea } from "react-mui-dropzone";

const DynamicMultiPdf = ({ setFieldValue, files = [] }) => {
  const handleChange = (e) => {
    setFieldValue("files", e);
  };

  return (
    <div>
      <DropzoneArea
        onChange={handleChange}
        filesLimit={6}
        initialFiles={files}
        acceptedFiles={[".pdf"]}
      />
    </div>
  );
};

DynamicMultiPdf.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.string),
};

export default DynamicMultiPdf;
