/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import FormField from "components/FormField";
import MDTypography from "components/MDTypography";
import titleList from "./TitleList";

const TitlePicker = ({
  titlePkr = {},
  handleTitleChange = () => {},
  title = {},
  setFieldValue = () => {},
  isExclusion = false,
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  return (
    <>
      {" "}
      <MDTypography variant="regular" style={{ marginRight: "0.5rem", fontSize: "16px" }}>
        {titlePkr?.name === "titlePkr" ? "Inclusions" : "Exclusions"}
      </MDTypography>{" "}
      <SelectF
        options={titleList}
        placeholder="Select an inclusion"
        value={title}
        onChange={(event) => {
          if (event) {
            if (isExclusion) {
              setFieldValue("titlePkrExc", event);
            } else {
              setFieldValue("titlePkr", event);
            }
            handleTitleChange(event);
          } else {
            if (isExclusion) {
              setFieldValue("titlePkrExc", "");
            } else {
              setFieldValue("titlePkr", "");
            }
            handleTitleChange(event);
          }
        }}
        //   getOptionValue={(option) => console.log("opp", option)}
        //   getOptionLabel={(option) => `${option.label}`}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
        isSearchable
        menuPortalTarget={document.body}
        renderInput={(params) => (
          <FormField
            {...params}
            //   {...rest}
            label={titlePkr.label}
            name={titlePkr?.name}
            onChange={() => null}
          />
        )}
      />
    </>
  );
};

// typechecking props for TitlePicker
TitlePicker.propTypes = {
  handleTitleChange: PropTypes.func,
  isExclusion: PropTypes.bool,
  title: PropTypes.instanceOf(Object),
  titlePkr: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
};

export default TitlePicker;
