import React from "react";
import PropTypes from "prop-types";
import { Grid2, Typography, Checkbox, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LanguagesPicker from "components/AutoComplete/LanguagesPicker";
import MDBox from "components/MDBox";
import LanguagesHeader from "./LanguagesHeader";
import { languagesList } from "../constants";

const LanguagesSection = ({ values, errors, languages = {}, setFieldValue, languagesV = [] }) => {
  const selectedLanguages = values.languages || [];
  const languageGuides = values.languageGuides || [];

  const handleCheckboxChange = (language, type) => {
    const updatedLanguages = languageGuides
      .map((item) => {
        // Check if the current language already exists with the type
        if (item.language === language) {
          // If the type is the same, remove it (toggle off)
          if (item.type === type) return null;
          // If the type is different, update the type
          return { ...item, type };
        }
        // Keep the other languages untouched
        return item;
      })
      .filter(Boolean); // Remove null items (toggled off)

    // Add a new language if it doesn't exist
    if (!languageGuides.some((item) => item.language === language)) {
      updatedLanguages.push({ language, type });
    }

    setFieldValue("languageGuides", updatedLanguages);
  };

  const handleRemoveLanguage = (language) => {
    // Transform language into the desired format
    const transformedLanguage = {
      type: language.type,
      language: language.code,
    };

    // Filter out the matching language object from the array
    const updatedLanguages = languageGuides.filter(
      (lang) =>
        !(lang.type === transformedLanguage.type && lang.language === transformedLanguage.language)
    );

    const updatedLanguages2 = selectedLanguages.filter((lang) => lang !== language);

    setFieldValue("languages", updatedLanguages2);

    // Update the Formik value
    setFieldValue("languageGuides", updatedLanguages);
    setFieldValue("languages", updatedLanguages2);
  };

  return (
    <MDBox mt={1.625}>
      <Grid2 container spacing={1}>
        <Grid2 item size={{ xs: 12 }}>
          <LanguagesPicker
            isObj
            languagesList={languagesList}
            languages={languages}
            languagesV={
              Array.isArray(languagesV) && languagesV.length > 0
                ? languagesV
                : values?.languageGuides
            }
            setFieldValue={setFieldValue}
            // errors={errors.languages}
            success={languagesV && !errors.languages}
          />
        </Grid2>

        {values?.languages && languagesV?.length > 0 && (
          <LanguagesHeader languagesV={languagesV} values={values} />
        )}

        {selectedLanguages.map((language) => (
          <Grid2 container key={language.code} size={{ xs: 12 }} spacing={0}>
            <Grid2 item size={{ xs: 3 }}>
              <Typography fontSize="medium">{language.name}</Typography>
            </Grid2>
            <Grid2 item size={{ xs: 2 }}>
              <Checkbox
                checked={languageGuides.some(
                  (item) => item.language === language.code && item.type === "GUIDE"
                )}
                onChange={() => handleCheckboxChange(language.code, "GUIDE")}
              />
            </Grid2>
            <Grid2 item size={{ xs: 2 }}>
              <Checkbox
                checked={languageGuides.some(
                  (item) => item.language === language.code && item.type === "AUDIO"
                )}
                onChange={() => handleCheckboxChange(language.code, "AUDIO")}
              />
            </Grid2>
            <Grid2 item size={{ xs: 2 }}>
              <Checkbox
                checked={languageGuides.some(
                  (item) => item.language === language.code && item.type === "WRITTEN"
                )}
                onChange={() => handleCheckboxChange(language.code, "WRITTEN")}
              />
            </Grid2>
            <Grid2 item size={{ xs: 2 }}>
              <IconButton onClick={() => handleRemoveLanguage(language)}>
                <ClearIcon />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </MDBox>
  );
};

LanguagesSection.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  languages: PropTypes.instanceOf(Object),
  languagesV: PropTypes.instanceOf(Array),
  errors: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default LanguagesSection;
