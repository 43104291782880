/* eslint-disable react/require-default-props */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "components/Tables/DataTable";

import { useDispatch, useSelector } from "react-redux";
import { getBookings } from "store/actions/statsActions";

const BookingsDataTable = ({ locationId = "" }) => {
  const dispatch = useDispatch();

  const bookings = useSelector((state) => state.stats.bookings);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    setTimeout(2000);
    if (locationId) {
      dispatch(getBookings(locationId));
    } else {
      dispatch(getBookings(""));
    }
  }, [locationId]);

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "userName", width: "20%" },
      { Header: "Email", accessor: "userEmail", width: "20%" },
      { Header: "Restaurant", accessor: "restaurantName", width: "20%" },
      // { Header: "Type", accessor: "type", width: "10%" },
      { Header: "Source", accessor: "source", width: "10%" },
      { Header: "Status", accessor: "status", width: "10%" },
      { Header: "Booked For", accessor: "bookedAt", width: "10%" },
    ],
    rows: [],
  });

  useEffect(() => {
    if (bookings?.length > 0) {
      const transformedData = bookings?.map((item) => ({
        userName: item.name,
        userEmail: item.email,
        restaurantName: item.recommendation,
        // type: item.type,
        source: item.source,
        status: item.status,
        bookedAt: typeof item.bookedAt === "string" ? new Date(item.date) : item?.date,
      }));
      setState({ ...state, rows: transformedData });
    } else {
      setState({ ...state, rows: [] });
    }
  }, [bookings]);

  return (
    <Grid container spacing={3}>
      <Grid item size={{ xs: 12 }}>
        <Card>
          <MDBox pt={3} px={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Bookings
            </MDTypography>
          </MDBox>
          <MDBox py={1}>
            <DataTable
              key={user.role}
              table={state}
              entriesPerPage
              showTotalEntries
              isSorted
              noEndBorder
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

// typechecking props for BookingsDataTable
BookingsDataTable.propTypes = {
  locationId: PropTypes.string,
};

export default BookingsDataTable;
