import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import tiersList from "./TiersList";

const TierPicker = ({ tierV = {}, setTier = () => {}, setFieldValue = () => {} }) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  return (
    <SelectF
      key={tierV?.value}
      options={tiersList}
      placeholder="Select a tier"
      value={tierV?.label ? tierV : null}
      onChange={(value) => {
        if (value) {
          setFieldValue("tier", value);
          setTier(value);
        } else {
          setTier({});
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      menuPortalTarget={document.body}
    />
  );
};

// typechecking props for BookingConfirmation Picker
TierPicker.propTypes = {
  setTier: PropTypes.func,
  setFieldValue: PropTypes.func,
  tierV: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TierPicker;
