/* eslint-disable no-underscore-dangle */
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Layout
import DashboardLayout from "layouts/DashboardLayout";
// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
// import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
// Material UI components
import Grid from "@mui/material/Grid2";
// Redux Actions
import setAlert from "store/actions/alertActions";
import {
  getLocations,
  setLoading,
  clearErrors,
  getUserPartnerLocations,
  publishUnpublishLoc,
} from "store/actions/locationAction";
import { getPdf, getCsv } from "store/actions/exportAction";
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TierPicker from "components/AutoComplete/TierPicker";
import Danger from "components/Danger";

const AllLocations = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const [rowDataLoc, setRowDataLoc] = useState({});
  const [open, setOpen] = useState(false);
  const [tier, setTier] = useState({});

  const locations = useSelector((state) => state.locations.locations);
  const error = useSelector((state) => state.locations.error);
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.locations.loading);

  const handlePublishUnPublishLoc = (val) => {
    if (val?.status === "published" || val?.status === "draft") {
      dispatch(setLoading());
      dispatch(publishUnpublishLoc(val?._id, val?.status, tier));
    } else {
      setRowDataLoc(val);
      setOpen(true);
    }
  };

  const columns = [
    "Name",
    "Partner",
    "Address",
    "City Name",
    "Number of Rooms",
    "Creation Date ",
    " Status",
    "Tier",
  ];
  useEffect(() => {
    dispatch(setLoading());
    if (user.role === "partner") {
      dispatch(getUserPartnerLocations());
    } else if (user.role === "admin") dispatch(getLocations());
  }, [user?.role]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      clearErrors();
    }
  }, [error, setAlert]);

  const handleClose = () => {
    setOpen(false);
    setTier(null);
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12 }}>
            {(user.role === "partner" || user.role === "admin") && (
              <MaterialTablePicker
                screen="LocationsList"
                title="Locations"
                searchField="locations"
                exportMenuField="locations"
                onRowClickURL={`/${user.role}/locations`}
                currentUrl="locations"
                tableRef={tableRef}
                tableInside
                columns={
                  user?.role === "partner"
                    ? columns.filter((column) => column !== "Partner")
                    : columns
                }
                data={locations}
                isLoading={loading}
                filteringOptions={false}
                addUrl="locations/new-location"
                actionsName="Location"
                handlePublishUnPublishLoc={handlePublishUnPublishLoc}
                // actionsChild={actionsChildFn}
                editableRole={user.role === "admin"}
                actionsRole={user.role === "admin"}
                getCsv={() => {
                  if (user?.role === "partner") {
                    dispatch(
                      getCsv(
                        `stats/csv?limit=${
                          locations?.length
                        }&page=1&search=&sort=name&dir=asc&partner_id=${
                          user.partner._id || user.partner
                        }&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  } else if (user?.role === "admin") {
                    dispatch(
                      getCsv(
                        `stats/csv?limit=${locations?.length}&page=1&search=&sort=name&dir=asc&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                  }
                }}
                getPdf={(value) => {
                  if (!value) {
                    // Handle the case where 'value' is undefined or empty
                  }

                  if (user?.role === "partner") {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?limit=${
                          locations?.length
                        }&page=1&search=&sort=name&dir=asc&partner_id=${
                          user.partner._id || user.partner
                        }&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  } else {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?limit=${locations?.length}&page=1&search=&sort=name&dir=asc&fields=name,partner,geoLocationData.address,geoLocationData.city,numRooms,createdAt`,
                        "Locations"
                      )
                    );
                  }
                }}
              />
            )}
          </Grid>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs"
              fullWidth
            >
              <DialogTitle id="alert-dialog-title">Add Tier: </DialogTitle>

              <DialogContent>
                <Grid item size={{ xs: 12 }} mt={3}>
                  <TierPicker tierV={tier || {}} setTier={setTier} setFieldValue={() => {}} />
                  {!tier?.value && <Danger validation="Tier is required!" />}
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDButton
                  sx={{
                    border: "2px solid grey", // Set the border color, width, and style
                    borderRadius: "8px", // Optional: Adjust border radius
                    padding: "8px 16px", // Optional: Add padding
                  }}
                  onClick={() => {
                    if (tier?.value) {
                      dispatch(publishUnpublishLoc(rowDataLoc?._id, rowDataLoc?.status, tier));
                      handleClose();
                    }
                  }}
                >
                  Publish
                </MDButton>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
        <Footer />
      </DashboardLayout>
    </div>
  );
};

export default AllLocations;
