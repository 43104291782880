/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewPartner page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import CuratorPicker from "components/AsyncSelect/CuratorPicker";
import Danger from "components/Danger";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOneUser } from "store/actions/userActions";

const ItineraryInfo = ({
  formData,
  detailPage = true,
  editPage = false,
  handleCuratorChange = () => {},
  selectedCurator = {},
  displayErr = false,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, introText, travelStyle, curator, days } = formField;
  const { title: titleV, introText: introTextV, travelStyle: travelStyleV, days: daysV } = values;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const sourceId = searchParams.get("sourceId");

  const isProduction = process.env.NODE_ENV === "production";
  const apiBaseUrl = isProduction ? "" : "https://staging.";

  const curatorUser = useSelector((state) => state.users.specificUser);

  useEffect(() => {
    if (source === "users" && sourceId) {
      dispatch(getOneUser(sourceId));
    }
  }, [source, sourceId]);

  useEffect(() => {
    if (curatorUser) {
      handleCuratorChange({ label: curatorUser?.name, value: curatorUser?._id });
      setFieldValue("curator", { label: curatorUser?.name, value: curatorUser?._id });
    }
  }, [curatorUser]);

  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Info</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            days: `${values?.days} ${values?.days > 1 ? "days" : "day"}`,
            city: values?.city,
            companion: values?.companion,
            interests: values?.interests,
            url: `${apiBaseUrl}lokalee.app/experiences/${values?.slug}`,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <CuratorPicker
                curator={curator}
                handleChange={handleCuratorChange}
                setFieldValue={setFieldValue}
                selectedCurator={selectedCurator}
                disabled={source === "users" && !!sourceId}
              />
              {displayErr && errors?.curator && <Danger validation={errors?.curator} />}
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={titleV}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV?.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={travelStyle.type}
                label={travelStyle.label}
                name={travelStyle.name}
                value={travelStyleV}
                placeholder={travelStyle.placeholder}
                error={errors.travelStyle && touched.travelStyle}
                success={travelStyleV?.length > 0 && !errors.travelStyle}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                label={introText.label}
                name={introText.name}
                value={introTextV}
                multiline
                outlined
                rows={5}
                placeholder={introText.placeholder}
                error={errors.introText && touched.introText}
                success={introTextV?.length > 0 && !errors.introText}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={days.type}
                label={days.label}
                name={days.name}
                value={daysV}
                placeholder={days.placeholder}
                error={errors?.days && touched?.days}
                success={daysV?.length > 0 && !errors?.days}
                disabled={editPage}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for ItineraryInfo
ItineraryInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
  handleCuratorChange: PropTypes.func,
  selectedCurator: PropTypes.instanceOf(Object),
  displayErr: PropTypes.bool,
};

export default ItineraryInfo;
