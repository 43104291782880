/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const RecommendationPicker = ({
  handleChange = () => {},
  recommendation,
  setFieldValue = () => {},
  itemType = "recommendation",
  destinations = [],
  selectedRecommendation,
}) => {
  const filterRec = (data) =>
    data.map((recommendationData) => ({
      label: recommendationData.title,
      _id: recommendationData._id,
    }));

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      return new Promise((resolve) => {
        const later = () => {
          clearTimeout(timeout);
          resolve(func.apply(this, args));
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      });
    };
  }

  const fetchOptions = (inputValue) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      let url = `${process.env.REACT_APP_API}/recommendations?`;
      if (inputValue) url += `title=${inputValue}`;
      if (destinations && destinations.length > 0) {
        const destinationIds = destinations.map((dest) => dest.value).join(",");
        url += `&destinations=${destinationIds}&`;
      }
      url += "&sort=name&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  const debouncedFetchOptions = debounce(fetchOptions, 1000);

  const promiseOptions = (inputValue) => debouncedFetchOptions(inputValue);

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {recommendation.label}
      </Typography>
      <AsyncSelect
        // ref={selectRef}
        key={selectedRecommendation}
        cacheOptions
        defaultOptions
        // defaultValue={recId}
        // defaultInputValue={recId}
        placeholder={
          // eslint-disable-next-line no-nested-ternary
          selectedRecommendation?.label
            ? selectedRecommendation?.label
            : selectedRecommendation?.title
            ? selectedRecommendation?.title
            : "Search for a Recommendation"
        }
        // key={recommendation}
        menuPosition="fixed"
        // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        // menuShouldScrollIntoView
        loadOptions={promiseOptions}
        onChange={(option) => {
          if (itemType === "recommendation") {
            handleChange(option, setFieldValue);
            setFieldValue("recommendation", option);
          } else {
            setFieldValue(itemType, option?._id);
            handleChange(option);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

RecommendationPicker.propTypes = {
  recommendation: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  itemType: PropTypes.string,
  destinations: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func,
  selectedRecommendation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default RecommendationPicker;
