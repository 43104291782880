/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";
import debounce from "lodash/debounce";

const CustomOption = ({ innerProps, data }) => (
  <div {...innerProps} style={{ display: "flex", alignItems: "center", padding: "8px 12px" }}>
    <span style={{ marginRight: "5px" }}>{data?.label}</span>
    {data?.type && (
      <span style={{ fontSize: "0.75rem", opacity: 0.75 }}>({data?.type?.toLowerCase()})</span>
    )}
  </div>
);

CustomOption.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

const CustomMultiValueLabel = ({ data }) => (
  <div style={{ display: "flex", alignItems: "center", padding: "3px 6px" }}>
    <span style={{ marginRight: "5px", fontSize: "0.870rem" }}>{data.label}</span>
    {data.type && (
      <span style={{ fontSize: "0.70rem", opacity: 0.75 }}>({data.type.toLowerCase()})</span>
    )}
  </div>
);

CustomMultiValueLabel.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const DynamicPrimaryDestinationPicker = ({
  primaryDestination = {},
  primaryDestinationV = {},
  handleChange = () => {},
  setFieldValue = () => {},
  isUser = false,
}) => {
  const filterDestination = (data) =>
    data.map((destinationsData) => ({
      label: destinationsData?.destinationName,
      value: destinationsData?._id,
      type: destinationsData?.destinationType,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/destinations?`;
      url += `${inputValue && `&destinationName=${inputValue}`}`;
      url += "&sort=destinationName";
      url += "&dir=asc";
      setTimeout(() => {
        // resolve();
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterDestination(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000); // Adjust the delay time (in milliseconds) as needed

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {primaryDestination.label}
      </Typography>{" "}
      <AsyncSelect
        key={primaryDestinationV}
        cacheOptions
        defaultOptions
        defaultValue={primaryDestinationV}
        placeholder="Search for a destination"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        getOptionValue={(option) => `${option.id || option.value}`}
        getOptionLabel={(option) => `${option?.destinationName || option.label}`}
        onChange={(options) => {
          // Handle an array of selected option
          if (!options || options.length === 0) {
            if (isUser) {
              setFieldValue("destinations", "");
              setFieldValue("locDestinations.destinations", "");
            } else {
              setFieldValue("primaryDestinationId", "");
            }
            handleChange(options);
          } else {
            if (isUser) {
              setFieldValue("destinations", options?.value);
              setFieldValue("locDestinations.destinations", options?.value);
            } else {
              setFieldValue("primaryDestinationId", options?.value);
            }
            handleChange(options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          Option: CustomOption, // Use the custom option component
          MultiValueLabel: CustomMultiValueLabel, // Use the custom component for selected options
        }}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem", // Adjust the font size to match the dropdown options
          }),
        }}
        pageSize={1000} // Set pageSize to load more options
      />
    </div>
  );
};

// typechecking props for DynamicPrimaryDestinationPicker
DynamicPrimaryDestinationPicker.propTypes = {
  primaryDestination: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  primaryDestinationV: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  isUser: PropTypes.bool,
};

export default DynamicPrimaryDestinationPicker;
