/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";

// Pages
import UserInfo from "../UserInfo";
import AdditionalInfo from "../AdditionalInfo";

const UserDetail = ({
  user = {},
  formField = {},
  selectedDestinations = [],
  handleDestinationsChange = () => {},
  selectedDestination = {},
  setSelectedDestinations = () => {},
  setSelectedLocation = () => {},
  handleDestinationChange = () => {},
  selectedPartner = {},
  handlePartnerChange = () => {},
  selectedLocation = {},
  handleLocationChange = () => {},
  selectedPromocodes = [],
  handlePromocodesChange = () => {},
  userDetailPage = true,
  setUserDetailPage = () => {},
  additionalDetailPage = true,
  setAdditionalDetailPage = () => {},
  setImgDetailPage = () => {},
}) => {
  const { values, touched, errors, setFieldValue, submitForm, isSubmitting } = useFormikContext();

  function getStepContent(type, formData) {
    if (type === "admin") {
      return (
        <UserInfo
          formData={formData}
          detailPage={userDetailPage}
          setDetailPage={setUserDetailPage}
          handleEdit={() => {
            setUserDetailPage(false);
            setAdditionalDetailPage(true);
            setImgDetailPage(true);
          }}
        />
      );
    }
    return (
      <AdditionalInfo
        formData={formData}
        cityObj={{ name: user?.city }}
        partnerObj={user?.partner}
        detailPage={additionalDetailPage}
        setDetailPage={setAdditionalDetailPage}
        handleEdit={() => {
          setUserDetailPage(true);
          setAdditionalDetailPage(false);
          setImgDetailPage(true);
        }}
        selectedDestinations={selectedDestinations}
        setSelectedDestinations={setSelectedDestinations}
        handleDestinationsChange={handleDestinationsChange}
        selectedDestination={selectedDestination}
        handleDestinationChange={handleDestinationChange}
        selectedPartner={selectedPartner}
        handlePartnerChange={handlePartnerChange}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        selectedPromocodes={selectedPromocodes}
        handlePromocodesChange={handlePromocodesChange}
        setSelectedLocation={setSelectedLocation}
      />
    );
  }

  return (
    <MDBox>
      {["admin", "addInfo"].map((item) => (
        <Card key={item} id="profile" sx={{ marginTop: "24px" }}>
          <MDBox p={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item size={{ xs: 12 }}>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  {getStepContent(item, {
                    values,
                    touched,
                    formField,
                    errors,
                    setFieldValue,
                    submitForm,
                    isSubmitting,
                  })}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      ))}
    </MDBox>
  );
};

// typechecking props for UserDetail
UserDetail.propTypes = {
  user: PropTypes.instanceOf(Object),
  formField: PropTypes.instanceOf(Object),
  handleDestinationsChange: PropTypes.func,
  selectedDestination: PropTypes.instanceOf(Object),
  selectedDestinations: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]),
  handleDestinationChange: PropTypes.func,
  selectedPartner: PropTypes.instanceOf(Object),
  selectedLocation: PropTypes.instanceOf(Object),
  selectedPromocodes: PropTypes.instanceOf(Array),
  handlePartnerChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  handlePromocodesChange: PropTypes.func,
  userDetailPage: PropTypes.bool,
  setUserDetailPage: PropTypes.func,
  additionalDetailPage: PropTypes.bool,
  setAdditionalDetailPage: PropTypes.func,
  setImgDetailPage: PropTypes.func,
  setSelectedDestinations: PropTypes.func,
  setSelectedLocation: PropTypes.func,
};

export default UserDetail;
