import React from "react";
import Grid from "@mui/material/Grid2";
import FormField from "components/FormField";
import CuratorPicker from "components/AsyncSelect/CuratorPicker";
import Danger from "components/Danger";
import PropTypes from "prop-types";

const CuratorFields = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleCuratorChange,
  curator,
}) => (
  <>
    <Grid item size={{ xs: 12 }}>
      <CuratorPicker
        curator={curator}
        handleChange={handleCuratorChange}
        setFieldValue={setFieldValue}
        selectedCurator={values?.curator}
      />
      {errors?.curator && touched.curator && <Danger validation={errors.curator} />}
    </Grid>
    <Grid item size={{ xs: 12 }}>
      <FormField
        type="text"
        label="Curator Name"
        name="curator.name"
        value={values?.curator?.name || values?.curator?.label || ""}
        disabled
      />
    </Grid>
    <Grid item size={{ xs: 12 }}>
      <FormField
        type="text"
        label="Curator Contact"
        name="curator.contact"
        value={values?.curator?.contact || values?.curator?.phone || ""}
        disabled
      />
    </Grid>
    <Grid item size={{ xs: 12 }}>
      <FormField
        type="email"
        label="Curator Email"
        name="curator.email"
        value={values?.curator?.email || ""}
        disabled
      />
    </Grid>
  </>
);

CuratorFields.propTypes = {
  values: PropTypes.shape({
    curator: PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      contact: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  curator: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCuratorChange: PropTypes.func.isRequired,
};

export default React.memo(CuratorFields);
