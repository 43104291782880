/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormField from "components/FormField";

import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, FormGroup, Typography } from "@mui/material";
import ParentsPicker from "components/AsyncSelect/ParentsPicker";
import { addTag, updateTag, getOneTag } from "store/actions/tagActions";
import MDTypography from "components/MDTypography";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import CompanionsPicker from "components/AutoComplete/CompanionsPicker";
import InterestsPicker from "components/AutoComplete/InterestsPicker";
import ImagePicker from "components/AutoComplete/ImagePicker";
import TypeTagPicker from "components/AutoComplete/TypeTagPicker";
import form from "./form";
import initialValues from "./initialValues";
import validations from "./validations";

const TagAndSubtagDialog = ({
  open,
  onClose,
  idSelected = "",
  typeVal = { label: "Recommendations", value: "RECOMMENDATIONS" },
  setTypeVal = () => {},
}) => {
  const dispatch = useDispatch();
  const { title, parents, type, top, interests, persona, messageBoxCategoryId } = form.formField;
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const tag = useSelector((state) => state.tags.tag);

  const mapInterests = (val) => {
    const interestMapping = {
      solo: "Solo",
      couples: "Partner",
      group: "Friends",
      family: "Family",
    };

    return val.map((item) => ({
      value: item,
      label: interestMapping[item] || item,
    }));
  };
  const typeMapping = {
    JOBS: { label: "Jobs", value: "JOBS" },
    RECOMMENDATIONS: { label: "Recommendations", value: "RECOMMENDATIONS" },
    BOTH: { label: "Rec. & Tickets", value: "BOTH" },
    TICKETS: { label: "Tickets", value: "TICKETS" },
  };

  useEffect(() => {
    if (tag) {
      const manipulated = {};
      manipulated.title = tag?.title || "";
      manipulated.messageBoxCategoryId = tag?.messageBoxCategoryId || "";
      manipulated.parentTagIds = tag?.parentTagIds || [];
      manipulated.top = tag?.top || false;
      manipulated.type = typeMapping[tag?.type] || typeVal;
      manipulated.persona = tag?.persona || [];
      manipulated.interests = tag?.interests || [];
      manipulated.icon = tag?.icon || "";
      manipulated._id = tag?._id;

      setInitialState(manipulated);
      setSelectedOptions(tag?.persona?.length > 0 ? mapInterests(tag?.persona) : []);
    } else {
      setInitialState({
        ...initialValues,
        type: typeVal,
      });
    }
  }, [tag, typeVal]);

  useEffect(() => {
    if (idSelected) dispatch(getOneTag(idSelected));
  }, [idSelected]);

  const handleSubmit = (values, actions) => {
    if (tag !== null) {
      dispatch(updateTag(values));
    } else {
      dispatch(addTag(values));
    }

    actions?.setSubmitting(false);
    actions?.resetForm();
  };

  const handleSelectionChange = (selected) => {
    setSelectedOptions(selected);
    // If you need to do something else with the selected options, do it here
  };

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validations[0]}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          handleSubmit(values);
          onClose();
          resetForm(initialValues);
        }, 500);
      }}
    >
      {({ setFieldValue, values, submitForm, errors, touched, resetForm }) => (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          keepMounted
          onClose={() => {
            onClose();
            resetForm(initialValues);
          }}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-paper": {
              height: "auto",
              width: { sm: "100%", md: "460px" },
              minWidth: { sm: "100%", md: "460px" },
            },
          }}
        >
          <DialogTitle style={{ textAlign: "center" }}>
            {tag?.title ? "Edit Tag" : "Add Tag"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item size={{ xs: 12 }}>
                <FormField
                  type={title.type}
                  label={title.label}
                  name={title.name}
                  value={values?.title}
                  placeholder={title.placeholder}
                  error={errors?.title && touched?.title}
                  success={values?.title?.length > 0 && !errors?.title}
                />
              </Grid>
              <Grid item size={{ xs: 12 }}>
                <Typography
                  style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}
                >
                  {type.label}
                </Typography>
                <TypeTagPicker
                  typeV={values?.type || typeVal || {}}
                  setType={setTypeVal}
                  setFieldValue={setFieldValue}
                  setSelectedOptions={setSelectedOptions}
                />
              </Grid>
              <Grid item size={{ xs: 12 }}>
                <ParentsPicker
                  parents={parents}
                  parentsV={values?.parentTagIds ? values?.parentTagIds : values?.parents || []}
                  typeVal={typeVal?.value}
                  setFieldValue={setFieldValue}
                  error={errors.parentTagIdsV && touched.parentTagIdsV}
                  success={values?.parents?.length > 0 && !errors.parents}
                />
              </Grid>

              <Grid item size={{ xs: 12 }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={values?.top}
                        name={top.name}
                        onChange={(event) => setFieldValue("top", event.target.checked)}
                      />
                    }
                    label="Top"
                  />
                </FormGroup>
              </Grid>
              {typeVal?.value === "JOBS" && values?.type?.value === "JOBS" && (
                <Grid item size={{ xs: 12 }}>
                  <ImagePicker key={values?.icon} edit imageType="icon" textAdded="Upload Icon *" />
                </Grid>
              )}
              {typeVal?.value !== "JOBS" && values?.type !== "JOBS" ? (
                <>
                  <Grid item size={{ xs: 12 }}>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {persona?.label}
                    </MDTypography>
                    <CompanionsPicker
                      handleSelectionChange={handleSelectionChange}
                      selectedValues={selectedOptions}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item sm={12} size={{ xs: 12 }}>
                    <InterestsPicker
                      interests={interests}
                      interestsV={values?.interests || []}
                      setFieldValue={setFieldValue}
                      error={errors.interests && touched.interests}
                      success={values?.interests && !errors.interests}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={messageBoxCategoryId.type}
                    label={messageBoxCategoryId.label}
                    name={messageBoxCategoryId.name}
                    value={values?.messageBoxCategoryId}
                    placeholder={messageBoxCategoryId.placeholder}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (Number(val) > 0 || val === "") {
                        setFieldValue("messageBoxCategoryId", val);
                      }
                    }}
                    error={errors?.messageBoxCategoryId && touched?.messageBoxCategoryId}
                    success={
                      values?.messageBoxCategoryId?.length > 0 && !errors?.messageBoxCategoryId
                    }
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose();
                resetForm(initialValues);
              }}
            >
              Cancel
            </Button>
            <Button onClick={submitForm}> {tag?.title ? "Edit" : "Add"}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

TagAndSubtagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setTypeVal: PropTypes.func,
  typeVal: PropTypes.instanceOf(Object),
  idSelected: PropTypes.string,
};

export default TagAndSubtagDialog;
