/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Html, Section, Text } from "@react-email/components";
import { propTypes, defaultProps } from "./PropTypes";
import HeadSection from "../HeadSection";
import LogoSection from "../LogoSection";
import FooterSection from "../FooterSection";

const EmailToGuest = ({ data }) => {
  return (
    <Html>
      <HeadSection />
      <Body
        style={{
          backgroundColor: "#f7fafc", // Light gray background
          lineHeight: "1.625", // Relaxed line spacing
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Container
          style={{
            backgroundColor: "#ffffff", // White background
            marginLeft: "auto", // Center horizontally
            marginRight: "auto", // Center horizontally
            padding: "0",
            maxWidth: "600px", // Email width
            border: "1px solid #eaeaea", // Border styling
          }}
        >
          {/* Logo Section */}
          <LogoSection />

          {/* Greeting Section */}
          <Section
            style={{
              textAlign: "left", // Center-align text
              padding: "1.5rem 2rem 1rem 2rem",
            }}
          >
            <Text
              style={{
                fontSize: "24px",
                color: "#222D29",
                marginBottom: "1rem",
              }}
            >
              You have a pending premium service request from a guest. Kindly review the following
              details to ensure fulfillment within possibility.
            </Text>
          </Section>

          {/* Service Details Section */}
          <Section
            style={{
              backgroundColor: "#F2F2F2", // Light gray background
              padding: "16px 24px",
              borderRadius: "11.54px", // Rounded corners
              margin: "0 auto 24px auto", // Center and apply bottom margin
              maxWidth: "536px", // Max width for alignment
            }}
          >
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#222D29",
                marginBottom: "8px",
              }}
            >
              SERVICE DETAILS
            </Text>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Order Number:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.invoiceNumber}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Order Details:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.items.map((item) => item.ref.title).join(", ")}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Order Quantity:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.totalPax}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Request Date & Time:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.createdAt}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Order Status:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.status}
              </Text>
            </div>
          </Section>

          {/* Guest Details Section */}
          <Section
            style={{
              backgroundColor: "#F2F2F2", // Light gray background
              padding: "16px 24px",
              borderRadius: "11.54px", // Rounded corners
              margin: "0 auto 24px auto", // Center and apply bottom margin
              maxWidth: "536px", // Max width for alignment
            }}
          >
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#222D29",
                marginBottom: "8px",
              }}
            >
              GUEST DETAILS
            </Text>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Last Name:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.user.name}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Room Number:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.user.roomNumber}
              </Text>
            </div>
            <div style={{ display: "flex", marginBottom: "8px" }}>
              <Text style={{ fontSize: "14px", color: "#222D29" }}>Email:</Text>
              <Text style={{ fontSize: "14px", fontWeight: "bold", color: "#222D29" }}>
                {data.email}
              </Text>
            </div>
          </Section>

          {/* Footer Section */}
          <Section style={{ textAlign: "left", padding: "16px" }}>
            <Text
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#222D29",
                marginBottom: "8px",
              }}
            >
              Thank you,
            </Text>
            <Text style={{ fontSize: "20px", color: "#222D29" }}>Lokalee Operations Team</Text>
          </Section>
          <FooterSection hasSocialLinks={false} />
        </Container>
      </Body>
    </Html>
  );
};

EmailToGuest.defaultProps = defaultProps;
EmailToGuest.propTypes = propTypes;

export default EmailToGuest;
