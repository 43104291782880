import React from "react";
import Grid from "@mui/material/Grid2";
import FormField from "components/FormField";
import PropTypes from "prop-types";

const ProviderFields = ({ values, errors, touched }) => (
  <>
    <Grid item size={{ xs: 12 }}>
      <FormField
        type="text"
        label="Provider Name"
        name="provider.name"
        value={values.provider?.name || ""}
        error={errors.provider?.name && touched.provider?.name}
        placeholder="Enter provider name"
      />
    </Grid>
    <Grid item size={{ xs: 12 }}>
      <FormField
        type="text"
        label="Provider Contact"
        name="provider.contact"
        value={values.provider?.contact || ""}
        error={errors.provider?.contact && touched.provider?.contact}
        placeholder="Enter provider contact"
      />
    </Grid>
    <Grid item size={{ xs: 12 }}>
      <FormField
        type="email"
        label="Provider Email"
        name="provider.email"
        value={values.provider?.email || ""}
        error={errors.provider?.email && touched.provider?.email}
        placeholder="Enter provider email"
      />
    </Grid>
  </>
);

ProviderFields.propTypes = {
  values: PropTypes.shape({
    provider: PropTypes.shape({
      name: PropTypes.string,
      contact: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
};

export default React.memo(ProviderFields);
