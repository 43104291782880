/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import LocationPicker from "components/AutoComplete/LocationPicker";
import LocationUserPartner from "components/LocationUserPartner";

const LocationFields = ({
  userRole,
  locationPartner = {},
  locations,
  values,
  location = {},
  errors = {},
  touched = {},
  setFieldValue,
  handleLocationChange,
}) => (
  <Grid item size={{ xs: 12 }}>
    {userRole === "admin" ? (
      <LocationPicker
        setFieldValue={setFieldValue}
        location={{
          label: location?.label || "",
          name: "locCity.location",
        }}
        selectedLocation={values?.location || {}}
        handleLocationChange={handleLocationChange}
        error={errors.locCity?.location && touched.location}
        success={!!values.location?.name && !errors.locCity?.location}
      />
    ) : (
      <LocationUserPartner
        defaultLocationId={locationPartner?._id || locations[0]?._id}
        handleChange={handleLocationChange}
        locations={locations}
        location={values.location}
        disabled
      />
    )}
  </Grid>
);

LocationFields.propTypes = {
  userRole: PropTypes.string.isRequired,
  locationPartner: PropTypes.instanceOf(Object),
  locations: PropTypes.instanceOf(Array).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
};

export default React.memo(LocationFields);
