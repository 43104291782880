/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";

const CurrencyPicker = ({
  handleSelectCurrency = () => {},
  currency = {},
  setFieldValue = () => {},
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  const currencyList = [
    { code: "EUR", name: "EUR" },
    { code: "USD", name: "USD" },
    { code: "AED", name: "AED" },
    { code: "GBP", name: "GBP" },
    { code: "CAD", name: "CAD" },
    { code: "AUD", name: "AUD" },
    { code: "EGP", name: "EGP" },
    { code: "TRY", name: "TRY" },
    { code: "SAR", name: "SAR" },
  ];

  return (
    <SelectF
      options={currencyList}
      placeholder="Select a currency"
      value={currency || null} // Ensure currency is either null or a valid object
      onChange={(event) => {
        if (event) {
          setFieldValue("defaultCurrencyCode", event);
          handleSelectCurrency(event);
        } else {
          setFieldValue("defaultCurrencyCode", null);
          handleSelectCurrency(null); // Ensure null is passed on clear
        }
      }}
      getOptionValue={(option) => `${option.name}`}
      getOptionLabel={(option) => `${option.name}`}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: getOptionStyle,
      }}
      menuPosition="fixed"
      menuPlacement="auto"
      menuShouldScrollIntoView={false}
      isSearchable
      isClearable
    />
  );
};

// typechecking props for CurrencyPicker
CurrencyPicker.propTypes = {
  handleSelectCurrency: PropTypes.func,
  currency: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  setFieldValue: PropTypes.func,
};

export default CurrencyPicker;
