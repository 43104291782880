/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import {
  SET_ITEM_LOADING,
  CLEAR_ERRORS,
  ITEM_ADD_SUCCESS_STEP0,
  ITEM_ADD_SUCCESS_STEP1,
  ITEM_ADD_SUCCESS_STEP2,
  ITEM_ADD_SUCCESS_STEP3,
  ITEM_ADD_SUCCESS_STEP4,
  ITEM_FAIL,
  UPDATE_ITEM,
  DELETE_SINGLE_ITEM,
  SET_ITEM_WIDGET_LOADING,
  CLEAR_GENERATED_ITEMS,
  REJECT,
  APPROVE,
  EDIT_ERROR,
  SET_ITEM_EMPTY,
  GET_UPDATED_TICKET_TYPE,
  SET_TOP_FALSE,
  SET_TOP_TRUE,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

export const addTicketStep0 = (data, ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const combinedArray = [data?.tag, ...(Array.isArray(data?.themes) ? data.themes : [])];

  const tagsArray = combinedArray
    .filter((item) => item !== undefined && item !== null) // Remove undefined or null items
    .map((item) => item._id || item.value); // Extract _id or value

  const payload = {
    title: data?.title,
    type: "STANDARD",
    tags: tagsArray.length > 0 ? tagsArray : undefined,
    description: data?.overview || data?.description,
    ...((data?.location?.length > 0 || data?.location) && {
      location: data?.location?._id
        ? data?.location?._id
        : data?.location?.value
        ? data?.location?.value
        : data?.location,
    }),
    curator:
      data?.curatorOrProv === "curator" && (data?.curator?.value || data?.curator?.id?._id)
        ? data?.curator?.value || data?.curator?.id?._id
        : "",
    provider: data?.curatorOrProv === "provider" ? data?.provider : {},
    destinations: data?.destinations?.map((destination) => destination.value),
    primaryDestination:
      data?.primaryDestination?.id || data?.primaryDestination?.value
        ? data?.primaryDestination?.id || data?.primaryDestination?.value
        : data?.primaryDestination,
    id: ticketId?.length > 0 ? ticketId : "",
  };

  try {
    const res = await axios.post(`${URL}/tickets`, payload, config);

    dispatch({
      type: ITEM_ADD_SUCCESS_STEP0,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const addTicketStep1 = (data, ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const transformedItineraryItems =
    data?.itineraryItems?.length > 0
      ? data?.itineraryItems?.map((item) => ({
          pointOfInterestLocation: {
            pin: {
              id: item?.pointOfInterestLocation?.pin?.id || item?.pointOfInterestLocation?.pin,
            },
            attractionId:
              item?.pointOfInterestLocation?.attractionId?.id ||
              item?.pointOfInterestLocation?.attractionId,
          },
          duration: item.duration,
          description: item.description,
        }))
      : [];

  const transformedTravelerPickup = {
    ...data.logistics?.travelerPickup,
    pickupOptionType: data?.pickupOptionType,
    pins: data?.logistics?.travelerPickup?.pins?.map((p) => ({
      pin: p?.pin?.id,
      pickupType: p?.pickupType,
    })),
  };

  let logistics = null;

  if (data?.pickupOptionType !== "MEET_EVERYONE_AT_START_POINT") {
    if (data?.logistics?.travelerPickup?.pins?.length > 0) {
      logistics = {
        start: data?.pinsStart?.map((item) => ({
          pin: {
            id: item.value ? item.value : item._id,
          },
        })),
        end: data?.pinsEnd?.map((item) => ({
          pin: {
            id: item.value ? item.value : item._id,
          },
        })),
        travelerPickup: transformedTravelerPickup,
      };
    } else {
      logistics = {
        start: data?.pinsStartValue?.map((item) => ({
          pin: {
            id: item.value ? item.value : item._id,
          },
        })),
        end: data?.pinsEndValue?.map((item) => ({
          pin: {
            id: item.value ? item.value : item._id,
          },
        })),
      };
    }
  } else if (data?.travelerPickup?.pins?.length > 0) {
    logistics = {
      start: data?.pinsStart?.map((item) => ({
        pin: {
          id: item.value ? item.value : item._id,
        },
      })),
      end: data?.pinsEnd?.map((item) => ({
        pin: {
          id: item.value ? item.value : item._id,
        },
      })),
      travelerPickup: transformedTravelerPickup,
    };
  } else {
    logistics = {
      start: data?.pinsStart?.map((item) => ({
        pin: {
          id: item.value ? item.value : item._id,
        },
      })),
      end: data?.pinsEnd?.map((item) => ({
        pin: {
          id: item.value ? item.value : item._id,
        },
      })),
      travelerPickup: { pickupOptionType: data.pickupOptionType },
    };
  }

  const payload = {
    highlight: data.highlight,
    top: data.top,
    itineraryItems: transformedItineraryItems,
    skipTheLine: data?.skipTheLine,
    languageGuides: data?.languageGuides,
    inclusions: data?.inclusions,
    exclusions: data?.exclusions,
    duration:
      data?.durationRadio2 === "specificDuration"
        ? {
            day: data?.day2,
            hour: data?.hour2,
            minute: data?.min2,
          }
        : data?.from2
        ? {
            from: data?.from2,
            to: data?.to2,
            timeType: data?.timeType2,
          }
        : undefined,
    bookingRequirements:
      data?.minTravelersPerBooking || data?.maxTravelersPerBooking
        ? {
            minTravelersPerBooking: data?.minTravelersPerBooking,
            maxTravelersPerBooking: data?.maxTravelersPerBooking,
            requiresAdultForBooking: data?.accompanied,
          }
        : undefined,
    ...(logistics?.start !== undefined && { logistics }),
    // ...(logistics?.start !== undefined
    //   ? { logistics }
    //   : {
    //       logistics: {
    //         travelerPickup: {
    //           pickupOptionType: data?.pickupOptionType,
    //           allowCustomTravelerPickup: data?.allowCustomTravelerPickup,
    //           minutesBeforeDepartureTimeForPickup: data?.minutesBeforeDepartureTimeForPickup,
    //           additionalInfo: data?.additionalPickupInfo,
    //           pins: data?.logistics?.travelerPickup?.pins?.map((p) => ({
    //             pin: p?.pin?.id,
    //             pickupType: p?.pickupType,
    //           })),
    //         },
    //       },
    //     }),

    additionalInfo: data?.additionalInfo,
    badges: data?.badges?.length > 0 ? [data?.badges[0]?.value] : [],
  };

  try {
    const res = await axios.patch(`${URL}/tickets/${ticketId}/content`, payload, config);

    dispatch({
      type: ITEM_ADD_SUCCESS_STEP1,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const addTicketStep2 = (data, ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  // if (data?.images) {
  //   data.images.path = data?.images?.name;
  //   form.append("images", data?.images);
  //   // form.append("hasLogo", true);
  // }
  data.images?.map((file) => {
    form.append("images", file);
    form.append("hasLogo", true);
    return file;
  });
  if (data?.video) form.append("video", data?.video);

  try {
    const res = await axios.patch(`${URL}/tickets/${ticketId}/media`, form, config);

    dispatch({
      type: ITEM_ADD_SUCCESS_STEP2,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const addTicketStep3 = (data, ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const concatenatedAvailabilities = [...(data?.availabilitiesAdded || [])];

  const payload = {
    ageBands: data?.ageBandsObject,
    currency: data?.defaultCurrencyCode?.name,
    type: data?.pricingInfo?.type ? data?.pricingInfo?.type : data?.pricingInfo,
    availabilities: concatenatedAvailabilities,
  };

  try {
    const res = await axios.patch(`${URL}/tickets/${ticketId}/schedule`, payload, config);

    dispatch({
      type: ITEM_ADD_SUCCESS_STEP3,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const addTicketStep4 = (data, ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const bookingConfirmationSettings =
    data?.bookingCutoffType === "FIXED_TIME" ||
    data?.bookingConfirmationSettings?.bookingCutoffType === "FIXED_TIME"
      ? {
          bookingCutoffType:
            data?.bookingCutoffType || data?.bookingConfirmationSettings?.bookingCutoffType,
          time:
            data?.timeCutoff?.value || data?.bookingConfirmationSettings?.bookingCutoffFixedTime,
          days: data?.daysCutoff
            ? data?.daysCutoff?.value
            : data?.bookingConfirmationSettings?.days,
          confirmationType:
            data?.confirmationType?.value ||
            data?.bookingConfirmationSettings?.confirmationType?.value,
        }
      : data?.hoursAct > 0
      ? {
          bookingCutoffType:
            data?.bookingCutoffType || data?.bookingConfirmationSettings?.bookingCutoffType,
          hour: data?.hoursAct,
          confirmationType:
            data?.confirmationType?.value || data?.bookingConfirmationSettings?.confirmationType,
        }
      : undefined;
  // : undefined;

  const ticketInfo = (data?.ticketType || data?.ticketInfo) && {
    ticketTypes:
      typeof data?.ticketType === "string" ? [data?.ticketType] : data?.ticketInfo?.ticketTypes,
    ticketsPerBooking: data?.ticketsPerBooking || data?.ticketInfo?.ticketsPerBooking,
  };

  const cancellationPolicy = {};
  if (typeof data?.cancellationPolicy === "string") {
    cancellationPolicy.type = data.cancellationPolicy;
  } else {
    cancellationPolicy.type = data.cancellationPolicy?.type;
  }
  if (typeof data?.cancelIfBadWeather === "string") {
    cancellationPolicy.cancelIfBadWeather = data.cancelIfBadWeather;
  } else {
    cancellationPolicy.cancelIfBadWeather = data.cancellationPolicy?.cancelIfBadWeather;
  }

  const payload = {
    bookingConfirmationSettings,
    ticketInfo,
    cancellationPolicy: Object.keys(cancellationPolicy).length > 0 ? cancellationPolicy : undefined,
  };

  try {
    const res = await axios.patch(`${URL}/tickets/${ticketId}/cancelationPolicy`, payload, config);

    dispatch({
      type: ITEM_ADD_SUCCESS_STEP4,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

export const topFalseFn = (ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const payload = {
    top: false,
  };

  try {
    const res = await axios.patch(`${URL}/tickets/${ticketId}`, payload, config);

    dispatch({
      type: SET_TOP_FALSE,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const topTrueFn = (ticketId) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const payload = {
    top: true,
  };

  try {
    const res = await axios.patch(`${URL}/tickets/${ticketId}`, payload, config);

    dispatch({
      type: SET_TOP_TRUE,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Update Ticket
export const updateTicket = (data, image, partner) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.title) form.append("title", data.title.trim());
  if (data.identifier) form.append("identifier", data.identifier);
  if (data.description) form.append("description", data.description);
  if (data.address) form.append("address", data.address);
  if (data.about) form.append("about", data.about);
  if (data?.day) form.append("day", data?.day);
  if (data?.hour) form.append("hour", data?.hour);
  if (data?.min) form.append("min", data?.min);
  if (data?.minQty) form.append("minQty", data?.minQty);
  if (data?.maxQty) form.append("maxQty", data?.maxQty);
  form.append("video", data?.video);

  if (data.available_quantity) form.append("available_quantity", data.available_quantity);
  if (data.relevance) form.append("relevance", data.relevance);
  form.append("availability_type", data.availability_type);
  data.availabilities?.map((availability) => {
    form.append("availabilities", JSON.stringify(availability));
    return availability;
  });
  if (data.category) form.append("category", data.category.id ? data.category.id : data.category);
  if (data?.subcategories?.length > 0) {
    form.append("subcategories", data.subcategories);
  } else form.append("subcategories", []);
  if (data.location?._id) {
    form.append("location", data.location._id);
    if (partner) form.append("partner", partner?._id);
  } else {
    form.append("location", "null");
  }
  form.append("source", data.source);
  if (data.supplierName) form.append("supplierName", data.supplierName);
  if (data.whatToExpect) form.append("whatToExpect", data.whatToExpect);
  if (data.meeting_point) form.append("meeting_point", data.meeting_point);
  if (data.cutoff_time) form.append("cutoff_time", data.cutoff_time);
  if (data.cutoff_days) form.append("cutoff_days", data.cutoff_days);

  // form.append("retail_price", JSON.stringify(data.retail_price));
  // form.append("net_price", JSON.stringify(data.net_price));
  form.append("itemType", "ticket");

  data.tags?.map((tag) => {
    if (tag !== "") {
      form.append("tags", tag.trim());
      return tag;
    }
    return null;
  });
  if (data.url) form.append("url", data.url);
  form.append("cancellationPolicy", data.cancellationPolicy);

  if (data.highlight !== undefined) form.append("highlight", data.highlight);
  if (data.insider !== undefined) form.append("insider", data.insider);
  if (data.sponsored !== undefined) form.append("sponsored", data.sponsored);
  if (data.currated !== undefined) form.append("currated", data.currated);
  if (data.special !== undefined) form.append("special", data.special);
  if (data.experienceX !== undefined) form.append("experienceX", data.experienceX);
  if (data.exclusive !== undefined) form.append("exclusive", data.exclusive);
  if (data.disabled !== undefined) form.append("disabled", data.disabled);
  if (data.last_chance !== undefined) form.append("last_chance", data.last_chance);
  if (data.top_seller !== undefined) form.append("top_seller", data.top_seller);
  if (data.likely_to_sell_out !== undefined)
    form.append("likely_to_sell_out", data.likely_to_sell_out);
  if (data.must_see !== undefined) form.append("must_see", data.must_see);
  if (data.free_cancellation !== undefined)
    form.append("free_cancellation", data.free_cancellation);
  if (data.daily !== undefined) form.append("daily", data.daily);
  if (data.top !== undefined) form.append("top", data.top);
  if (data.cancellable !== undefined) form.append("cancellable", data.cancellable);
  // if (data.city) form.append("city", data.city.trim());
  if (data.country) form.append("country", data.country?.trim());
  if (data.lat) form.append("lat", data.lat);
  if (data.lng) form.append("lng", data.lng);
  if (data.region) form.append("region", data.region?.trim());
  form.append("contact", data.contact);
  form.append("bookingEmail", data.bookingEmail);
  // if (data.altTextField)
  form.append("altTextField", data.altTextField);
  // if (data.metaTitleField)
  form.append("metaTitleField", data.metaTitleField);
  // if (data.metaFields)
  form.append("metaFields", data.metaFields);

  // if (data.languages) form.append("languages", JSON.stringify(data.languages));

  if (data?.languages?.length > 0) {
    data?.languages.map((lang) => {
      form.append("languages", JSON.stringify(lang));
      return lang;
    });
  } else {
    form.append("languages", []);
  }

  if (data?.highlights?.length > 0) {
    data.highlights?.map((el) => {
      form.append("highlights", el);
      return el;
    });
  }

  if (data?.included?.length > 0) {
    data.included?.map((singleInclude) => {
      form.append("included", singleInclude);
      return singleInclude;
    });
  }

  if (data?.not_included?.length > 0) {
    data.not_included?.map((singleNotInclude) => {
      form.append("not_included", singleNotInclude);
      return singleNotInclude;
    });
  }

  // For logo
  if (image) {
    image.path = image?.name;
    form.append("images", image);
    form.append("hasLogo", true);
  }
  // to tell backend that images were cleared
  if (data?.images?.length === 0) form.append("cleared", true);

  if (data?.images?.length > 0) {
    data.images?.map((file) => {
      form.append("images", file);
      return file;
    });
  }

  if (data?.kidsFriendly) form.append("kidsFriendly", data?.kidsFriendly);

  if (data?.style?.length > 0) {
    form.append("style", data.style);
  }
  if (data?.interests?.length > 0) {
    form.append("interests", data.interests);
  }
  if (data.timeSlotPreference) form.append("timeSlotPreference", data.timeSlotPreference);
  if (data.seasonality) form.append("seasonality", data.seasonality);
  if (data.genderPreference) form.append("genderPreference", data.genderPreference);

  // if (image) form.append("image", image);
  try {
    const res = await axios.patch(`${URL}/items/${data._id}`, form, config);

    dispatch({
      type: UPDATE_ITEM,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ITEM_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Get updated TICKET type
export const updateTicketType = (ticketType) => ({
  type: GET_UPDATED_TICKET_TYPE,
  payload: ticketType,
});

export const reject = (id) => async (dispatch) => {
  try {
    const response = await axios.patch(`${URL}/items/unapprove/${id}`, {});

    dispatch({
      type: REJECT,
      payload: response?.data?.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      payload: err?.response?.data?.data?.message,
    });
  }
};

export const approve = (id) => async (dispatch) => {
  try {
    const response = await axios.patch(`${URL}/items/approve/${id}`, {});
    dispatch({
      type: APPROVE,
      payload: response?.data?.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      payload: err?.response?.data?.data?.message,
    });
  }
};

export const getTicketTranslations = (id) =>
  factory.get(
    `${URL}/tickets/${id}/translations?local=ar`,
    "GET_TICKET_TRANSLATION",
    "TICKET_FAIL"
  );

export const updateTicketTranslations = (data, id) =>
  factory.patch(
    data,
    `${URL}/tickets/${id}/translations?local=ar`,
    "UPDATE_ITEM_TRANSLATION",
    "ITEM_FAIL"
  );

// Delete Item - set active as false
export const deleteTicket = (id) =>
  factory.patch({ active: false }, `${URL}/tickets/${id}`, "DELETE_ITEM", "ITEM_FAIL");

// check ticket if it's found in stops experiences
export const checkTicket = (id, cityName, sourceV) =>
  factory.patch(
    { active: false, city: cityName, source: sourceV },
    `${URL}/items/checkItem/${id}?city=${cityName}`,
    "CHECK_ITEMS",
    "ITEM_FAIL"
  );

//  Get All Items
export const getItems = () => factory.get(`${URL}/tickets`, "GET_ITEMS", "ITEMS_FAIL");

// Get city items
export const getCityItems = (city) =>
  factory.get(
    `${URL}/items?itemType=ticket&sort=name&dir=asc&city=${city}`,
    "GET_ITEMS",
    "ITEM_FAIL"
  );

// get One Partner
export const getOneTicket = (id) =>
  factory.get(`${URL}/tickets/${id}`, "GET_ONE_ITEM", "ITEM_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Clear single Item
export const deleteSingleItem = () => ({ type: DELETE_SINGLE_ITEM });

// Set Loading
export const setLoading = () => ({ type: SET_ITEM_LOADING });

// Get Partner Items
export const getPartnerItems = (partner) =>
  factory.get(`${URL}/partners/${partner}/tickets`, "GET_ITEMS", "ITEMS_FAIL");

// Get Location Items
export const getLocationItems = (location) =>
  factory.get(`${URL}/locations/${location}/items`, "GET_ITEMS", "ITEMS_FAIL");

export const getItemTickets = (location) =>
  factory.get(`${URL}/tickets?location=${location}`, "GET_ITEMS", "ITEMS_FAIL");

export const getLocationTickets = (partnerId) =>
  factory.get(`${URL}/partners/${partnerId}/tickets`, "GET_ITEMS", "ITEMS_FAIL");

export const generateItemsList = (items, fontFamily, textAlign, location) =>
  factory.post(
    { items, fontFamily, textAlign, location },
    `${URL}/locations/widget`,
    "GENERATE_ITEMS_LIST",
    "GENERATE_ITEMS_LIST_FAIL"
  );

export const setItemEmpty = () => ({ type: SET_ITEM_EMPTY });

// Set Generate Widget Loading
export const setWidgetLoading = () => ({ type: SET_ITEM_WIDGET_LOADING });

// Get Generated items
export const getGeneratedItems = (locationSlug) =>
  factory.get(
    `${URL}/locations/locationWidget/${locationSlug}`,
    "GET_GENERATED_ITEMS",
    "GENERATE_ITEMS_LIST_FAIL"
  );

// Clear Generated Items
export const clearGeneratedItems = () => ({ type: CLEAR_GENERATED_ITEMS });
