/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Customized components
import TravelDeskPicker from "components/AutoComplete/TavelDeskPicker";
import FormField from "components/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getTravelDesks, setLoading } from "store/actions/travelDeskActions";
import DetailList from "components/DetailList";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import Danger from "components/Danger";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import UploadImage from "../Sliders/UploadImage";
import TemplatePicker from "../TemplatePicker";

const AdditionalInfo = ({
  formData,
  travelDeskObj = {},
  detailPage = true,
  editPage = false,
  handleChange = () => {},
  selectedTravelDesk = {},
}) => {
  const dispatch = useDispatch();

  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    travelDesk,
    numRooms,
    enableServices,
    dinningLabel,
    serviceTitle,
    template,
    images,
    trendTitle,
    trendSubTitle,
  } = formField;
  const {
    travelDesk: travelDeskV,
    numRooms: numRoomsV,
    images: imagesV,
    enableServices: enableServicesV,
    dinningLabel: dinningLabelV,
    serviceTitle: serviceTitleV,
    template: templateV,
    trendTitle: trendTitleV,
    trendSubTitle: trendSubTitleV,
  } = values;

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTravelDesks());
  }, []);
  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Additional info</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <DetailList
          data={{
            travelDesk: values?.travelDesk?.name,
            numRooms: values?.numRooms,
            facilities_and_services: values?.enableServices,
            dinningLabel: values?.dinningLabel,
            serviceTitle: values?.serviceTitle,
            template: values?.template,
            trendTitle: values?.trendTitle,
            trendSubTitle: values?.trendSubTitle,
            images: values?.images,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={values.enableServices}
                      name={enableServices.name}
                      value={enableServicesV}
                      onChange={(event) => setFieldValue("enableServices", event.target.checked)}
                    />
                  }
                  label={enableServices.label}
                />
              </FormGroup>
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <FormField
                type={dinningLabel.type}
                label={dinningLabel.label}
                name={dinningLabel.name}
                value={dinningLabelV}
                // placeholder={dinningLabelV.placeholder}
                error={errors?.dinningLabelV && touched?.dinningLabelV}
                success={dinningLabelV?.length > 0 && !errors?.dinningLabelV}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <FormField
                type={serviceTitle.type}
                label={serviceTitle.label}
                name={serviceTitle.name}
                value={serviceTitleV}
                placeholder={serviceTitle.placeholder}
                error={errors?.serviceTitle && touched?.serviceTitle}
                success={serviceTitleV?.length > 0 && !errors?.serviceTitle}
              />
            </Grid>
          </Grid>
          <Grid item size={{ xs: 12 }}>
            <TravelDeskPicker
              travelDesk={travelDesk}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              selectedTravelDesk={selectedTravelDesk || travelDeskObj || {}}
              error={errors.travelDesk && touched.travelDesk && errors.travelDesk?._id}
              success={travelDeskV?.name && !errors.travelDesk && errors.travelDesk?._id}
            />
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item size={{ sm: 12 }}>
              <FormField
                type={numRooms.type}
                label={numRooms.label}
                name={numRooms.name}
                value={numRoomsV}
                placeholder={numRooms.placeholder}
                error={errors?.numRooms && touched?.numRooms}
                success={numRoomsV?.length > 0 && !errors?.numRoomsV}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={3}>
            <Grid item size={{ xs: 6 }}>
              <FormField
                type={trendTitle.type}
                label={trendTitle.label}
                name={trendTitle.name}
                value={trendTitleV}
                placeholder={trendTitle.placeholder}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <FormField
                type={trendSubTitle.type}
                label={trendSubTitle.label}
                name={trendSubTitle.name}
                value={trendSubTitleV}
                placeholder={trendSubTitle.placeholder}
              />
            </Grid>
            <Grid item size={{ sm: 6 }}>
              <TemplatePicker
                template={template}
                templateV={templateV}
                setFieldValue={setFieldValue}
              />
            </Grid>

            <Grid item size={{ sm: 12 }}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                {images?.label}
              </MDTypography>
              <UploadImage
                key={values?.images}
                defaultImage={imagesV?.[0]}
                setFieldValue={setFieldValue}
                fieldName="images"
              />
              {errors?.images && touched?.images && <Danger validation={errors?.images} />}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for AdditionalInfo
AdditionalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  travelDeskObj: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
  selectedTravelDesk: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
};

export default AdditionalInfo;
