/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

// Material UI components
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Custom Components
import FormField from "components/FormField";
import { dateFormatWithDash, capitalizeFirstLetter } from "components/helper";
import ViewEditActions from "components/DetailList/ViewEditActions";
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";

const UserInfo = ({
  formData,
  detailPage = false,
  setDetailPage = () => {},
  handleEdit = () => {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, email, phone, dob } = formField;
  const { name: nameV, email: emailV, phone: phoneV } = values;
  const { submitForm, resetForm } = useFormikContext();

  // State
  const [dateOfBirth, setDateOfBirth] = useState("");

  // Selector
  const user = useSelector((state) => state.users.user);

  // UseEffect
  useEffect(() => {
    if (formData?.values?.dob) {
      setDateOfBirth(dateFormatWithDash(formData?.values?.dob));
    }
  }, [formData?.values?.dob]);

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          User info
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          stateName="users"
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      {detailPage ? (
        <DetailList
          data={{
            name: values?.name,
            email: values?.email,
            phone: values?.phone,
            dob: values?.dob,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={capitalizeFirstLetter(nameV)}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
                disabled={user.role === "partner"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={phone.type}
                label={phone.label}
                name={phone.name}
                value={phoneV}
                placeholder={phone.placeholder}
                error={errors.phone && touched.phone}
                success={phoneV.length > 0 && !errors.phone}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <MDDatePicker
                input={{
                  type: dob.type,
                  label: dob.label,
                  name: dob.name,
                  variant: "standard",
                  width: "100%",
                  value: dateOfBirth,
                }}
                onChange={(event, val) => {
                  setFieldValue("dob", val);
                }}
                options={{
                  maxDate: new Date(),
                  dateFormat: "Y-m-d",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default UserInfo;
