/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";
import React, { useRef, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";

import { exportDayName } from "utils/function/utilFunctions";

import OpeningHoursDialog from "./openingHoursDialog";

const StyledButton = styled(Button)`
  background-color: ${() => "#00acc1"};
  color: ${() => "white"};
  border: 1px solid ${() => "#00acc1"};
  &:hover {
    background-color: ${() => "#00acc1"};
  }
`;

const OpeningHours = ({ setFieldValue, openHours = {} }) => {
  /* *** Refs *** */
  const selectedDay = useRef(0);

  /* *** States *** */
  const [selectedDays, setSelectedDays] = useState([]);
  const [conflictedData, setConflictedData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleSignleDaySelection = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((item) => item !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  const handleDaysSelection = () => {
    if (selectedDays.length === openHours.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(openHours.map((row) => row.weekDay));
    }
  };

  const handelOverWritting = () => {
    const newOpenHours = conflictedData.map((item) => {
      if (item.length < 2) {
        return item[0];
      }

      return item[1];
    });

    setFieldValue("openHours", newOpenHours);
    setConflictedData([]);
  };
  const handleDeletion = () => {
    const newOpenHours = openHours.filter(
      (item) => !selectedDays.includes(item.weekDay) && item.weekDay !== selectedDay.current
    );
    setFieldValue("openHours", newOpenHours);
    setSelectedDays([]);
    selectedDay.current = 0;
    setOpenDeleteDialog(false);
  };

  const handleTitle = (row) => {
    const { open_all_day, closed, openHours: rowOpenHours = [] } = row;

    if (closed) {
      return "Closed";
    }

    if (open_all_day) {
      return "Open all day";
    }

    return rowOpenHours.map((item) => `${item.open} - ${item.close}`).join(" & ");
  };
  const handleDisplayingConflicts = (item, index) => {
    if (item.length < 2) {
      return null;
    }

    const [oldData, newData] = item;

    return (
      <div
        key={index}
        style={{
          display: "flex",
          padding: "10px",
          marginTop: "10px",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {exportDayName(oldData.weekDay)}
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {oldData.closed
            ? "Closed"
            : oldData.open_all_day
            ? "Open all day"
            : oldData.openHours.map((hour) => `${hour.open} - ${hour.close}`).join(" & ")}
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {newData.closed
            ? "Closed"
            : newData.open_all_day
            ? "Open all day"
            : newData.openHours.map((hour) => `${hour.open} - ${hour.close}`).join(" & ")}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "0px",
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onClick={handleDaysSelection}
                    checked={selectedDays.length === openHours.length && openHours.length !== 0}
                  />
                </TableCell>

                <TableCell padding="checkbox">Day</TableCell>
                <TableCell align="center">Time</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {openHours.map((row) => {
                const { weekDay } = row;

                return (
                  <TableRow
                    key={weekDay}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedDays.includes(weekDay)}
                        onClick={() => handleSignleDaySelection(weekDay)}
                      />
                    </TableCell>

                    <TableCell padding="checkbox">
                      <ListItem role="listitem">{exportDayName(weekDay)}</ListItem>
                    </TableCell>

                    <TableCell align="center">{handleTitle(row)}</TableCell>

                    <TableCell align="right">
                      <IconButton
                        edge="end"
                        key="close"
                        color="inherit"
                        aria-label="Close"
                        onClick={() => {
                          selectedDay.current = weekDay;
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        key="edit"
                        edge="end"
                        color="inherit"
                        aria-label="Edit"
                        onClick={() => {
                          selectedDay.current = weekDay;
                          setOpenEditDialog(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <div style={{ textAlign: "end" }}>
        <Button onClick={() => setOpenEditDialog(true)} style={{ margin: 10 }}>
          Add Hours
        </Button>

        <Button
          style={{ margin: 10 }}
          disabled={selectedDays.length === 0}
          onClick={() => setOpenDeleteDialog(true)}
        >
          Delete Hours
        </Button>
      </div>

      {/* Add/Edit Dialog */}
      {openEditDialog && (
        <OpeningHoursDialog
          selectedDay={selectedDay}
          openHoursValue={openHours}
          isVisible={openEditDialog}
          setFieldValue={setFieldValue}
          setConflictedData={setConflictedData}
          closeDialogBox={() => setOpenEditDialog(false)}
          title={`${selectedDay.current !== 0 ? "Edit" : "Select"} Days and Time`}
        />
      )}

      {/* Conflicts Dialog */}
      {conflictedData.length > 0 && (
        <Dialog
          fullWidth
          keepMounted
          open={conflictedData.length > 0}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-paper": {
              height: "auto",
              padding: "0px 24px",
              minWidth: { md: "680px" },
              width: { sm: "100%", md: "680px" },
            },
          }}
        >
          <DialogTitle>Conflicts found</DialogTitle>

          <DialogContent style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ width: "90%" }}>
                The following days already have opening hours. Do you want to overwrite them?
              </p>

              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  marginTop: "10px",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Day
                </div>

                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Old
                </div>

                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  New
                </div>
              </div>

              {conflictedData.map((item, index) => handleDisplayingConflicts(item, index))}
            </div>
          </DialogContent>

          <DialogActions>
            <StyledButton onClick={() => handelOverWritting()}>Overwrite</StyledButton>
            <StyledButton
              onClick={() => {
                setConflictedData([]);
              }}
            >
              Cancel
            </StyledButton>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete dialog */}
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          aria-labelledby="confirm-dialog"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="confirm-dialog">Delete Open Hour</DialogTitle>

          <DialogContent>
            <DialogContentText>Are you sure you want to delete this entry?</DialogContentText>
          </DialogContent>

          <DialogActions>
            <StyledButton variant="contained" isNegative onClick={() => setOpenDeleteDialog(false)}>
              No
            </StyledButton>

            <StyledButton variant="contained" onClick={handleDeletion}>
              Yes
            </StyledButton>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

OpeningHours.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  openHours: PropTypes.instanceOf(Array),
};

export default OpeningHours;
