/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import AgeAndTypePicker from "components/AutoComplete/AgeAndTypePicker";
import ShowHideComponentsPicker from "components/ShowHideComponentsPicker";
import FormField from "components/FormField";
// import { capitalizeFirstLetter } from "components/helper";
import DynamicPriceSchedules from "./DynamicPriceSchedules";

const ScheduleAndPricingInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleDaysChange = () => {},
  handleTimesChange = () => {},
  selectedCurrency = {},
  handleCurrencyChange = () => {},
  setAddSchedule = () => {},
  addSchedule = false,
  setEndDateProm = "",
  setStartDateProm = "",
  startDateProm = "",
  endDateProm = "",
  // handleRadioChange,
}) => {
  const { formField, values, setFieldValue, errors, touched } = formData;
  const {
    pricingInfo,
    scheduleOrOption,
    optionName,
    descriptionOption,
    ageBands,
    startAge,
    endAge,
    maxQty,
    minQty,
    retailPrice,
    netPrice,
    startDate,
    // setAdd,
    add,
    // date,
    // setDate,
    endDate,
    days,
    times,
  } = formField;
  const {
    ageBands: ageBandsV,
    pricingInfo: pricingInfoV,
    scheduleOrOption: scheduleOrOptionV,
    optionName: optionNameV,
    descriptionOption: descriptionOptionV,
    days: daysV,
    times: timesV,
    availabilitiesAdded: availabilitiesAddedV,
  } = values;

  const scheduleOrOptionOptions = [
    { value: "schedule", label: "Schedule" },
    { value: "option", label: "Option" },
  ];

  const ticketByOptions = [
    { value: "PER_PERSON", label: "Person" },
    { value: "UNIT", label: "Group" },
  ];

  const handleScheduleOrOptionChange = () => {
    setFieldValue("availabilitiesAdded", []);
  };

  const handleAddSchedule = () => {
    // Construct the pricesArray based on current values
    const pricesArray = values?.ageBands.map((ab, idx) => ({
      minTravelers: values[`minQty_${idx}`],
      maxTravelers: values[`maxQty_${idx}`],
      ageBand: ab,
      retailPrice: values[`retailPrice_${idx}`],
      netPrice: values[`netPrice_${idx}`],
    }));

    // Construct the new scheduleObject
    let scheduleObject = {
      startDate: values?.startDate,
      endDate: values?.endDate,
      timedEntries: values?.times.map((time) => time?.value),
      daysOfWeek: values?.days.map((day) => day?.value),
      prices: [], // Initialize prices as an empty array
    };

    if (values?.scheduleOrOption === "schedule") {
      scheduleObject = {
        ...scheduleObject,
        prices: values?.pricesObject,
      };
    }

    if (values?.scheduleOrOption === "option") {
      scheduleObject = {
        ...scheduleObject,
        prices: pricesArray,
        productOption: {
          title: values?.optionName,
          description: values?.descriptionOption,
        },
      };
      setAddSchedule(false);
    }

    const updatedAvailabilities = [...values.availabilitiesAdded, scheduleObject];
    setFieldValue("availabilitiesAdded", updatedAvailabilities);

    // Reset fields after adding
    values.ageBands.forEach((_, idx) => {
      setFieldValue(`minQty_${idx}`, "");
      setFieldValue(`maxQty_${idx}`, "");
      setFieldValue(`retailPrice_${idx}`, "");
      setFieldValue(`netPrice_${idx}`, "");
    });

    setFieldValue("days", []);
    setFieldValue("times", []);
    setFieldValue("startDate", "");
    setFieldValue("endDate", "");
    setFieldValue("optionName", "");
    setFieldValue("descriptionOption", "");
  };

  return (
    <>
      <ShowHideComponentsPicker title="Traveler Details">
        {detailPage && editPage ? (
          <DetailList
            data={{
              availabilities: values?.availabilities,
              PriceTheTicketBy: values?.pricingInfo?.type === "PER_PERSON" ? "Person" : "Group",
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item size={{ xs: 12 }}>
                <MDTypography variant="h5" style={{ marginBottom: "5px", marginRight: "0.5rem" }}>
                  Define the groups that can participate:
                </MDTypography>
                <AgeAndTypePicker
                  ageBands={ageBands}
                  ageBandsV={ageBandsV}
                  minAge={startAge}
                  maxAge={endAge}
                  setFieldValue={setFieldValue}
                  displayAge
                />
              </Grid>
              <Grid item size={{ xs: 12 }}>
                <DynamicRadioGroup
                  groupLabel={pricingInfo.label}
                  name={pricingInfo.name}
                  value={typeof pricingInfoV === "string" ? pricingInfoV : pricingInfoV?.type}
                  options={ticketByOptions}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item size={{ xs: 12 }}>
                <DynamicRadioGroup
                  groupLabel={scheduleOrOption.label}
                  name={scheduleOrOption.name}
                  value={
                    scheduleOrOptionV ||
                    (values?.productOptions?.length > 0 ? "option" : "schedule")
                  }
                  options={scheduleOrOptionOptions}
                  setFieldValue={setFieldValue}
                  handleChange={handleScheduleOrOptionChange}
                />
              </Grid>{" "}
            </Grid>

            {scheduleOrOptionV === "option" && (
              <>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={optionName.type}
                    label={optionName.label}
                    name={optionName.name}
                    value={optionNameV}
                    placeholder={optionName.placeholder}
                    error={errors.optionName}
                    success={optionNameV?.length > 0 && !errors.optionName}
                  />
                </Grid>
                <Grid item size={{ xs: 12 }}>
                  <FormField
                    type={descriptionOption.type}
                    label={descriptionOption.label}
                    name={descriptionOption.name}
                    value={descriptionOptionV}
                    placeholder={descriptionOption.placeholder}
                    error={errors.descriptionOption && touched.descriptionOption}
                    success={descriptionOptionV?.length > 0 && !errors.descriptionOption}
                  />
                </Grid>
              </>
            )}
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Pricing schedules">
        {detailPage && editPage ? (
          <DetailList
            data={{
              currency: values?.availabilities?.currency,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid item size={{ xs: 12 }}>
              <DynamicPriceSchedules
                startDate={startDate}
                endDate={endDate}
                add={add}
                minQty={minQty}
                maxQty={maxQty}
                retailPrice={retailPrice}
                netPrice={netPrice}
                scheduleOrOptionV={scheduleOrOptionV}
                ageBands={ageBands}
                ageBandsV={ageBandsV}
                daysV={daysV}
                days={days}
                handleDaysChange={handleDaysChange}
                handleTimesChange={handleTimesChange}
                times={times}
                timesV={timesV}
                availabilitiesV={availabilitiesAddedV}
                setFieldValue={setFieldValue}
                selectedCurrency={selectedCurrency}
                handleCurrencyChange={handleCurrencyChange}
                handleAddSchedule={handleAddSchedule}
                addSchedule={addSchedule}
                setAddSchedule={setAddSchedule}
                endDateProm={endDateProm}
                setEndDateProm={setEndDateProm}
                startDateProm={startDateProm}
                setStartDateProm={setStartDateProm}
                // handleRadioChange={handleRadioChange}
              />
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
    </>
  );
};

ScheduleAndPricingInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleDaysChange: PropTypes.func,
  setAddSchedule: PropTypes.func,
  setEndDateProm: PropTypes.func,
  setStartDateProm: PropTypes.func,
  endDateProm: PropTypes.string,
  startDateProm: PropTypes.string,
  handleTimesChange: PropTypes.func,
  handleCurrencyChange: PropTypes.func,
  selectedCurrency: PropTypes.instanceOf(Object),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  add: PropTypes.bool,
  addSchedule: PropTypes.bool,
};

export default ScheduleAndPricingInfo;
