/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import AttractionInfo from "pages/content/attractions/new-attraction/components/AttractionInfo";
import AdditionalInfo from "pages/content/attractions/new-attraction/components/AdditionalInfo";
import { useSelector } from "react-redux";

const AttractionDetail = ({
  formData,
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  travelDeskObj = {},
  tagObj = {},
  selectedTravelDesk = {},
  selectedDestination = {},
  selectedTag = {},
  handleTravelDeskChange = () => {},
  handleDestinationChange = () => {},
  handleTagChange = () => {},
  setSelectedTravelDesk = () => {},
}) => {
  const { submitForm, resetForm } = useFormikContext();
  const user = useSelector((state) => state.users.user);

  const handleCardTitle = () => {
    if (type === "attractionInfo") {
      return "Attraction info";
    }
    return "Additional info";
  };

  const handleInfoCard = () => {
    if (type === "attractionInfo") {
      return (
        <AttractionInfo
          formData={formData}
          editPage
          detailPage={detailPage}
          handleDestinationChange={handleDestinationChange}
          selectedDestination={selectedDestination}
          travelDeskObj={travelDeskObj}
          handleTravelDeskChange={handleTravelDeskChange}
          selectedTravelDesk={selectedTravelDesk}
        />
      );
    }
    return (
      <AdditionalInfo
        formData={formData}
        editPage
        detailPage={detailPage}
        tagObj={tagObj}
        handleTagChange={handleTagChange}
        selectedTag={selectedTag}
      />
    );
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        {user?.role === "admin" && (
          <ViewEditActions
            detailPage={detailPage}
            handleEdit={handleEdit}
            handleClose={() => {
              resetForm();
              setDetailPage(true);
              setSelectedTravelDesk(null);
            }}
            handleSave={() => {
              submitForm();
            }}
          />
        )}
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// typechecking props for AttractionDetail
AttractionDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  travelDeskObj: PropTypes.instanceOf(Object),
  tagObj: PropTypes.instanceOf(Object),
  selectedDestination: PropTypes.instanceOf(Object),
  handleDestinationChange: PropTypes.func,
  selectedTravelDesk: PropTypes.instanceOf(Object),
  handleTravelDeskChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  handleTagChange: PropTypes.func,
  setSelectedTravelDesk: PropTypes.func,
  // editorState: PropTypes.instanceOf(Object),
  // setEditorState: PropTypes.func,
};

export default AttractionDetail;
