/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Material Dashboard 2 PRO React examples

// libraries
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { format, isSameDay, subDays } from "date-fns";

// custom components
import Spinner from "components/Spinner";
import Danger from "components/Danger";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DynamicDateRange from "components/DynamicDateRange";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import MaterialTablePicker from "components/MaterialTablePicker";

// redux actions
import {
  setLoading,
  getProductionReports,
  clearHeaderAndReports,
} from "store/actions/reportActions";
import LocationReportPicker from "components/AutoComplete/ReportsPickers/LocationReportPicker";
import { getLocations, getUserPartnerLocations } from "store/actions/locationAction";

// material ui components
import Grid from "@mui/material/Grid2";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import pdfProductionReport from "./PdfProductionReport";
import generateCommissionInvoicePdf from "./AutoDownloadReport";

// Schemas
import initialValues from "./ProductionReportsList/schemas/initialValues";
import validations from "./ProductionReportsList/schemas/validations";
import ItemsTable from "./ProductionReportsList/ItemsTable";

const columns = [
  "Invoice Number",
  "Customer Name",
  "Quantity Number",
  "Purchase Date",
  "Booking Date",
  "Subtotal Amount",
  "Discount Amount",
  "Order Total",
  "Partner commission",
];

const ProductionReports = () => {
  /* ** Refs ** */
  const formRef = useRef();
  const generateAndDownlaod = useRef(false);

  /* ** Constants ** */
  const tableRef = createRef();
  const dispatch = useDispatch();

  /* ** Selectors ** */
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.reports.loading);
  const locations = useSelector((state) => state.locations.locations);
  const productionReports = useSelector((state) => state.reports.productionReports);

  /* ** States ** */
  const currentValidation = validations[0];
  const [hideDaterange, setHideDaterange] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setLoading();

    if (user?.role === "partner") {
      dispatch(getUserPartnerLocations(user?.partner?._id, "&status=published&fields=name,slug"));
    } else {
      dispatch(getLocations("&status=published&fields=name,slug"));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (generateAndDownlaod.current) {
      generateAndDownlaod.current = false;
      generateCommissionInvoicePdf(productionReports);
    }
  }, [productionReports]);
  useEffect(() => {
    dispatch(clearHeaderAndReports());
  }, [selectedLocation]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async () => {
    await sleep(1000);

    dispatch(setLoading());
    dispatch(
      getProductionReports(
        {
          dateRange,
          isReport: false,
          slug: selectedLocation?.slug,
          locationId: selectedLocation?._id,
        },
        selectedCurrency?.name
      )
    );
  };
  const handleSubmit = () => {
    if (!selectedLocation) {
      return;
    }
    submitForm();
  };
  const handleDateChange = (date, event) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    }
  };
  const handleLocationChange = (value) => {
    if (!value) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(value);
    }
  };

  const detailsPanel = (rowData) => (
    <MDBox sx={{ ml: 6, mr: 4 }}>
      <ItemsTable items={rowData.rowData.items} />
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item size={{ xs: 12, lg: 8 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, formData, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item sx={{ mt: 3 }} size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Location
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <LocationReportPicker
                      loading={loading}
                      locationReportPage
                      locations={locations}
                      location={selectedLocation}
                      setFieldValue={setFieldValue}
                      handleChange={handleLocationChange}
                    />
                  </MDBox>
                  {errors?.location && touched?.location && (
                    <Danger validation={errors?.location} />
                  )}
                </Grid>
                <Grid item sx={{ mt: 3 }} size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Currency
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                    }}
                  >
                    <CurrencyPicker
                      currency={selectedCurrency}
                      handleSelectCurrency={(event) => {
                        setSelectedCurrency(event);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <DynamicDateRange
                    isTodayIncluded
                    defaultValue={dateRange}
                    selectedOption={dateRange}
                    hideDaterange={hideDaterange}
                    hideDateRangeFn={setHideDaterange}
                    handleDateChange={handleDateChange}
                  />
                  <MDBox style={{ fontSize: "small" }}>
                    Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
                    {dateRange[0].endDate.toDateString()}
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                sx={{
                  display: "flex",
                  marginTop: "20px",
                  textAlign: "right",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
                  <MDButton
                    color="dark"
                    type="submit"
                    variant="gradient"
                    onClick={() => {
                      if (Object.keys(errors).length === 0) {
                        generateAndDownlaod.current = false;
                        setHideDaterange(true);
                      }
                    }}
                  >
                    Generate report
                  </MDButton>

                  {user.role === "admin" && (
                    <MDButton
                      color="dark"
                      variant="gradient"
                      onClick={() => {
                        if (selectedLocation) {
                          generateAndDownlaod.current = true;
                          dispatch(setLoading());
                          dispatch(
                            getProductionReports(
                              {
                                dateRange,
                                isReport: true,
                                slug: selectedLocation?.slug,
                                locationId: selectedLocation?._id,
                              },
                              selectedCurrency?.name
                            )
                          );
                        }
                      }}
                    >
                      Generate partner commission report
                    </MDButton>
                  )}
                </div>
              </MDBox>

              {selectedLocation &&
                productionReports?.docInvoices?.length > 0 &&
                !generateAndDownlaod.current && (
                  <div
                    style={{
                      width: "100%",
                      height: "21px",
                      marginTop: "15px",
                      backgroundColor: "#d3d3d3",
                    }}
                  />
                )}
              {productionReports?.summary && !generateAndDownlaod.current && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between", // Ensures the items are spaced out
                    alignItems: "center", // Aligns both items vertically
                    marginTop: "15px",
                  }}
                >
                  {user.role === "admin" && (
                    <div
                      style={{
                        textAlign: "left",
                        marginLeft: "45px", // Add some margin from the table's left side
                      }}
                    >
                      <label
                        style={{
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Total Commission: {productionReports?.summary?.totalNetProfit}
                      </label>
                    </div>
                  )}

                  <div
                    style={{
                      textAlign: "right",
                      margin: "0 45px",
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Partner Total Commission: {productionReports?.summary?.totalComission}
                    </label>
                  </div>
                </div>
              )}

              <Grid item size={{ xs: 12 }} sx={{ mt: 3 }}>
                {productionReports?.docInvoices && !generateAndDownlaod.current && (
                  <MDBox sx={{ m: 5 }}>
                    <MaterialTablePicker
                      tableInside
                      actionsRole
                      title="Orders"
                      columns={columns}
                      tableRef={tableRef}
                      isLoading={loading}
                      isFreeDownloadAction
                      isExportable={false}
                      isRowClickable={false}
                      filteringOptions={false}
                      screen="ProductionReportsList"
                      currentUrl="productionReports"
                      searchField="productionReports"
                      actionsName="productionReports"
                      data={productionReports?.docInvoices}
                      detailPanel={(rowData) => detailsPanel(rowData)}
                      actionsOnClick={() => pdfProductionReport(productionReports)}
                    />
                  </MDBox>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Footer />
      <Spinner loading={loading} />
    </DashboardLayout>
  );
};

export default ProductionReports;
