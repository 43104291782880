import React from "react";
import { FormControl, FormGroup } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DynamicRadioGroup from "../../../../../../components/DynamicRadioGroup"; // Assuming DynamicRadioGroup is in the same directory

const AccessibilityForm = ({
  handleAccessibilityChange = () => {},
  selectedAdditionalInfo = {},
  // setFieldValue,
  physicalRestrictionsV = [],
}) => {
  const accessibilityOptions = [
    {
      label: "Is it Wheelchair accessible?",
      name: "WHEELCHAIR_ACCESSIBLE",
    },
    {
      label: "Is all transportation wheelchair accessible?",
      name: "TRANSPORTATION_WHEELCHAIR_ACCESSIBLE",
    },
    {
      label: "Are all surfaces wheelchair accessible (no uneven terrain, stairs, etc.)?",
      name: "SURFACES_WHEELCHAIR_ACCESSIBLE",
    },
    {
      label: "Is it stroller accessible?",
      name: "STROLLER_ACCESSIBLE",
    },
    {
      label: "Are service animals allowed?",
      name: "PETS_WELCOME",
      helperText: "Service animals are allowed.",
    },
    {
      label: "Can travelers easily arrive/depart on public transportation?",
      name: "PUBLIC_TRANSPORTATION_NEARBY",
    },
    {
      label: "Are infants required to sit on laps?",
      name: "INFANTS_MUST_SIT_ON_LAPS",
    },
    {
      label: "Are infant seats available?",
      name: "INFANT_SEATS_AVAILABLE",
    },
  ];

  return (
    <FormControl>
      <FormGroup>
        {accessibilityOptions.map((option) => (
          <MDBox
            key={option.name}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              borderBottom: "1px solid lightGrey",
              paddingTop: "5px",
            }}
          >
            <MDTypography component="span" variant="body2" sx={{ maxWidth: "470px" }}>
              {option?.label}
            </MDTypography>
            <DynamicRadioGroup
              // value={selectedAdditionalInfo[option.name] ? "yes" : "no"}
              value={
                (physicalRestrictionsV?.length > 0 &&
                  physicalRestrictionsV?.includes(option.name)) ||
                selectedAdditionalInfo.includes(option.name)
                  ? "yes"
                  : "no"
              }
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              name={option.name}
              // handleChange={handleAccessibilityChange}
              setFieldValue={handleAccessibilityChange}
            />
          </MDBox>
        ))}
      </FormGroup>
    </FormControl>
  );
};

AccessibilityForm.propTypes = {
  handleAccessibilityChange: PropTypes.func,
  // setFieldValue: PropTypes.func,
  selectedAdditionalInfo: PropTypes.instanceOf(Object),
  physicalRestrictionsV: PropTypes.instanceOf(Array),
};

export default AccessibilityForm;
