export const typeOptions = [
  { value: "tour", label: "Tour" },
  { value: "activity", label: "Activity" },
  { value: "ticket", label: "Ticket" },
  { value: "rental", label: "Rental" },
  { value: "transport", label: "Transport" },
];

export const curatorOrProvOptions = [
  { value: "curator", label: "Curator" },
  { value: "provider", label: "Provider" },
];
