import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 0.9,
    height: "-webkit-fill-available",
    color: "#003005", // Change label color
  },
  "& .MuiInputLabel-shrink": {
    lineHeight: 1.5,
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#003005", // Change bottom border color
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "#003005", // Change bottom border color on hover
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#003005", // Change bottom border color when focused
  },
  "& .Mui-focused": {
    color: "#003005", // Change label color when focused
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#003005", // Ensure the label color stays green when focused
  },
});

// eslint-disable-next-line react/function-component-definition
export default function AutocompleteTags({
  setFieldValue,
  tags,
  tagsV = [],
  labelName = "",
  ...rest
}) {
  return (
    <div data-test="component-autocomplete-tags">
      <Autocomplete
        id="tags"
        open={false}
        options={[]}
        multiple
        freeSolo
        defaultValue={tagsV?.length !== 0 ? tagsV : undefined}
        value={tagsV?.length !== 0 ? tagsV : []}
        onChange={(_event, newValue) => {
          setFieldValue(
            "tags",
            newValue.map((e) => e.toLowerCase().trim())
          );
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            {...rest}
            name={tags.name}
            variant="standard"
            label={labelName || tags.label}
            margin="normal"
            fullWidth
          />
        )}
      />
    </div>
  );
}

AutocompleteTags.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  tags: PropTypes.instanceOf(Object).isRequired,
  tagsV: PropTypes.instanceOf(Array),
  labelName: PropTypes.string,
};
