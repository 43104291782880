/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import colors from "assets/theme/base/colors";

const { primaryC } = colors;

const MDInputF = styled(MDInput)(({ disabled, multiline, error }) => ({
  backgroundColor: "transparent!important",
  opacity: disabled ? 0.6 : 1,
  "& .MuiInputLabel-root.Mui-focused": {
    color: primaryC.main, // Change the label color here
    height: "-webkit-fill-available", // Apply height to the label
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: primaryC.main, // Change the bottom border color here
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: primaryC.main, // Change the active (focused) border color here
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: error ? "#d32f2f" : primaryC.main, // Set red border if error exists
    },
    "&:hover fieldset": {
      borderColor: error && primaryC.main, // revert to primary color on hover if error exists
    },
    "&.Mui-focused fieldset": {
      borderColor: error ? "#d32f2f" : primaryC.main, // Retain red border on focus if error
    },
  },
  ...(multiline && {
    "& .MuiInputBase-inputMultiline": {
      paddingTop: "24px", // Adjust the value as needed
      paddingBottom: "16px", // Adjust the value as needed
      overflowY: "auto", // Ensure vertical scrolling
    },
    "& textarea": {
      paddingTop: "24px", // Additional padding for the textarea element
      paddingBottom: "16px", // Additional padding for the textarea element
    },
  }),
}));

const FormField = ({
  label,
  name,
  outlined = null,
  disabled = null,
  multiline = null,
  minRows = 1,
  InputProps = {},
  ...rest
}) => (
  <MDBox mb={1.5} style={{ width: "100%" }}>
    <Field
      {...rest}
      name={name}
      as={MDInputF}
      variant={outlined ? "outlined" : "standard"}
      label={label}
      disabled={disabled}
      fullWidth
      multiline={multiline} // Enable multiline based on prop
      minRows={multiline ? minRows : undefined} // Set minRows only if multiline is enabled
      style={{ height: "fit-content" }} // Apply inline style to the label
      InputProps={{
        ...InputProps, // Merge InputProps from props
        style: {
          ...InputProps?.style,
          paddingRight: "40px", // Your custom padding
        },
      }}
    />
    <MDBox mt={0.75}>
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
        <ErrorMessage name={name} />
      </MDTypography>
    </MDBox>
  </MDBox>
);

// Setting default props
FormField.defaultProps = {
  outlined: false,
  disabled: false,
  multiline: false,
  minRows: 1,
  InputProps: {},
};

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  InputProps: PropTypes.instanceOf(Object),
};

export default FormField;
