/* eslint-disable no-underscore-dangle */
import {
  SETTING_UPDATE_SUCCESS,
  PRODUCT_ADD_SUCCESS,
  GET_ONLINE_STORE,
  SET_LOCATION_LOADING,
  LOCATION_ADD_SUCCESS,
  LOCATION_FAIL,
  GET_LOCATIONS,
  GET_USER_PARTNER_LOCATIONS,
  CLEAR_ERRORS,
  DELETE_LOCATION,
  GET_ONE_LOCATION,
  UPDATE_LOCATION,
  GET_LOCATION_TAGS,
  GET_ONE_PARTNER,
  SET_LOCATION_APPEARANCE,
  UPDATE_FILTER,
  LOGOUT,
  UPDATE_LOCATION_TRANSLATION,
  SLIDE_LOCATION_ADD_SUCCESS,
  LOCATION_SLIDERS_FAIL,
  DELETE_LOCATION_SLIDER,
  SLIDE_LOCATION_UPDATE,
  GET_LOCATION_TRANSLATION,
  PUBLISH_UNPUBLISH,
  REFRESH_LOCATION,
  REFRESH_LOCATION_FAIL,
  SET_REFRESH_LOADING,
  GET_SUB_LOCATION_TAGS,
  LOCATION_JOBS_ADD_SUCCESS,
  WIFI_LOCATION_ADD_SUCCESS,
  LOCATION_WIFI_FAIL,
} from "../actions/Types";

const initialState = {
  error: null,
  added: false,
  loading: false,
  refreshLoading: false,

  wifi: {},
  jobs: [],
  store: [],
  sliders: [],
  locations: [],
  location: null,
  locationTags: [],
  locationSubTags: [],
  translationLocation: {},
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        location: action.payload.data.location,
      };
    case PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        store: [action.payload.data.data, ...state.store],
      };
    case GET_ONLINE_STORE:
      return {
        ...state,
        error: null,
        loading: false,
        store: action.payload.data,
      };
    case GET_LOCATION_TAGS:
      return {
        ...state,
        error: null,
        loading: false,
        locationTags: action.payload.data,
      };
    case GET_SUB_LOCATION_TAGS:
      return {
        ...state,
        error: null,
        loading: false,
        locationSubTags: action.payload.data,
      };
    case LOCATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: [action.payload.data, ...state.locations],
        added: true,
        location: null,
      };
    case SLIDE_LOCATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sliders: action.payload?.data?.location?.slider,
        location: action.payload?.data?.location,
        added: true,
      };
    case SLIDE_LOCATION_UPDATE:
      return {
        ...state,
        sliders: action.payload?.data?.location?.slider,
        location: action.payload?.data?.location,
        added: true,
      };

    case DELETE_LOCATION_SLIDER:
      return {
        ...state,
        loading: false,
        sliders: state.location?.slider,
        location: action.payload?.data?.location,
        added: true,
      };

    case WIFI_LOCATION_ADD_SUCCESS:
      return {
        ...state,
        wifi: action.payload?.data,
        location: action.payload?.data?.location,
        added: true,
      };
    case GET_ONE_LOCATION:
      return {
        ...state,
        error: null,
        added: false,
        loading: false,
        settingsUpdated: false,
        location: action.payload.data.data,
      };
    case GET_ONE_PARTNER: // Clear locations when partner is selcted to avoid pulling previous partner locations
      return {
        ...state,
        locations: [],
      };
    case GET_LOCATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: action.payload.data,
        added: false,
        location: null,
      };
    case GET_USER_PARTNER_LOCATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: action.payload.data.data,
        added: false,
        // location: null,
      };

    case SET_LOCATION_APPEARANCE:
      return {
        ...state,
        loading: false,
        error: null,
        location: action.payload,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        loading: false,
        locations: state.locations.filter(
          (location) => location._id !== action.payload.data.location._id
        ),
      };
    case UPDATE_LOCATION:
    case UPDATE_FILTER:
      return {
        ...state,
        locations: state.locations.map((location) =>
          location._id === action.payload.data.location._id
            ? action.payload.data.location
            : location
        ),
        loading: false,
        location: action.payload.data.location,
        added: true,
      };
    case UPDATE_LOCATION_TRANSLATION:
      return {
        ...state,
        translationLocation: action.payload.data,
      };
    case GET_LOCATION_TRANSLATION:
      return {
        ...state,
        translationLocation: action.payload.data,
      };
    case REFRESH_LOCATION:
      return {
        ...state,
        refreshLoading: false,
      };
    case REFRESH_LOCATION_FAIL:
      return {
        ...state,
        refreshLoading: false,
        error: action.payload,
      };

    case LOCATION_JOBS_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        jobs: action.payload.data,
        added: true,
      };

    case LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOCATION_SLIDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOCATION_WIFI_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_REFRESH_LOADING:
      return {
        ...state,
        refreshLoading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        locations: [],
        error: null,
        added: false,
        location: null,
      };

    case PUBLISH_UNPUBLISH:
      return {
        ...state,
        locations: state.locations.map((location) =>
          location._id === action.payload.data._id ? action.payload.data : location
        ),
        loading: false,
      };

    default:
      return state;
  }
};
export default variable;
