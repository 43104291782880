// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const DynamicCard = ({
  image = "",
  label = "",
  title = "",
  subHeader = "",
  startDate = "",
  endDate = "",
  action = { type: "internal", route: "/", color: "primary", label: "" },
  destinationName = "",
  nbOfDays = "",
  styleLabel = "",
}) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        width: "100%",
      }}
    >
      <MDBox position="relative" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          onClick={() => navigate(action)}
          sx={{
            width: "100%",
            height: "200px",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
            cursor: "pointer",
          }}
        />
      </MDBox>
      <MDBox mt={1} mx={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {label}
        </MDTypography>
        {styleLabel && (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            style={{
              backgroundColor: "#C60024",
              padding: "4px 6px",
              borderRadius: "2px",
              color: "#ffffff",
            }}
          >
            {styleLabel}
          </MDTypography>
        )}
        {nbOfDays && (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            style={{ display: "block" }}
          >
            {nbOfDays}
          </MDTypography>
        )}
        <MDBox mb={1}>
          {action.type === "internal" ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
              style={{ fontSize: "18px" }}
            >
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
              style={{ fontSize: "18px" }}
            >
              {title}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mb={2} lineHeight={0}>
          <MDTypography
            variant="button"
            fontWeight="light"
            color="text"
            style={{ fontSize: "13px" }}
          >
            {subHeader}
          </MDTypography>
          {destinationName?.length > 0 && (
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              style={{ fontSize: "13px" }}
            >
              {destinationName}
            </MDTypography>
          )}
          {startDate?.length > 0 && (
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              style={{ fontSize: "13px", display: "block" }}
            >
              Start date: {startDate?.split("T")[0]}
            </MDTypography>
          )}
          {endDate?.length > 0 && (
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              style={{ fontSize: "13px" }}
            >
              End date: {endDate?.split("T")[0]}
            </MDTypography>
          )}
        </MDBox>
        {action.route && (
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            {action.type === "internal" ? (
              <MDButton
                onClick={() => navigate(action.route)}
                variant="outlined"
                size="small"
                color={action.color}
                style={{ padding: "8px 8px" }}
              >
                {action.label}
              </MDButton>
            ) : (
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
                style={{ padding: "8px 8px" }}
              >
                {action.label}
              </MDButton>
            )}
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

// Typechecking props for the DynamicCard
DynamicCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  label: PropTypes.string,
  title: PropTypes.string,
  subHeader: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]),
    label: PropTypes.string,
  }),
  destinationName: PropTypes.string,
  nbOfDays: PropTypes.string,
  styleLabel: PropTypes.string,
};

export default DynamicCard;
