import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Section, Head, Html, Text, Button, Img } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import { Font } from "@react-email/font";
import FooterSection from "../FooterSection";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";

const ConciergeEmail = ({
  user = {
    name: "Lokalee",
  },
  token = "https://lokalee.app",
}) => (
  <Html>
    <Head>
      <Font
        fontFamily="Helvetica"
        fallbackFontFamily="Arial"
        webFont={{
          url: "https://fonts.gstatic.com/s/helvetica/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
          format: "woff2",
        }}
        fontStyle="normal"
      />
    </Head>
    <Tailwind>
      <Body className="bg-gray-100 font-sans leading-relaxed">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-center pt-4">
            <Img
              src="https://email-assets.lokalee.app/email/emailImages/Image.png"
              alt="Welcome"
              className="ml-4 mr-4 h-auto"
            />
          </Section>
          <Section className="text-center bg-white p-5 text-black">
            <Text className="text-[24px] leading-[34px] font-normal">Greetings {user.name},</Text>
            <Text className="text-[28px] leading-[34px] font-bold">Welcome to Lokalee!</Text>
          </Section>
          <Section className="text-center bg-[#8C8C8C] p-5 text-white">
            <Text className="text-[20px] leading-[23px] font-bold mb-1">
              We are happy to have you onboard!
            </Text>
            <Text className="text-[20px] leading-[23px] font-bold mb-2">
              Please use the following link to validate your email:
            </Text>
            <Button
              className="bg-white text-black text-[14px] leading-[14px] font-semibold py-2 px-4 rounded-md mx-auto mt-4 mb-4"
              href={token}
            >
              Reset Password
            </Button>
            <Text className="text-[20px] leading-[23px] font-bold mb-0">Thanks,</Text>
            <Text className="text-[20px] leading-[23px] font-bold mt-0">Lokalee Team</Text>
          </Section>
          <Section>
            <Text className="text-[20px] leading-[23px] font-bold mb-4 text-center">
              Access Our Backoffice To:
            </Text>
            <ul className="list-none pl-0 text-gray-700 flex flex-col items-center">
              <li className="flex items-center h-14 mb-2">
                <Img
                  src="https://email-assets.lokalee.app/email/emailImages/ticket-line.png"
                  alt="Check your password"
                  className="w-12 h-12 mr-4"
                />
                <div className="flex flex-col justify-center">
                  <Text className="text-[15px] leading-[17px] font-normal mb-0">
                    Check your password
                  </Text>
                  <Text className="text-[15px] leading-[17px] font-normal mt-0">
                    balance and commission
                  </Text>
                </div>
              </li>
              <hr className="border-t-2 w-[385px] border-[#C5D0CC] mb-2" />
              <li className="flex items-center mb-2">
                <Img
                  src="https://email-assets.lokalee.app/email/emailImages/iphone-old-apps-line.png"
                  alt="Save your profile details"
                  className="w-12 h-12 mr-4"
                />
                <Text className="text-[15px] leading-[17px] font-normal">
                  See your orders details
                </Text>
              </li>
              <hr className="border-t-2 w-[385px] border-[#C5D0CC] mb-2" />
              <li className="flex items-center mb-2">
                <Img
                  src="https://email-assets.lokalee.app/email/emailImages/qr-code-line.png"
                  alt="Download your QR code"
                  className="w-12 h-12 mr-4"
                />
                <Text className="text-[15px] leading-[17px] font-normal">
                  Download your Qr codes
                </Text>
              </li>
            </ul>
          </Section>
          <hr className="border-t-2 border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

ConciergeEmail.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  token: PropTypes.string,
};

export default ConciergeEmail;
