import React from "react";
import PropTypes from "prop-types";

import { Body, Container, Section, Html, Text, Button } from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";
import LogoSection from "pages/emails/LogoSection";
import IfYouNeedSupportSection from "pages/emails/IfYouNeedSupport";
import FooterSection from "pages/emails/FooterSection";
import HeadSection from "../HeadSection";

const ReviewsEmail = ({ customerName = "Lokalee", ticketName = "XXXXXXX" }) => (
  <Html>
    <HeadSection />
    <Tailwind>
      <Body className="bg-gray-100">
        <Container className="bg-white rounded-md shadow-md mx-auto max-w-lg">
          <LogoSection />
          <Section className="text-center bg-white p-5 text-black rounded-md">
            <Text className="text-[25px] leading-[26.5px] font-bold text-black mb-2">
              Dear {customerName}
            </Text>
            <Text className="text-[20px] leading-[23px] font-bold text-black mb-2">
              We hope you enjoyed {ticketName}
            </Text>

            <Text className="text-[20px] leading-[23px] font-normal mt-4 mb-4 text-black">
              Rate & Review Your Experience
            </Text>
            <Button
              className="text-[16px] leading-[16px] font-semibold bg-[#003005] text-white font-semibold py-2 px-4 rounded-md mx-auto"
              href="#"
            >
              Add Review
            </Button>
          </Section>
          <hr className="border-t-0  border-[#394A43]" />
          <IfYouNeedSupportSection />
          <FooterSection />
        </Container>
      </Body>
    </Tailwind>
  </Html>
);

ReviewsEmail.propTypes = {
  customerName: PropTypes.string,
  ticketName: PropTypes.string,
};

export default ReviewsEmail;
