/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import { setItemFilter } from "store/actions/filterActions";
import ViewEditActions from "components/DetailList/ViewEditActions";

const ByTickets = ({ locationId = "", filters = {} }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState("all");
  const [detail, setDetail] = useState(true);

  const ticketOptions = [
    { value: "all", label: "All" },
    { value: "own", label: "Only related to this location" },
    { value: "none", label: "None" },
    { value: "lokalee", label: "Lokalee" },
    { value: "viator", label: "Viator" },
  ];

  useEffect(() => {
    if (filters.ticketsOption?.length > 0) {
      setItem(filters.ticketsOption);
    }
  }, [filters]);

  const handleSave = (event) => {
    event.preventDefault(); // Prevent form submission
    dispatch(setItemFilter(locationId, item));
    setDetail(true);
  };

  const handleChange = (e, value) => {
    setItem(value);
  };

  return (
    <Card id="items" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">General Tickets filter</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12 }}>
            <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="body2">
                Choose the tickets you want your visitors to see:
              </MDTypography>

              <ViewEditActions
                detailPage={detail}
                handleEdit={() => setDetail(false)}
                handleClose={() => {
                  setDetail(true);
                  setItem(filters.ticketsOption);
                }}
                handleSave={handleSave}
              />
            </MDBox>
            <DynamicRadioGroup
              name="item"
              value={item}
              options={ticketOptions}
              setFieldValue={handleChange}
              disabled={detail}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// typechecking props for ByTickets
ByTickets.propTypes = {
  locationId: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};
export default ByTickets;
