/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/**
=========================================================
* LCM Dashboard V2
=========================================================

* Product Page: https://www.lcm.lokalee.app
* Copyright 2023 Lokalee (https://www.lokalee.app)

 =========================================================
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// actions

// Newticketinfo page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import MultiImage from "components/MultiImage";
import MDTypography from "components/MDTypography";
import Danger from "components/Danger";

const Media = ({ formData, editPage = false, detailPage = true }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { video } = formField;
  const { video: videoV, images: imagesV } = values;

  return (
    <MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            video: values?.video,
            images: values?.images,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={video.type}
                label={video.label}
                name={video.name}
                value={videoV || values?.video}
                placeholder={video.placeholder}
                error={errors.video && touched.video}
                success={videoV?.length > 0 && !errors.video}
              />
            </Grid>

            <Grid item size={{ sm: 12 }} sx={{ mt: 2 }}>
              <MDTypography variant="h6" fontWeight="regular" color="text">
                Images *
              </MDTypography>
              <MultiImage setFieldValue={setFieldValue} images={imagesV || values?.images} />
              {errors?.images && touched?.images && <Danger validation={errors?.images} />}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Media
Media.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default Media;
