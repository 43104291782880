import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid2";
import { Field } from "formik";
import DetailList from "components/DetailList";

const MenuFilter = ({ formData, detailPage = true, editPage = false }) => {
  const { values, setFieldValue } = formData;
  const { filters: filtersV } = values;

  const filterOptions = [
    "category",
    "distance",
    "language",
    "cuisines",
    "price",
    "features",
    "opening hours",
    "duration",
  ];

  // Adjusted initialization of checkboxes
  const [checkboxes, setCheckboxes] = useState(() =>
    filterOptions.map((value) => ({
      value,
      label: value.charAt(0).toUpperCase() + value.slice(1), // Capitalize label
      // Set checked based on filtersV or default to true
      checked: filtersV && filtersV?.length > 0 ? filtersV.includes(value) : true,
    }))
  );

  useEffect(() => {
    if (!filtersV || filtersV.length === 0) {
      setFieldValue("filters", filterOptions);
    }
  }, [filtersV, setFieldValue]);

  // Effect to update checkboxes state when filtersV changes
  useEffect(() => {
    if (filtersV && filtersV?.length > 0) {
      setCheckboxes((current) =>
        current.map((checkbox) => ({
          ...checkbox,
          checked: filtersV.includes(checkbox.value),
        }))
      );
    }
  }, [filtersV]);

  const handleCheckboxChange = (checked, value) => {
    // Update the checkboxes state
    setCheckboxes((current) =>
      current.map((checkbox) => (checkbox.value === value ? { ...checkbox, checked } : checkbox))
    );

    // After state update, filter and send only the labels of checkboxes that are checked
    const checkedFilters = checkboxes
      .filter((checkbox) => (checkbox.value === value ? checked : checkbox.checked))
      .map((checkbox) => checkbox.value);

    setFieldValue("filters", checkedFilters);
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Menu filter</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            filters: values?.filters,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid item size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column" }}>
            {checkboxes.map((item) => (
              <div key={item.value}>
                <Field
                  type="checkbox"
                  name={item.value}
                  checked={item.checked}
                  onChange={(e) => handleCheckboxChange(e.target.checked, item.value)}
                />
                <MDTypography variant="button" color="text" sx={{ marginLeft: "8px" }}>
                  {item.label}
                </MDTypography>
              </div>
            ))}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

MenuFilter.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default MenuFilter;
