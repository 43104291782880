import React from "react";
import { Divider, Grid2 } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TitlePicker from "components/AutoComplete/TitlePicker";
import FormField from "components/FormField";
import AvailabilityInclusionPanel from "components/AvailabilityInclusionPanel";
import AvailabilityExclusionPanel from "components/AvailabilityExclusionPanel";
import Danger from "components/Danger";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";

const InclusionExclusion = ({
  values,
  setFieldValue,
  handleAddInclusion,
  handleAddExclusion,
  validationMessage = "",
  titlePkr = {},
  errors = {},
  touched = {},
  selectedTitleInc = {},
  selectedTitleExc = {},
  handleTitleIncChange = () => {},
  handleTitleExcChange = () => {},
}) => (
  <MDBox mt={1.625}>
    <Grid2 container size={{ xs: 12 }} spacing={2}>
      {/* Inclusions */}
      <Grid2 item size={{ xs: 6 }}>
        <TitlePicker
          titlePkr={titlePkr}
          setFieldValue={setFieldValue}
          title={selectedTitleInc}
          handleTitleChange={handleTitleIncChange}
        />
      </Grid2>
      {values.titlePkr?.label === "Other" && (
        <Grid2 item size={{ xs: 6 }} style={{ marginTop: "10px" }}>
          <FormField
            type="text"
            label="Other"
            name="typeText"
            value={values.typeText}
            placeholder="Specify inclusion"
            setFieldValue={setFieldValue}
          />
        </Grid2>
      )}
      <Grid2 item size={{ xs: 12 }}>
        <MDButton
          key={values.inclusions?.length}
          variant="contained"
          color="secondary"
          onClick={handleAddInclusion}
        >
          Add Inclusion
        </MDButton>
      </Grid2>
      {validationMessage && (validationMessage[0] === "I" || validationMessage[13] === "i") && (
        <Danger validation={validationMessage} />
      )}
      <Divider
        sx={{ height: "1px", width: "100%", backgroundColor: "gray", margin: "10px 0 0 0" }}
      />
      <Grid2 item size={{ xs: 12 }}>
        {values?.inclusions?.length > 0 ? (
          <AvailabilityInclusionPanel
            setFieldValue={setFieldValue}
            availabilities={values?.inclusions}
          />
        ) : (
          <MDBox sx={{ marginTop: "30px" }}>
            <MDTypography variant="h5">No Inclusions Found!</MDTypography>
            {errors?.availabilities && touched?.availabilities && (
              <Danger validation={errors?.availabilities} />
            )}
          </MDBox>
        )}
      </Grid2>

      {/* Exclusions */}
      <Grid2 item size={{ xs: 6 }}>
        <TitlePicker
          isExclusion
          titlePkr={values.titlePkrExc}
          setFieldValue={setFieldValue}
          title={selectedTitleExc}
          handleTitleChange={handleTitleExcChange}
        />
      </Grid2>
      {values.titlePkrExc?.label === "Other" && (
        <Grid2 item size={{ xs: 6 }} style={{ marginTop: "10px" }}>
          <FormField
            type="text"
            label="Other"
            name="typeTextExc"
            value={values.typeTextExc}
            placeholder="Specify exclusion"
            setFieldValue={setFieldValue}
          />
        </Grid2>
      )}
      <Grid2 item size={{ xs: 12 }}>
        <MDButton
          key={values.exclusions?.length}
          variant="contained"
          color="secondary"
          onClick={handleAddExclusion}
        >
          Add Exclusion
        </MDButton>
      </Grid2>
      {validationMessage && (validationMessage[0] === "E" || validationMessage[13] === "e") && (
        <Danger validation={validationMessage} />
      )}
      <Divider
        sx={{ height: "1px", width: "100%", backgroundColor: "gray", margin: "10px 0 0 0" }}
      />
      {/* <Grid2 container alignItems="end" sx={{ marginBottom: "30px" }}> */}
      <Grid2 item size={{ xs: 12 }} style={{ marginBottom: "20px" }}>
        {values?.exclusions?.length > 0 ? (
          <AvailabilityExclusionPanel
            setFieldValue={setFieldValue}
            availabilities={values?.exclusions}
          />
        ) : (
          <MDBox sx={{ marginTop: "30px", marginBottom: "30px" }}>
            <MDTypography variant="h5">No Exclusions Found!</MDTypography>
            {errors?.availabilities && touched?.availabilities && (
              <Danger validation={errors?.availabilities} />
            )}
          </MDBox>
        )}
      </Grid2>
      {/* </Grid2> */}
    </Grid2>
  </MDBox>
);

InclusionExclusion.propTypes = {
  values: PropTypes.shape({
    titlePkr: PropTypes.instanceOf(Object),
    titlePkrExc: PropTypes.instanceOf(Object),
    typeText: PropTypes.string,
    typeTextExc: PropTypes.string,
    inclusions: PropTypes.instanceOf(Array),
    exclusions: PropTypes.instanceOf(Array),
  }).isRequired,
  titlePkr: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func.isRequired,
  handleTitleIncChange: PropTypes.func,
  handleTitleExcChange: PropTypes.func,
  handleAddInclusion: PropTypes.func.isRequired,
  handleAddExclusion: PropTypes.func.isRequired,
  validationMessage: PropTypes.string,
  selectedTitleInc: PropTypes.instanceOf(Object),
  selectedTitleExc: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  touched: PropTypes.instanceOf(Object),
};

export default InclusionExclusion;
