/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid2";
// import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Divider } from "@mui/material";
// import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { areAllValuesEmpty, dateFormatWithDash } from "components/helper";

const Review = ({ formData }) => {
  const { values } = formData;

  const [promoCodeInfoData, setPromoCodeInfoData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setPromoCodeInfoData({
        title: values?.title,
        description: values?.description,
        method: values?.method,
        code: values?.method === "auto" ? undefined : values?.code,
        type: values?.type,
        value: values?.value,
        appliesTo: values?.appliesTo,
        minimumType: values?.minimumType,
        minimumValue: values?.minimumValue,
        eligibility: values?.eligibility,
        locations: values?.eligibility === "criteria" && values?.locations,
        destinations:
          values?.appliesTo === "criteria"
            ? values?.destinations?.length > 0 && values?.destinations[0]?.label?.length > 0
              ? values?.destinations?.map((destination) => destination?.label)
              : values?.destinations
            : "",
        items:
          values?.appliesTo === "items"
            ? values?.items?.length > 0 && values?.items[0]?.label?.length > 0
              ? values?.items?.map((item) => item?.label)
              : values?.items
            : "",
        startDate: values?.startDate?.length > 0 && dateFormatWithDash(values?.startDate),
        endDate: values?.endDate?.length > 0 && dateFormatWithDash(values?.endDate),
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([{ id: 0, title: "PromoCode info", data: promoCodeInfoData }]);
  }, [promoCodeInfoData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              xs={12}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  //   editPage: PropTypes.bool,
};

export default Review;
