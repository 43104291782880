// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTravelDesks, setLoading } from "store/actions/travelDeskActions";
import MyAutoComplete from ".";

const TravelDeskPicker = ({
  travelDesk,
  handleChange,
  setFieldValue,
  selectedTravelDesk = null,
  ...rest
}) => {
  const dispatch = useDispatch();

  const travelDesks = useSelector((state) => state.travelDesks.travelDesks);

  // get travelDesks
  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTravelDesks());
  }, []);

  if (travelDesks?.length === 0) {
    return null; // Return null or a loading spinner while waiting for the data to load
  }

  return (
    <MyAutoComplete
      {...rest}
      data={travelDesks}
      idName="travelDesk"
      label={travelDesk.label}
      name={travelDesk?.name}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      selectedOption={selectedTravelDesk}
    />
  );
};

// typechecking props for TravelDeskPicker
TravelDeskPicker.propTypes = {
  travelDesk: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedTravelDesk: PropTypes.instanceOf(Object),
};

export default TravelDeskPicker;
