/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const DestinationsFilter = ({ onFilterChanged, columnDef }) => {
  const [selectedDestination, setSlectedDestination] = useState(null);

  const handleChange = (value) => {
    if (!value) {
      setSlectedDestination(null);
    } else {
      setSlectedDestination(value);
    }
    const val = value?.value;
    onFilterChanged(columnDef.tableData.id, val);
  };

  const filterDestination = (data) =>
    data.map((destinationsData) => ({
      label: destinationsData?.destinationName,
      value: destinationsData?._id,
      type: destinationsData?.destinationType,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/destinations?`;
      url += `${inputValue && `&destinationName=${inputValue}`}`;
      url += "&sort=destinationName";
      url += "&dir=asc";
      setTimeout(() => {
        // resolve();
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterDestination(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>
            <AsyncSelect
              aria-label="Destination"
              cacheOptions
              defaultOptions
              defaultValue={selectedDestination}
              isClearable
              menuPosition="fixed"
              loadOptions={promiseOptions}
              onChange={(option) => {
                handleChange(option);
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "48px",
                  width: "200px",
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                input: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menuList: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                }),
                placeholder: (base) => ({
                  ...base,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "100%",
                  WebkitLineClamp: 1,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                singleValue: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
              }}
            />
          </th>
        </tr>
      </thead>
    </table>
  );
};

DestinationsFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
};

export default DestinationsFilter;
