/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import { capitalizeFirstLetter } from "components/helper";

const versionOptions = [
  { label: "V1", value: "v1" },
  { label: "V0.1", value: "v0.1" },
  { label: "V2.221", value: "v2.221" },
];

const ServiceJobsVersionPicker = ({ versionV = "", setFieldValue, company = "" }) => {
  useEffect(() => {
    switch (company) {
      case "sky":
        setFieldValue("version", "v1");
        setFieldValue("diningVersion", "v1");
        break;
      case "messagebox":
        setFieldValue("version", "v0.1");
        break;
      case "digi":
        setFieldValue("diningVersion", "v2.221");
        break;
      default:
        setFieldValue("version", "");
        break;
    }
  }, [company]);

  return (
    <div>
      <AsyncSelect
        isDisabled
        options={versionOptions}
        placeholder="Select a version"
        value={
          versionV
            ? { label: capitalizeFirstLetter(versionV), value: versionV.toLowerCase() }
            : null
        }
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow,
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor,
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem", // Customize the selected option text size
          }),
        }}
      />
    </div>
  );
};

// Prop-types for ServiceJobsVersionPicker
ServiceJobsVersionPicker.propTypes = {
  company: PropTypes.string,
  versionV: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default ServiceJobsVersionPicker;
