// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";

const SidenavList = ({ children = null }) => (
  <List
    sx={{
      px: 2,
      my: 0.3,
    }}
  >
    {children}
  </List>
);

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node,
};

export default SidenavList;
