/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import { getItems } from "store/actions/itemAction";
import ItemsPicker from "components/AutoComplete/ItemsPicker";
import LocationsPicker from "components/AutoComplete/LocationsPicker";
import Danger from "components/Danger";
import { capitalizeFirstLetter, dateFormatWithDash } from "components/helper";
import { v4 as uuidv4 } from "uuid"; // Import UUID library
import MDDatePicker from "components/MDDatePicker";

const PromoCodeInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleLocationChange = () => {},
  selectedLocations = {},
  setSelectedLocations = () => {},
  handleDestinationChange = () => {},
  selectedDestination = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    title,
    description,
    method,
    type,
    code,
    value,
    appliesTo,
    destinations,
    tickets,
    locations,
    minimumType,
    minimumValue,
    eligibility,
    startDate,
    endDate,
  } = formField;
  const {
    title: titleV,
    description: descriptionV,
    method: methodV,
    type: typeV,
    code: codeV,
    value: valueV,
    appliesTo: appliesToV,
    destinations: destinationsV,
    tickets: ticketsV,
    locations: locationsV,
    minimumType: minimumTypeV,
    minimumValue: minimumValueV,
    eligibility: eligibilityV,
    startDate: startDateV,
    endDate: endDateV,
  } = values;

  const dispatch = useDispatch();

  // useSelector
  const startDateKey = uuidv4();

  // State
  const [startDateProm, setStartDateProm] = useState(undefined);
  const [endDateProm, setEndDateProm] = useState(undefined);
  const itemsList = useSelector((state) => state.items.items);

  const methodOptions = [
    { value: "code", label: "Promocode" },
    { value: "auto", label: "Automatic Discount" },
  ];

  const handleMethodChange = () => {
    if (methodV === "code") {
      setFieldValue("code", "");
    }
    if (methodV === "auto") {
      setFieldValue("code", null);
    }
  };
  const handleAppliesToChange = () => {
    if (appliesToV === "tickets") {
      setFieldValue("destinations", []);
    } else if (appliesToV === "criteria") {
      setFieldValue("tickets", []);
    } else {
      setFieldValue("tickets", []);
      setFieldValue("destinations", []);
    }
  };
  const handleMinimumTypeChange = () => {
    if (minimumTypeV.length > 0) {
      setFieldValue("minimumValue", "");
    }
  };
  const handleEligibilityChange = () => {
    setSelectedLocations([]);
    if (eligibilityV === "criteria") {
      setFieldValue("locations", []);
    }
  };
  const typeOptions = [
    { value: "percentage", label: "Percentage(%)" },
    { value: "amount", label: "Fixed Amount($)" },
  ];

  const appliesToOptions = [
    { value: "all", label: "All items" },
    { value: "criteria", label: "Selection criteria" },
    { value: "tickets", label: "Manually" },
  ];

  const minimumTypeOptions = [
    { value: "none", label: "No minimum requirements" },
    { value: "amount", label: "Minimum purchase amount($)" },
    { value: "quantity", label: "Minimum Quantity of Items" },
  ];

  const eligibilityOptions = [
    { value: "all", label: "All customers" },
    { value: "criteria", label: "Specific Location" },
  ];

  // useEffect
  useEffect(() => {
    dispatch(getItems());
  }, []);

  // UseEffect
  useEffect(() => {
    if (formData?.values?.startDate) {
      setStartDateProm(dateFormatWithDash(formData?.values?.startDate));
    }
    if (formData?.values?.endDate) {
      setEndDateProm(dateFormatWithDash(formData?.values?.endDate));
    }
  }, [formData?.values?.startDate, formData?.values?.endDate]);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">PromoCode info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            description: values?.description,
            method: values?.method,
            code: values?.method === "auto" ? null : values?.code,
            type: values?.type,
            value: values?.value,
            appliesTo: values?.appliesTo,
            minimumType: values?.minimumType,
            minimumValue: values?.minimumTypeV !== "none" && values?.minimumValue,
            eligibility: values?.eligibility,
            locations: values?.eligibility === "criteria" && values?.locations,
            destinations:
              values?.appliesTo === "criteria"
                ? values?.destinations?.length > 0 &&
                  values?.destinations[0]?.destinationName?.length > 0
                  ? values?.destinations?.map((destination) => destination?.destinationName)
                  : values?.destinations
                : "",
            tickets:
              values?.appliesTo === "tickets"
                ? values?.tickets?.length > 0 &&
                  (values?.tickets[0]?.label?.length > 0 || values?.tickets[0]?.title?.length > 0)
                  ? values?.tickets?.map((item) => item?.label || item?.title)
                  : values?.tickets
                : "",
            startDate: values?.startDate?.length > 0 && dateFormatWithDash(values?.startDate),
            endDate: values?.endDate?.length > 0 && dateFormatWithDash(values?.endDate),
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors?.title && touched?.title}
                success={titleV?.length > 0 && !errors?.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                multiline
                outlined
                rows={5}
                placeholder={description.placeholder}
                success={descriptionV?.length > 0 && !errors?.description}
              />
            </Grid>

            <Grid item size={{ xs: 6 }}>
              <MDDatePicker
                key={startDateKey}
                input={{
                  type: startDate.type,
                  label: startDate.label,
                  name: startDate.name,
                  variant: "standard",
                  width: "100%",
                  value: startDateProm,
                }}
                onChange={(event, val) => {
                  if (val) {
                    setFieldValue("startDate", val);
                    setStartDateProm(val);
                  } else {
                    setFieldValue("startDate", new Date());
                    setStartDateProm(new Date());
                  }
                }}
                inputFormat="dd/MM/yyyy"
                error={errors.startDate && touched.startDate}
                success={startDateV?.length > 0 && !errors.startDate}
                options={{
                  minDate: new Date(),
                  // dateFormat: "d/m/y",
                }}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <MDDatePicker
                key={startDateKey}
                input={{
                  type: endDate.type,
                  label: endDate.label,
                  name: endDate.name,
                  variant: "standard",
                  width: "100%",
                  value: endDateProm,
                }}
                onChange={(event, val) => {
                  if (val) {
                    setFieldValue("endDate", val);
                    setEndDateProm(val);
                  } else {
                    setFieldValue("endDate", new Date());
                    setEndDateProm(new Date());
                  }
                }}
                error={errors.startDate && touched.startDate}
                success={endDateV?.length > 0 && !errors.endDate}
                options={{
                  minDate: values.startDate || new Date(), // Set minDate to startDate or current date if startDate is not set

                  // dateFormat: "d-m-Y",
                }}
              />
            </Grid>
            <Grid item size={{ xs: 6 }} sx={{ display: "flex", flexDirection: "column" }}>
              <div>
                <DynamicRadioGroup
                  groupLabel={method.label}
                  handleChange={handleMethodChange}
                  name={method.name}
                  value={methodV}
                  options={methodOptions}
                  setFieldValue={setFieldValue}
                  // disabled
                />
              </div>
              {methodV === "code" && (
                <div>
                  <FormField
                    type={code.type}
                    label={code.label}
                    name={code.name}
                    value={codeV}
                    placeholder={code.placeholder}
                    error={errors?.code && touched?.code}
                    success={codeV?.length > 0 && !errors?.title}
                  />
                </div>
              )}
            </Grid>
            <Grid item size={{ xs: 6 }} sx={{ display: "flex", flexDirection: "column" }}>
              <div>
                <DynamicRadioGroup
                  groupLabel={type.label}
                  name={type.name}
                  value={typeV}
                  options={typeOptions}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div>
                <FormField
                  type={value.type}
                  label={value.label}
                  name={value.name}
                  value={valueV}
                  placeholder={value.placeholder}
                  error={errors?.value && touched?.value}
                  success={valueV?.length > 0 && !errors?.title}
                />
              </div>
            </Grid>
            <Grid item size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column" }}>
              <div>
                <DynamicRadioGroup
                  groupLabel={appliesTo.label}
                  name={appliesTo.name}
                  value={appliesToV}
                  handleChange={handleAppliesToChange}
                  options={appliesToOptions}
                  setFieldValue={setFieldValue}
                />
              </div>
              {appliesToV === "criteria" && (
                <div>
                  <Grid item size={{ xs: 12 }} sx={{ mt: "16px" }}>
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <DestinationsPicker
                        setFieldValue={setFieldValue}
                        handleChange={handleDestinationChange}
                        destinations={destinations}
                        destinationsV={destinationsV || selectedDestination}
                      />
                    </div>
                    <Danger validation={errors?.destinations} />
                  </Grid>
                </div>
              )}
              {appliesToV === "tickets" && (
                <div>
                  <ItemsPicker
                    items={tickets}
                    itemsList={itemsList}
                    itemsV={
                      ticketsV?.length > 0 && ticketsV[0]?._id?.length > 0
                        ? ticketsV?.map((item) => ({ label: item?.title, value: item?._id }))
                        : ticketsV
                    }
                    setFieldValue={setFieldValue}
                    error={errors.ticketsV && touched.ticketsV}
                    success={ticketsV?.length > 0 && !errors.items}
                  />
                  {errors?.tickets && touched?.tickets && <Danger validation={errors?.tickets} />}
                </div>
              )}
            </Grid>
            <Grid item size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column" }}>
              <div>
                <DynamicRadioGroup
                  groupLabel={minimumType.label}
                  name={minimumType.name}
                  value={minimumTypeV}
                  handleChange={handleMinimumTypeChange}
                  options={minimumTypeOptions}
                  setFieldValue={setFieldValue}
                />
              </div>
              {minimumTypeV !== "none" && (
                <div>
                  <FormField
                    type={minimumValue.type}
                    label={minimumValue.label}
                    name={minimumValue.name}
                    value={minimumValueV}
                    placeholder={minimumValue.placeholder}
                  />
                </div>
              )}
            </Grid>
            <Grid item size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column" }}>
              <div>
                <DynamicRadioGroup
                  groupLabel={eligibility.label}
                  name={eligibility.name}
                  value={eligibilityV}
                  handleChange={handleEligibilityChange}
                  options={eligibilityOptions}
                  setFieldValue={setFieldValue}
                />
              </div>
              {eligibilityV === "criteria" && (
                <div>
                  <LocationsPicker
                    location={locations}
                    setFieldValue={setFieldValue}
                    locationArr={selectedLocations}
                    handleChange={handleLocationChange}
                    error={errors.location?._id && touched.location?._id}
                    success={locationsV?.length > 0 && !errors.location?._id}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for PromoCodeInfo
PromoCodeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  selectedLocations: PropTypes.instanceOf(Object),
  setSelectedLocations: PropTypes.func,
  handleLocationChange: PropTypes.func,
  handleDestinationChange: PropTypes.func,
  selectedDestination: PropTypes.instanceOf(Object),
};

export default PromoCodeInfo;
