/* eslint-disable camelcase */

import checkout from "./form";

const {
  formField: {
    // tag page
    title,
    parents,
    top,
    type,
    interests,
    persona,
    icon,
    messageBoxCategoryId,
  },
} = checkout;

const initialValues = {
  // information page
  [title.name]: "",
  [parents.name]: [],
  [top.name]: false,
  [type.name]: { label: "Recommendations", value: "RECOMMENDATIONS" },
  [interests.name]: [],
  [persona.name]: [],
  [icon.name]: "",
  [messageBoxCategoryId.name]: "",
};

export default initialValues;
