/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { List, ListItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const PickupPointsList = ({ data = {} }) => {
  const listItemStyle = {
    display: "flex",
  };

  const columnStyle = {
    flex: "0.5", // Equal distribution of width
    padding: "3px", // Adjust the padding as needed for space between columns
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 130,
  };

  const { start = [], end = [], travelerPickup = {} } = data;
  const { pins = [], pickupOptionType } = travelerPickup;

  // Use the first start and end point if there's only one, otherwise map over the pins
  const startPoint = start?.[0];
  const endPoint = end?.[0];

  return (
    <List>
      <ListItem style={listItemStyle}>
        <div style={{ ...columnStyle }}>
          <strong>Start point</strong>
        </div>
        <div style={{ ...columnStyle }}>
          <strong>End point</strong>
        </div>
        <div style={{ ...columnStyle, width: 150 }}>
          <strong>Type</strong>
        </div>
        <div style={{ ...columnStyle }}>
          <strong>Pickup point</strong>
        </div>
        <div style={{ ...columnStyle }}>
          <strong>Pickup type</strong>
        </div>
      </ListItem>
      {pins.map((pinItem, index) => (
        <ListItem key={pinItem?.pin?.id?.name || index} style={listItemStyle}>
          <div style={{ ...columnStyle }}>
            <Tooltip title={startPoint?.pin?.id?.name}>
              <span>{startPoint?.pin?.id?.name}</span>
            </Tooltip>
          </div>
          <div style={{ ...columnStyle }}>
            <Tooltip title={endPoint?.pin?.id?.name}>
              <span>{endPoint?.pin?.id?.name}</span>
            </Tooltip>
          </div>
          <div style={{ ...columnStyle }}>
            <span>
              {pickupOptionType === "PICKUP_EVERYONE"
                ? "Everyone"
                : pickupOptionType === "PICKUP_AND_MEET_AT_START_POINT"
                ? "Pickup and meet at start point"
                : "Meet everyone at start point"}
            </span>
          </div>
          <div style={{ ...columnStyle }}>
            <Tooltip title={pinItem?.pin?.id?.name}>
              <span>{pinItem?.pin?.id?.name}</span>
            </Tooltip>
          </div>
          <div style={{ ...columnStyle }}>
            <span>{pinItem?.pickupType}</span>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

PickupPointsList.propTypes = {
  data: PropTypes.shape({
    start: PropTypes.arrayOf(
      PropTypes.shape({
        pin: PropTypes.shape({
          id: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      })
    ),
    end: PropTypes.arrayOf(
      PropTypes.shape({
        pin: PropTypes.shape({
          id: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      })
    ),
    travelerPickup: PropTypes.shape({
      pickupOptionType: PropTypes.string,
      pins: PropTypes.arrayOf(
        PropTypes.shape({
          pin: PropTypes.shape({
            id: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
          pickupType: PropTypes.string,
        })
      ),
    }),
  }),
};

export default PickupPointsList;
