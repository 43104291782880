// constants.js
export const venuesOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const healthRestrictions = [
  { label: "Not recommended for travelers with back problems", value: "NO_BACK_PROBLEMS" },
  { label: "Not recommended for pregnant travelers", value: "NO_PREGNANT" },
  {
    label: "Not recommended for travelers with heart problems or other serious medical conditions",
    value: "NO_HEART_PROBLEMS",
  },
];

export const radioOptions = [
  {
    value: "PHYSICAL_EASY",
    label: "Easy",
    helperText: "Most travelers can participate",
  },
  {
    value: "PHYSICAL_MEDIUM",
    label: "Moderate",
    helperText: "Travelers should have a moderate physical fitness level",
  },
  {
    value: "PHYSICAL_HARD",
    label: "Challenging",
    helperText: "Travelers should have a strong physical fitness level",
  },
];

export const accompaniedOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const durationOptions = [
  { value: "specificDuration", label: "Specific duration" },
  { value: "flexibleDuration", label: "Flexible duration" },
];

export const pickupOptions = [
  {
    value: "PICKUP_EVERYONE",
    label: "Everyone",
  },
  {
    value: "PICKUP_AND_MEET_AT_START_POINT",
    label: "Pickup and meet at start point",
  },
  {
    value: "MEET_EVERYONE_AT_START_POINT",
    label: "Meet everyone at startpoint",
  },
];

export const languagesList = [
  { code: "en", name: "English", default: false },
  { code: "fr", name: "French", default: false },
  { code: "nl", name: "Dutch", default: false },
  { code: "es", name: "Spanish", default: false },
  { code: "ru", name: "Russian", default: false },
  { code: "ar", name: "Arabic", default: false },
  { code: "it", name: "Italian", default: false },
  { code: "gr", name: "German", default: false },
];
