import React from "react";
import PropTypes from "prop-types";
import ForgotPasswordEmail from "..";

const LcmForgetPassword = ({ token = "https://lokalee.app", user = {} }) => (
  <ForgotPasswordEmail isCode={false} user={user} token={token} />
);

LcmForgetPassword.propTypes = {
  user: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

export default LcmForgetPassword;
