import PropTypes from "prop-types";

export const defaultProps = {
  user: {
    name: "John Doe",
    roomNumber: "1130",
  },
  data: {
    invoiceNumber: "LK20240001",
    items: [
      {
        ref: {
          title: "Towel",
        },
        totalPax: 2,
        itemTotalPrice: {
          price: {
            recommendedRetailPrice: 10,
          },
        },
        currency: "USD",
      },
      {
        ref: {
          title: "Towel2",
        },
        totalPax: 2,
        itemTotalPrice: {
          price: {
            recommendedRetailPrice: 20,
          },
        },
        currency: "USD",
      },
    ],
    paymentMethod: "cash",
  },
};

export const propTypes = {
  user: {
    name: PropTypes.string,
    roomNumber: PropTypes.string,
  },
  data: {
    invoiceNumber: PropTypes.string,
    items: PropTypes.array,
    paymentMethod: PropTypes.string,
  },
};
